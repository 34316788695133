import { getGmm } from '../../stores/globalState';
import { sendObject } from './handler';

export type ToggleForceTouchKeyboard = {
  type: 'toggleForceTouchKeyboard';
  forceTouchKeyboard: 't' | 'f';
};

type Options = {
  force: boolean;
};

export const postToggleTouchKeyboard = async ({
  force,
}: Options): Promise<void> => {
  getGmm()?.rebuildCurrentProblem();
  return sendObject({
    type: 'toggleForceTouchKeyboard',
    forceTouchKeyboard: force ? 't' : 'f',
  });
};
