import { CSSProperties, FC } from 'react';

import { FLOATER_BLUE_BACKGROUND } from '../modals/constants';

const dragZoneStyle: CSSProperties = {
  backgroundImage: 'url(GMM-icons/GMM-icon-draggable.svg)',
  cursor: 'move',
  height: '20px',
  marginBottom: '10px',
  paddingTop: '5px',
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

interface DragZoneProps {
  dragHandle: string;
  collapsed?: boolean;
  collapsedMessage?: string;
  color?: string;
}

export const DragZone: FC<DragZoneProps> = ({
  dragHandle,
  collapsed,
  collapsedMessage,
  color,
}) => {
  return (
    <div className={dragHandle} style={dragZoneStyle}>
      {collapsed && collapsedMessage && (
        <span
          style={{
            color: color || 'white',
            backgroundColor: FLOATER_BLUE_BACKGROUND,
          }}
        >
          {collapsedMessage}
        </span>
      )}
    </div>
  );
};
