export function dot(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  size: number,
  fill?: string
): void {
  const restore = ctx.fillStyle;

  ctx.beginPath();
  ctx.ellipse(x, y, size, size, 0, 0, 360);

  if (fill) {
    ctx.fillStyle = ctx.strokeStyle;
    ctx.fill();
  } else {
    ctx.fillStyle = 'white';
    ctx.fill();
    ctx.fillStyle = ctx.strokeStyle;
    ctx.stroke();
  }

  ctx.fillStyle = restore;
}
