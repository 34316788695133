import { FC } from 'react';

import { MODAL_Z_INDEX } from '@gmm/problem';

import {
  SHOW_ANSWER,
  studentAppModalStore,
} from '../../../studentApp/stores/studentAppModalStore';

interface OverlayProps {
  close: () => void;
}

export const Overlay: FC<OverlayProps> = ({ close }) => {
  const currentModal = studentAppModalStore(state => state.currentModal);

  const showingAnswer = currentModal === SHOW_ANSWER;
  const transparency = showingAnswer ? 0.2 : 0.4;

  // handle React Mouse Event for closing with method closeMe, which should take
  // one parameter for the event
  const closeMe = (event: React.MouseEvent<HTMLDivElement>) => {
    if (showingAnswer) {
      event.stopPropagation();
      event.preventDefault();

      return;
    }

    close();
  };

  return (
    <div
      onClick={closeMe}
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: `rgba(0, 0, 0, ${transparency})`,
        zIndex: MODAL_Z_INDEX,
      }}
    />
  );
};
