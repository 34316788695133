import { FC, useEffect, useRef } from 'react';
import { ReadAloud } from './readAloud';
import { cancelReadAloud, readAloud } from '../readAloud';
import audioIcon from '../images/audioIcon_white.png';
import { problemModalStore } from './problemModalStore';

interface ReadableProps {
  text: string;
  highlightColor: string;
}

export const Readable: FC<ReadableProps> = ({ text, highlightColor }) => {
  const targetRef = useRef<HTMLDivElement | null>(null);
  const permitReadAloud = problemModalStore(state => state.permitReadAloud);

  const click = () => {
    const target = targetRef.current;
    if (!target) return;

    readAloud(new ReadAloud(target));
  };

  useEffect(() => {
    return () => {
      cancelReadAloud();
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {permitReadAloud && (
        <div
          onClick={click}
          style={{
            padding: '3px',
            marginRight: '5px',
            maxWidth: '23px',
            cursor: 'pointer',
          }}
        >
          <img
            src={audioIcon}
            style={{
              width: '18px',
              height: '22px',
            }}
          />
        </div>
      )}
      <div
        ref={targetRef}
        className="audioButtonTarget"
        data-highlightcolor={highlightColor}
        style={{
          lineHeight: '1.5',
        }}
      >
        {text}
      </div>
    </div>
  );
};
