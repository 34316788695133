import { FC, useEffect, useRef } from 'react';
import { problemModalStore } from './problemModalStore';
import { MODAL_Z_INDEX } from './constants';

export const NativeInputBox: FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [location, setLocation] = problemModalStore(state => [
    state.nativeInputLocation,
    state.setNativeInputLocation,
  ]);
  const [text, setNativeInputText] = problemModalStore(state => [
    state.nativeInputText,
    state.setNativeInputText,
  ]);
  const startingCursorPosition = problemModalStore(
    state => state.nativeInputCursorOnMount
  );

  useEffect(() => {
    const keydown = (evt: KeyboardEvent) => {
      if (!inputRef.current) return;

      if (evt.key === 'Escape' || evt.key === 'Enter') {
        setLocation(undefined);
        evt.preventDefault();
        evt.stopPropagation();
      }
    };

    const inputChanged = () => {
      if (!inputRef.current) return;
      setNativeInputText(inputRef.current.value);
    };

    if (inputRef.current) {
      inputRef.current.addEventListener('keydown', keydown);
      inputRef.current.addEventListener('input', inputChanged);
      inputRef.current.focus();
      inputRef.current.setSelectionRange(
        startingCursorPosition,
        startingCursorPosition
      );
    }

    return () => {
      inputRef.current?.removeEventListener('keydown', keydown);
      inputRef.current?.removeEventListener('input', inputChanged);
    };
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        left: `${location?.x}px`,
        top: `${location?.y}px`,
        zIndex: MODAL_Z_INDEX,
      }}
    >
      <input type="text" ref={inputRef} defaultValue={text} />
    </div>
  );
};
