// import dimensions to avoid circular dependency from BasicObject usage of getReadAloudButtonWidth
import { ClickOrTouchEvent, TTSInterface } from '..';
import { line } from '../renderers';
import {
  HEIGHT,
  ICON_HEIGHT,
  ICON_Y_INSET,
  LOADING_INSET,
  SPEAKER_ICON_WIDTH,
  SPEAKER_ICON_X_INSET,
  STOP_ICON_WIDTH,
  STOP_ICON_X_INSET,
  WIDTH,
} from '../state';
import { BasicObject } from '../uiObjects';
import { getImage } from '../utils';

import { hasSpoken, isSpeechAvailable } from './readAloudUtil';
import { cancelReadAloud, readAloud } from './Reader';
import { TTS } from './TTS';

const audioIcon = getImage('audioIcon');
const audioStopIcon = getImage('audioStopIcon');
const loadingIcon = getImage('loadingwheel');

// PLAYING: currently reading aloud
// INACTIVE: not currently reading aloud
// LOADING: transitioning from INACTIVE to PLAYING
export type Status = 'PLAYING' | 'INACTIVE' | 'LOADING';

export interface ReadAloudButtonDependencies {
  paintCanvas(): void;
  errorToServer(error: string): void;
}

export class ReadAloudButton extends BasicObject implements TTSInterface {
  tts: TTS;

  gmmName = 'ReadAloudButton';

  status: Status = 'INACTIVE';

  problemJS: ReadAloudButtonDependencies;

  constructor(tts: TTS, problemJS: ReadAloudButtonDependencies) {
    super();
    this.tts = tts;
    this.problemJS = problemJS;

    this.setAllDim(WIDTH, HEIGHT);
  }

  getTTS(): TTS {
    if (!this.tts) throw new Error('No TTS');

    return this.tts;
  }

  setStatus(status: Status, repaint: boolean): void {
    this.status = status;

    if (this.status === 'INACTIVE') this.tts.clearHighlightReadAloud();

    if (repaint) this.problemJS.paintCanvas();
  }

  mouseDownResponse(
    _x?: number,
    _y?: number,
    evt?: ClickOrTouchEvent
  ): boolean {
    if (!isSpeechAvailable()) return false;

    // iOS does not allow speech synthesis until it has been directly triggered
    // by once a user action -- but touchstart doesn't count.
    if (evt && evt.type === 'touchstart' && !hasSpoken()) return false;

    if (this.status === 'INACTIVE') {
      this.setStatus('LOADING', true);
      readAloud(this);
    } else {
      cancelReadAloud();
    }

    return true;
  }

  paintMe(ctx: CanvasRenderingContext2D): void {
    ctx.save();

    if (this.status === 'LOADING') {
      ctx.drawImage(
        loadingIcon,
        LOADING_INSET,
        LOADING_INSET,
        WIDTH - 2 * LOADING_INSET,
        HEIGHT - 2 * LOADING_INSET
      );
    }

    // speaker icon
    else if (this.status === 'INACTIVE') {
      // When read aloud is permitted, but device doesn't support it,
      // fade the drawing and draw a slash through the speaker icon.
      // Do the same during initialization of voices.
      if (!isSpeechAvailable()) {
        ctx.globalAlpha = 0.5;
      }

      ctx.drawImage(
        audioIcon,
        SPEAKER_ICON_X_INSET,
        ICON_Y_INSET,
        SPEAKER_ICON_WIDTH,
        ICON_HEIGHT
      );

      if (!isSpeechAvailable()) {
        line(ctx, WIDTH, 0, 0, HEIGHT, 2, 'black');
      }
    }
    // 'PLAYING'
    else {
      ctx.drawImage(
        audioStopIcon,
        STOP_ICON_X_INSET,
        ICON_Y_INSET,
        STOP_ICON_WIDTH,
        ICON_HEIGHT
      );
    }

    ctx.restore();
  }

  // ReadAloudButton sometimes fakes membership as a line element to
  // seamlessly utilize pre-existing positioning logic.
  // A consequence is that it must implement expected line
  // element functions setLine, buildSizeRecursive, setEditable
  // and setFont.
  setLine(): void {}
  buildSizeRecursive(): void {}
  setEditable(): void {}
  setFont(_font: any): void {}

  // TTSInterface
  getSpokenText(): string {
    return this.tts.getSpokenText();
  }

  // TTSInterface
  setHighlightReadAloudFromSpokenIndex(startIndex: number): void {
    this.tts.setHighlightReadAloudFromSpokenIndex(startIndex);
  }

  // TTSInterface
  readAloudEnded(): void {
    this.setStatus('INACTIVE', true);
  }

  // TTSInterface
  readAloudStarted(): void {
    this.setStatus('PLAYING', true);
  }

  // TTSInterface
  errorToServer(error: string): void {
    this.problemJS.errorToServer(error);
  }
}
