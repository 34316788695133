import { FC, useEffect, useRef } from 'react';

import { applyPixelRatioToCanvas } from '@gmm/problem';

import { GRAY } from '../../constants';

const DEFAULT_FONT_SIZE = 16;
const DEFAULT_COLOR = GRAY;
// ctx metrics are slightly too small
const NUDGE_VERTICAL = 1.5;
const NUDGE_HORIZONTAL = 1;
const ROUNDED_MARGIN = 3;

export interface ScoreProps {
  text: string | number;
  color?: string;
  backColor?: string;
  fontSize?: number;
  bold?: boolean;
  roundedRectangle?: boolean;
  id?: string;
}

// try for inter, fall back on arial
const FONT_NAME = 'Inter, Arial';

export const TextOnCanvas: FC<ScoreProps> = ({
  text,
  fontSize,
  color,
  backColor,
  bold,
  roundedRectangle,
  id,
}) => {
  const fontSizer = fontSize || DEFAULT_FONT_SIZE;
  const colorizer = color || DEFAULT_COLOR;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  // 1) size canvas to text
  // 2) paint text on canvas
  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) return;

    const ctx = canvas.getContext('2d')!;

    const font = (bold ? 'bold ' : '') + fontSizer + 'px ' + FONT_NAME;
    const margin = roundedRectangle ? ROUNDED_MARGIN : 0;

    const { width, height, descent } = sizeToFit(canvas, text, font, margin);

    ctx.clearRect(0, 0, width, height);

    if (roundedRectangle) {
      ctx.fillStyle = backColor || 'transparent';
      roundRect(ctx, width, height, 5);
      ctx.fill();
    }

    ctx.fillStyle = colorizer;
    ctx.font = font;
    ctx.textAlign = 'left';

    ctx.fillText(
      text.toString(),
      NUDGE_HORIZONTAL + margin,
      height - descent - NUDGE_VERTICAL - margin
    );
  }, [text, backColor]);

  return <canvas ref={canvasRef} id={id} />;
};

interface Dimensions {
  width: number;
  height: number;
  descent: number;
}

const sizeToFit = (
  canvas: HTMLCanvasElement,
  text: string | number,
  font: string,
  margin: number
): Dimensions => {
  const ctx = canvas.getContext('2d');

  // happy compiler
  if (!ctx) throw new Error('Canvas context not found');

  ctx.font = font;

  const metrics = ctx.measureText(text.toString());

  const width = metrics.width + 2 * NUDGE_HORIZONTAL + 2 * margin;

  const actualBoundingBoxAscent = metrics.actualBoundingBoxAscent || 0;
  const actualBoundingBoxDescent = metrics.actualBoundingBoxDescent || 0;
  let height = actualBoundingBoxAscent + actualBoundingBoxDescent + 2 * margin;

  height += 2 * NUDGE_VERTICAL;

  applyPixelRatioToCanvas(canvas, width, height);

  return {
    width,
    height,
    descent: actualBoundingBoxDescent,
  };
};

const roundRect = (
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number,
  radius: number
): void => {
  ctx.beginPath();
  ctx.moveTo(radius, 0);
  ctx.lineTo(width - radius, 0);
  ctx.arcTo(width, 0, width, radius, radius);
  ctx.lineTo(width, height - radius);
  ctx.arcTo(width, height, width - radius, height, radius);
  ctx.lineTo(radius, height);
  ctx.arcTo(0, height, 0, height - radius, radius);
  ctx.lineTo(0, radius);
  ctx.arcTo(0, 0, radius, 0, radius);
  ctx.closePath();
};
