export function applyPixelRatioToCanvas(
  canvas: HTMLCanvasElement,
  w: number,
  h: number
): void {
  canvas.width = w * PIXEL_RATIO;
  canvas.height = h * PIXEL_RATIO;
  canvas.style.width = w + 'px';
  canvas.style.height = h + 'px';
  canvas.getContext('2d')?.setTransform(PIXEL_RATIO, 0, 0, PIXEL_RATIO, 0, 0);
}

interface CanvasRenderingContext2D {
  backingStorePixelRatio?: number;
  mozBackingStorePixelRatio?: number;
  msBackingStorePixelRatio?: number;
  oBackingStorePixelRatio?: number;
  webkitBackingStorePixelRatio?: number;
}

const ctx = document
  .createElement('canvas')
  .getContext('2d') as CanvasRenderingContext2D;
const dpr = window.devicePixelRatio || 1;
const bsr =
  ctx.webkitBackingStorePixelRatio ||
  ctx.mozBackingStorePixelRatio ||
  ctx.msBackingStorePixelRatio ||
  ctx.oBackingStorePixelRatio ||
  ctx.backingStorePixelRatio ||
  1;

export const PIXEL_RATIO = dpr / bsr;
