import {
  firstGreaterThanSecond,
  firstLessThanSecond,
  numbersEqual,
} from '../numberComparison';

const isOnLine = (
  num: number,
  excludeStartingPoints: boolean,
  includeEndingPoints: boolean
): boolean => {
  if (!excludeStartingPoints && numbersEqual(num, 0)) return true;
  if (includeEndingPoints && numbersEqual(num, 1)) return true;

  if (firstGreaterThanSecond(num, 0) && firstLessThanSecond(num, 1)) {
    return true;
  }

  return false;
};

interface Point {
  x: number;
  y: number;
}

type Line = [Point, Point];

interface DefaultIntersection {
  x: null;
  y: null;
  onLine1: false;
  onLine2: false;
}

export interface Intersection extends Point {
  onLine1: boolean;
  onLine2: boolean;
}

type LineIntersection = DefaultIntersection | Intersection;

export function checkLineIntersection(
  [{ x: line1StartX, y: line1StartY }, { x: line1EndX, y: line1EndY }]: Line,
  [{ x: line2StartX, y: line2StartY }, { x: line2EndX, y: line2EndY }]: Line,
  excludeStartingPoints = false,
  includeEndingPoints = false
): LineIntersection {
  const denominator =
    (line2EndY - line2StartY) * (line1EndX - line1StartX) -
    (line2EndX - line2StartX) * (line1EndY - line1StartY);

  if (denominator == 0) {
    return {
      x: null,
      y: null,
      onLine1: false,
      onLine2: false,
    };
  }

  let a = line1StartY - line2StartY;
  let b = line1StartX - line2StartX;
  const numerator1 =
    (line2EndX - line2StartX) * a - (line2EndY - line2StartY) * b;
  const numerator2 =
    (line1EndX - line1StartX) * a - (line1EndY - line1StartY) * b;

  a = numerator1 / denominator;
  b = numerator2 / denominator;

  const onLine1 = isOnLine(a, excludeStartingPoints, includeEndingPoints);
  const onLine2 = isOnLine(b, excludeStartingPoints, includeEndingPoints);

  return {
    x: line1StartX + a * (line1EndX - line1StartX),
    y: line1StartY + a * (line1EndY - line1StartY),
    onLine1,
    onLine2,
  };
}
