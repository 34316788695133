import { FC } from 'react';

import {
  useLayout,
  useProcessedProblems,
  useProcessedWorkStore,
} from '../../../studentApp/hooks';
import { squareGroupsStyle } from '../util/constants';

import { LabelAndSquares } from './labelAndSquares';
import { OrderBy } from './orderBy';
import { RegionLabel } from './regionLabel';

export const SpiralReviewRegion: FC = () => {
  const { spiralReviewByMonth } = useProcessedProblems();
  const currentWorkType = useProcessedWorkStore().currentWorkType;
  const srProgress = useProcessedWorkStore().srProgress;
  const isLandscape = useLayout().isLandscape;
  const height = isLandscape ? '50px' : '150px';

  return (
    <>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <RegionLabel
          text={'Spiral Review'}
          score={currentWorkType === 'ASSIGNMENT' ? srProgress : undefined}
          id="spiralReviewProgress"
        />
        <OrderBy />
      </div>

      <div
        className="scrollable"
        style={{ ...squareGroupsStyle, flexGrow: 1, height: height }}
      >
        {Array.from(spiralReviewByMonth.entries()).map(([month, problems]) => (
          <LabelAndSquares key={month} text={month} problems={problems} />
        ))}
      </div>
    </>
  );
};
