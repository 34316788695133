import {
  LOADING,
  WORK_HISTORY,
  studentAppModalState,
} from '../../stores/studentAppModalStore';
import { ShowAssignmentsHistory } from '../../types';

import { sendObject } from './handler';

export type RequestAssignmentsHistory = { type: 'requestWorkHistory' };

export const postRequestAssignmentsHistory = async (): Promise<void> => {
  studentAppModalState().setCurrentModal(LOADING);

  const onSuccess = (data: ShowAssignmentsHistory) => {
    studentAppModalState().setWorkHistory(data.workHistory);
    studentAppModalState().setCurrentModal(WORK_HISTORY);
  };

  sendObject({ type: 'requestWorkHistory' }, { onSuccess });
};
