import { MODAL_Z_INDEX } from '@gmm/problem';

export const examLoading = (
  <img
    src="/GMM-images/loading.gif"
    alt="loading"
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50px',
      height: '50px',
      zIndex: MODAL_Z_INDEX,
    }}
  />
);
