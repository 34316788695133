import { ModalProps, modalStore } from '../stores/modalStore';

interface UseModal {
  close: () => void;
  isShowing: boolean;
  modalProps: ModalProps;
  show: (modalProps: ModalProps) => void;
}

export const useModal = (): UseModal => {
  const isShowing = modalStore(state => state.isShowing);
  const modalProps = modalStore(state => state.modalProps);
  const setIsShowing = modalStore(state => state.setIsShowing);

  const close = (): void => {
    setIsShowing(false);
  };

  const show = (modalProps: ModalProps): void => {
    setIsShowing(true, modalProps);
  };

  return {
    close,
    isShowing,
    modalProps,
    show,
  };
};
