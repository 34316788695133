import { FC } from 'react';
import Draggable from 'react-draggable';

import {
  DIALOG_BLUE_BODY,
  DIALOG_BLUE_BUTTON,
  ModalButton,
  ModalHeader,
  modalStyle,
} from '@gmm/problem';

import { bannerStore } from '../../../studentApp/stores';
import { GETTING_STARTED_VIDEO_URL } from '../../constants';

// slight increase due to visual blur when underlining links
const LINE_HEIGHT = '1.1';

export const Welcome: FC<{ close: () => void }> = ({ close }) => {
  const loginCount = bannerStore(state => state.loginCount);

  const title = loginCount === 0 ? 'Welcome to Get More Math' : 'Welcome';
  const firstPhrase = loginCount === 0 ? 'Check out this ' : 'Watch this ';
  const secondPhrase =
    loginCount === 0
      ? ' to learn how Get More Math works.'
      : ' to become a Get More Math pro in no time.';

  return (
    <Draggable handle=".gmm-welcome-modal">
      <div style={{ ...modalStyle, backgroundColor: DIALOG_BLUE_BODY }}>
        <ModalHeader
          title={title}
          close={close}
          dragHandle={'gmm-welcome-modal'}
        />

        <div style={{ height: '20px' }} />

        <div style={{ lineHeight: LINE_HEIGHT }}>
          {firstPhrase}
          <a
            id="onboardingLink"
            href={GETTING_STARTED_VIDEO_URL}
            target="_blank"
            style={{ color: 'white' }}
            rel="noreferrer"
          >
            Getting Started video
          </a>
          {secondPhrase}
        </div>

        <div style={{ height: '20px' }}></div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
          }}
        >
          <ModalButton color={DIALOG_BLUE_BUTTON} text="OK" close={close} />
        </div>
      </div>
    </Draggable>
  );
};
