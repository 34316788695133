import { Button, ButtonGroup, styled, Switch } from '@material-ui/core';
import { FC, useState } from 'react';

import { showSmileys } from '../legacy';
import { getSelectedProblem, NormalProblem, problemState } from '../stores';
import { Proficiency } from '../types';
import { getColorData, colorsArray } from '../utils';

export type DevDirection = 'up' | 'down' | 'reset';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

// if leveling up and at 5 go back to 1 and vise-versa
const loopAround = (bool: boolean): number => (bool ? 5 : 1);

const getLevel = (direction: 'up' | 'down'): Proficiency => {
  const isUp = direction === 'up';
  const currentProblem = getSelectedProblem() as NormalProblem;
  const level = getColorData(currentProblem.lvl).level;
  const change = direction === 'up' ? +1 : -1;
  const newLevel =
    level !== loopAround(isUp) ? level + change : loopAround(!isUp);

  return colorsArray[newLevel - 1];
};

export const DevTools: FC = () => {
  const [devTools, setDevTools] = useState(false);
  const isDevEnv = process.env.NODE_ENV === 'development';
  const [withSmileys, setWithSmileys] = useState(false);

  window.dev = setDevTools;

  const levelUpSelected = (): void => {
    const currentProblem = getSelectedProblem() as NormalProblem;

    if (withSmileys) showSmileys();
    problemState().updateProblem(currentProblem.id, {
      lvl: getLevel('up'),
    });
  };

  const levelDownSelected = (): void => {
    const currentProblem = getSelectedProblem() as NormalProblem;

    problemState().updateProblem(currentProblem.id, {
      lvl: getLevel('down'),
    });
  };

  const handleChange = (): void => {
    setWithSmileys(!withSmileys);
  };

  return devTools && isDevEnv ? (
    <Container>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        <Button onClick={levelDownSelected}>Down</Button>
        <Button onChange={handleChange} disableElevation>
          Smileys
          <span role="img" aria-label="smiley">
            😀
          </span>
          <Switch
            checked={withSmileys}
            value={withSmileys}
            onChange={handleChange}
            inputProps={{ role: 'switch' }}
            color="primary"
            size="small"
          />
        </Button>
        <Button onClick={levelUpSelected}>Up</Button>
      </ButtonGroup>
    </Container>
  ) : null;
};
