import axios from 'axios';

import { convertStringToBool } from './utils';

export const configureAxios = (): void => {
  axios.defaults.baseURL = process.env.JAVA_URL;
  axios.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded';

  axios.interceptors.response.use(function axiosInterceptorResponseSuccess(
    response
  ) {
    if (response.data) {
      response.data = convertStringToBool(response.data);
    }

    return response;
  });
};
