import { problemModalState } from '../modals';
import { buildTTSDTO } from '../readAloud';
import { line, circle } from '../renderers';
import { BasicObject, getSubmitButton, TTSDTO } from '../uiObjects';
import { decodeString, distance } from '../utils';

import {
  AttemptGetter,
  AttemptGetterStatus,
  BaseDTO,
  STATUS_DIAMETER,
} from './attemptGetter';

interface FractionShadeGetterDependencies {
  // reusing 'grabFocus' used by other getters in 'mega file'
  grabFocus(getter: AttemptGetter): void;

  // for building BasicObjects to render prompt
  getStaticPanelFromText(
    text: string,
    font?: string,
    tts?: TTSDTO
  ): BasicObject;

  // for getSubmitButton
  submitAttempt(): void;
  getThemeColor(): string;
  paintCanvas(): void;

  // for serialization validation
  isTesting(): boolean;
}

type Shape = 'RECTANGLE' | 'CIRCLE';

interface DTO extends BaseDTO {
  prompt: string;
  title: string | undefined;
  slices: boolean[];
  shape: Shape;
  rectangleSliceWidth: number;
  rectangleSliceHeight: number;
  circleDiameter: number;
}

const STATUS_MARGIN = 7;
const SHADED_COLOR = '#00acc8';
const EMPTY_COLOR = 'white';
const BORDER_COLOR = 'black';

export class FractionShadeGetter extends AttemptGetter {
  problemJS: FractionShadeGetterDependencies;

  dto: DTO;

  sliceHolder: BasicObject;

  statusLocation: { x: number; y: number };

  constructor(
    problemJS: FractionShadeGetterDependencies,
    dtoFromServer: DTO,
    status: AttemptGetterStatus,
    changeHandler: any,
    x?: number,
    y?: number
  ) {
    super(dtoFromServer, status, changeHandler, x || 0, y || 0);
    this.problemJS = problemJS;
    this.dto = dtoFromServer;
    this.gmmName = 'fractionShadeGetter';

    const decoded = decodeString(this.dto.prompt) ?? this.dto.prompt;
    const prompt = this.problemJS.getStaticPanelFromText(
      decoded,
      undefined,
      buildTTSDTO(decoded)
    );

    prompt.viewportH += this.VERTICAL_SPACE_AFTER_PROMPT;
    this.add(prompt);

    if (this.dto.title) {
      const decodedTitle = decodeString(this.dto.title) ?? this.dto.title;
      const title = this.problemJS.getStaticPanelFromText(
        decodedTitle,
        undefined,
        buildTTSDTO(decodedTitle)
      );

      title.viewportH += this.VERTICAL_SPACE_AFTER_PROMPT;
      this.add(title);
    }

    this.sliceHolder = this.getSliceHolder(this.dto.shape);

    this.add(this.sliceHolder);

    this.layoutChildrenUD();
    this.sizeMeToFitChildren();

    this.statusLocation = {
      x: this.viewportW - STATUS_DIAMETER / 2 + STATUS_MARGIN,
      y: this.sliceHolder.viewportY + this.sliceHolder.viewportH / 2,
    };

    const submitButton = getSubmitButton(
      this,
      problemJS.paintCanvas,
      problemJS.submitAttempt,
      problemJS.getThemeColor,
      'clicked submit for fractionShade'
    );

    submitButton.viewportY =
      this.viewportH + this.VERTICAL_SPACE_BEFORE_SUBMIT_BUTTON;

    this.add(submitButton);

    this.sizeMeToLowestAndRightmostChildren();
    this.centerHorizontally();
  }

  paintMe(ctx: CanvasRenderingContext2D): void {
    this.paintStatus(ctx, this.statusLocation);
  }

  getSliceHolder(shape: Shape): BasicObject {
    const ret = new BasicObject();

    // Easiest case: model with BasicObjects (which are, happily, rectangular!)
    if (shape === 'RECTANGLE') {
      for (let x = 0; x < this.dto.slices.length; x++) {
        ret.add(new RectangleSlice(this.dto, x, this));
      }

      ret.layoutChildrenUD();
      ret.sizeMeToFitChildren();

      ret.viewportMargin = 1;
      ret.viewportMarginColor = BORDER_COLOR;

      ret.paintMe = (ctx: CanvasRenderingContext2D) => {
        ctx.save();

        // horizontal segments, not counting top and bottom
        for (let y = 1; y < this.dto.slices.length; y++) {
          line(
            ctx,
            0,
            y * this.dto.rectangleSliceHeight,
            ret.viewportW,
            y * this.dto.rectangleSliceHeight,
            1,
            BORDER_COLOR
          );
        }

        ctx.restore();
      };
    }
    // Slightly more complex case, using dto.slices as model, handling all logic for paint and mouse here
    else if (shape === 'CIRCLE') {
      // An extra pixel on all sides to prevent drawing from flattening a bit of circle's arc
      ret.setAllDim(this.dto.circleDiameter + 2);

      const sliceCount = this.dto.slices.length;
      const sliceRange = (2 * Math.PI) / sliceCount;
      const radius = this.dto.circleDiameter / 2;
      const cX = radius;
      const cY = radius;

      ret.paintMe = (ctx: CanvasRenderingContext2D): void => {
        ctx.save();

        ctx.translate(1, 1);

        for (let i = 0; i < sliceCount; i++) {
          ctx.fillStyle = this.dto.slices[i] ? SHADED_COLOR : EMPTY_COLOR;
          ctx.beginPath();
          ctx.moveTo(cX, cY);
          ctx.arc(cX, cY, radius, i * sliceRange, (i + 1) * sliceRange);
          ctx.closePath();
          ctx.fill();
        }

        circle(ctx, cX, cY, radius, BORDER_COLOR, true, true, 1);

        // draw wedge borders
        for (let i = 0; i < sliceCount; i++) {
          const endX = cX + radius * Math.cos(((2 * Math.PI) / sliceCount) * i);
          const endY = cY - radius * Math.sin(((2 * Math.PI) / sliceCount) * i);

          line(ctx, cX, cY, endX, endY, 1, BORDER_COLOR);
        }

        ctx.restore();
      };

      ret.mouseDownResponse = (x: number, y: number): boolean => {
        if (!this.isEnabled()) return false;

        // account for 1 pixel of white space on all four sides of circle
        x--;
        y--;

        if (distance(x, y, cX, cY) > radius) {
          return true;
        }

        // transform to coordinates from circle center origin to calculate angle of ray (polar coordinates, angle) from center
        x -= cX;
        y -= cY;
        // Goal: an angle in range 0 <= angle < 2pi, which we later use to deterimine which slice is hit
        // There are eight possibilities for coordinates:
        // 1) on positive x axis
        // 2) on negative x axis
        // 3) on positive y axis
        // 4) on negative y axis
        // IF none of the above:
        // 5) first quadrant
        // 6) second quadrant
        // 7) third quadrant
        // 8) fourth quadrant

        // This handles possibilities 1, 3, and 5
        let angle = Math.atan(y / x);

        // This handles possibilities 2, 6 and 7
        if (x < 0) {
          angle += Math.PI;
        }
        // This handles possibilities 4 and 8
        else if (y < 0) {
          angle += Math.PI * 2;
        }

        const sliceIndex = Math.trunc(angle / sliceRange);

        this.dto.slices[sliceIndex] = !this.dto.slices[sliceIndex];
        // When taking an exam, this will spur auto-submission of attempt when user
        // clicks on a different exam square without clicking submit
        this.changedMaybe(this.agId);

        this.grabFocus();

        return true;
      };
    }

    return ret;
  }

  grabFocus(): void {
    this.problemJS.grabFocus(this);
  }

  getSelectedCount(): number {
    let ret = 0;

    this.dto.slices.forEach(slice => {
      if (slice) ret++;
    });

    return ret;
  }

  serializeAttempt(): any {
    if (!this.problemJS.isTesting()) {
      if (this.getSelectedCount() < 1) {
        problemModalState().alert({
          msg: `You need to select at least one part.`,
          top: 'Invalid',
        });

        return;
      }
    }

    return { slices: this.dto.slices };
  }

  // Record and apply state sent from server
  // Unlikely we'll use this: server doesn't meddle with slices
  update(updatedJson: DTO): void {
    this.dto.slices = updatedJson.slices;
  }
}

class RectangleSlice extends BasicObject {
  index: number;
  dto: DTO;
  getter: FractionShadeGetter;

  constructor(dto: DTO, index: number, getter: FractionShadeGetter) {
    super();
    this.dto = dto;
    this.index = index;
    this.getter = getter;

    this.viewportW = this.dto.rectangleSliceWidth;
    this.viewportH = this.dto.rectangleSliceHeight;

    this.updateFill();
  }

  updateFill(): void {
    this.fill = this.dto.slices[this.index] ? SHADED_COLOR : EMPTY_COLOR;
  }

  mouseDownResponse(): boolean {
    if (!this.getter.isEnabled()) return false;

    this.dto.slices[this.index] = !this.dto.slices[this.index];

    this.updateFill();

    // When taking an exam, this will spur auto-submission of attempt when user
    // clicks on a different exam square without clicking submit
    this.getter.changedMaybe(this.getter.agId);

    this.getter.grabFocus();

    return true;
  }
}
