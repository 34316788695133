import { FC } from 'react';
import Draggable from 'react-draggable';

import {
  DIALOG_BLUE_BODY,
  DIALOG_BLUE_BUTTON,
  ModalButton,
  ModalHeader,
  READ_ALOUD_HIGHLIGHT_BLUE,
  Readable,
  modalStyle,
} from '@gmm/problem';

import { useProcessedWorkStore } from '../../../studentApp/hooks/useProcessedWorkStore';
import { workStore } from '../../../studentApp/stores/workStore';
import { Work } from '../../../studentApp/types';

interface FinishedWorkProps {
  close: () => void;
  options: FinishedWorkOptions;
}

export interface FinishedWorkOptions {
  finishedWork: Work;
}

export const FinishedWorkDialog: FC<FinishedWorkProps> = ({
  close,
  options,
}) => {
  const { currentWorkName: newWorkName } = useProcessedWorkStore();
  const [newWork] = workStore(state => [state.currentWork]);
  const completedWork = options.finishedWork;

  // Format "completed" work name for display
  let modalTitle;
  let oldWorkName = completedWork?.name;
  const oldWorkType = completedWork?.type;

  if (oldWorkType === 'CORRECTIONS') {
    oldWorkName = 'exam corrections';
    modalTitle = 'Corrections Finished';
  } else {
    oldWorkName = 'assignment: ' + oldWorkName;
    modalTitle = 'Assignment Finished';
  }

  // Destructure `work` object for easier reference
  const { type, date, dateAssigned } = newWork;

  // Format "next up" work name and date assigned for display
  let workName = newWorkName;
  const workDate = type === 'CORRECTIONS' ? date : dateAssigned;
  let month, day;

  // Date parsing, handle corrections and other work
  if (workDate) {
    if (type === 'CORRECTIONS') {
      [day, month] = workDate.split('/').map(Number);
      workName = 'exam corrections';
    } else {
      const dateObj = new Date(workDate);

      month = dateObj.getMonth() + 1;
      day = dateObj.getDate();
    }
  }

  return (
    <Draggable handle=".finishedWorkModal">
      <div
        className="blue"
        style={{ ...modalStyle, backgroundColor: DIALOG_BLUE_BODY }}
      >
        <ModalHeader
          close={close}
          title={modalTitle}
          dragHandle={'finishedWorkModal'}
        />
        {
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '20px',
              rowGap: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <img
                src="GMM-images/tabicon.png"
                style={{
                  height: '30px',
                  width: '30px',
                }}
              />
              <img
                src="GMM-images/Smiley.png"
                style={{
                  height: '50px',
                  width: '50px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                }}
              />
              <img
                src="GMM-images/tabicon.png"
                style={{
                  height: '30px',
                  width: '30px',
                }}
              />
            </div>

            <Readable
              highlightColor={READ_ALOUD_HIGHLIGHT_BLUE}
              text={`Congratulations! You finished the ${oldWorkName}.`}
            />

            <Readable
              highlightColor={READ_ALOUD_HIGHLIGHT_BLUE}
              text={`Next up: ${workName} ${
                workDate ? `assigned on ${month}/${day}` : ''
              }`}
            />

            <Readable
              highlightColor={READ_ALOUD_HIGHLIGHT_BLUE}
              text="Keep up the good work!"
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
              }}
            >
              <ModalButton color={DIALOG_BLUE_BUTTON} text="OK" close={close} />
            </div>
          </div>
        }
      </div>
    </Draggable>
  );
};
