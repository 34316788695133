export function dottedLine(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  x2: number,
  y2: number,
  color = 'black',
  da: [number, number] = [9, 6]
): void {
  ctx.save();
  ctx.beginPath();
  const dx = x2 - x,
    dy = y2 - y;
  const len = Math.sqrt(dx * dx + dy * dy);
  const rot = Math.atan2(dy, dx);

  ctx.translate(x, y);
  ctx.moveTo(0, 0);
  ctx.rotate(rot);
  const dc = da.length;
  let di = 0;
  let draw = true;

  x = 0;

  while (len > x) {
    x += da[di++ % dc];
    if (x > len) x = len;
    draw ? ctx.lineTo(x, 0) : ctx.moveTo(x, 0);
    draw = !draw;
  }

  ctx.strokeStyle = color;
  ctx.stroke();
  ctx.closePath();
  ctx.restore();
}
