import { makeStyles } from '@material-ui/core';
import { styled } from '@mui/material';
import { CSSProperties, FC } from 'react';

import logo from '../../../static_files/GMM-images/gmm-letters-white.svg';
import { useLayout, useProcessedBannerStore } from '../../../studentApp/hooks';
import { useProcessedWorkStore } from '../../../studentApp/hooks/useProcessedWorkStore';
import { bannerStore } from '../../../studentApp/stores';
import { userStore } from '../../../studentApp/stores/userStore';
import {
  BANNER_HEIGHT,
  BOX_SHADOW,
  MAX_LANDSCAPE_BANNER_STUDENT_NAME_AND_CLASS_WIDTH,
  MAX_PORTRAIT_BANNER_STUDENT_NAME_AND_CLASS_WIDTH,
} from '../../constants';

import { HamburgerMenu } from './hamburger';
import { Hand } from './hand';
import { WorkNameAndProgress } from './workNameAndProgress';

interface StyleProps {
  isLandscape: boolean;
}

const useStyles = makeStyles({
  nameAndClass: (props: StyleProps) => ({
    maxWidth: props.isLandscape
      ? MAX_LANDSCAPE_BANNER_STUDENT_NAME_AND_CLASS_WIDTH
      : MAX_PORTRAIT_BANNER_STUDENT_NAME_AND_CLASS_WIDTH,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
});

export const Banner: FC = () => {
  const [currentClass, otherClasses] = bannerStore(state => [
    state.className,
    state.otherClasses,
  ]);
  const studentName = userStore(state => state.studentName);
  const { themeColor, isShowHand } = useProcessedBannerStore();
  const { currentWorkType } = useProcessedWorkStore();
  const { isLandscape } = useLayout();
  const classes = useStyles({ isLandscape });

  const nameAndClass =
    studentName + (otherClasses.length > 0 ? ', ' + currentClass : '');

  return (
    <StyledBanner color={themeColor}>
      <img src={logo} style={logoStyle} />

      <WorkNameAndProgress />

      <div style={rightSideStyle}>
        <span className={classes.nameAndClass}>{nameAndClass}</span>

        {isShowHand && <Hand />}

        {currentWorkType !== 'EXAM' && <HamburgerMenu />}
      </div>
    </StyledBanner>
  );
};

export const StyledBanner = styled('div')(({ color }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: BANNER_HEIGHT + 'px',
  minHeight: BANNER_HEIGHT + 'px',
  paddingLeft: '20px',
  paddingRight: '20px',
  backgroundColor: color,
  boxShadow: BOX_SHADOW,
  color: 'white',
}));

const rightSideStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'right',
  alignItems: 'center',
  marginLeft: 'auto',
  color: 'white',
  maxWidth: '300px',
};

export const logoStyle: CSSProperties = {
  display: 'flex',
  height: '33px',
  /* It's all about happy jbeye */
  transform: 'translateY(2px)',
};
