import { FC } from 'react';
import { DragZone } from './dragZone';

interface ModalHeaderProps {
  title: string;
  close: () => void;
  dragHandle: string;
}

export const ModalHeader: FC<ModalHeaderProps> = ({
  title,
  close,
  dragHandle,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <DragZone dragHandle={dragHandle} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
          fontSize: '140%',
          fontWeight: 'bold',
        }}
      >
        <span>{title}</span>

        <div
          onClick={close}
          style={{
            marginLeft: 'auto',
            cursor: 'pointer',
          }}
        >
          <span>&times;</span>
        </div>
      </div>
    </div>
  );
};
