import { FC } from 'react';
import Draggable from 'react-draggable';

import { ModalHeader, modalStyle } from '@gmm/problem';

import { postAssignmentChoice } from '../../../../studentApp/api';
import { studentAppModalStore } from '../../../../studentApp/stores/studentAppModalStore';
import { Work } from '../../../../studentApp/types';

import { ListItem } from './listItem';

interface WorkHistoryProps {
  close: () => void;
}

export const WorkHistory: FC<WorkHistoryProps> = ({ close }) => {
  const workHistory = studentAppModalStore(state => state.workHistory);

  return (
    <Draggable handle=".gmm-work-history-modal">
      <div className="blue" style={modalStyle}>
        <ModalHeader
          title="Work History"
          close={close}
          dragHandle={'gmm-work-history-modal'}
        />

        <div style={{ height: '15px' }}></div>

        <div className="limit-list-height">
          {workHistory.map(work => buildWorkItem(work, close))}
        </div>
      </div>
    </Draggable>
  );
};

export const buildWorkItem = (work: Work, close: () => void): JSX.Element => {
  const launchWork = (work: Work) => {
    postAssignmentChoice(work.workId!, work.type || work.workType);
    close();
  };

  const getFormattedDate = (date: string): string => {
    // check for extra 0s in the date (e.g. 9/04 should be 9/4)
    return date.replace(/\/0/g, '/');
  };

  return (
    <ListItem
      key={work.workId}
      date={getFormattedDate(work.date ?? '')}
      corrections={work.workType === 'CORRECTIONS'}
      text={work.name}
      score={`${work.points} of ${work.required}`}
      disabled={!work.available || work.points! >= work.required!}
      launch={() => launchWork(work)}
    />
  );
};
