import { FC, useState } from 'react';

import { useProcessedBannerStore } from '../../../studentApp/hooks';
import { bannerStore } from '../../../studentApp/stores';
import {
  NEED_HELP,
  studentAppModalStore,
} from '../../../studentApp/stores/studentAppModalStore';
import { buttonStyle } from '../util/constants';

export const NeedHelpButton: FC = () => {
  const setCurrentModal = studentAppModalStore(state => state.setCurrentModal);
  const [
    replacementsPerDay,
    replacementsUsedToday,
    teacherOnline,
    allowReplacementsWhenTeacherOnline,
  ] = bannerStore(state => [
    state.replacementsPerDay,
    state.replacementsUsedToday,
    state.teacherOnline,
    state.allowReplacementsWhenTeacherOnline,
  ]);
  const themeColor = useProcessedBannerStore().themeColor;
  const [isHovered, setIsHovered] = useState(false);

  const showNeedHelpModal = () => {
    if (replacementsUsedToday >= replacementsPerDay) return;
    // Otherwise, the popup will appear on the use of last replacement
    // because hovered was set to true in order to click the button,
    // then never back to false because modal overlay prevents mouse
    // moving off the button from being detected.
    setIsHovered(false);
    setCurrentModal(NEED_HELP);
  };

  const isReplacementAllowedByTeacher =
    !teacherOnline || allowReplacementsWhenTeacherOnline;

  return (
    <div style={{ marginLeft: 'auto', position: 'relative', width: '114px' }}>
      {isReplacementAllowedByTeacher && replacementsPerDay > 0 && (
        <div
          className={`need-help-button ${
            replacementsUsedToday >= replacementsPerDay
              ? 'disabled-need-help'
              : ''
          }`}
          onClick={showNeedHelpModal}
          onTouchStart={() => setIsHovered(true)}
          onMouseEnter={() => setIsHovered(true)}
          style={{
            ...buttonStyle,
            backgroundColor:
              replacementsPerDay - replacementsUsedToday > 0
                ? themeColor
                : 'gray',
          }}
        >
          SHOW ANSWER
        </div>
      )}

      {isReplacementAllowedByTeacher &&
        replacementsPerDay > 0 &&
        replacementsUsedToday >= replacementsPerDay &&
        isHovered && (
          <div
            className="need-help-disabled-popup"
            onMouseLeave={() => setIsHovered(false)}
          >
            You have used all your daily replacements. You get{' '}
            {replacementsPerDay} per day.
          </div>
        )}
    </div>
  );
};
