import { FC, useEffect, useRef } from 'react';
import { useProcessedProblemModalStore } from './useProblemModalStore';
import { Dialog } from './dialog';
import { AxesModal, axesModalStore } from './axesModal';
import { problemModalStore } from './problemModalStore';
import { MODAL_Z_INDEX } from './constants';
import { createPortal } from 'react-dom';

export const ProblemModals: FC = () => {
  const showAxesModal = axesModalStore(state => state.visible);
  const setShowAxesModal = axesModalStore(state => state.setVisible);
  const { isShowingAnyModal } = useProcessedProblemModalStore();
  const [stackedDialogs, popStackableDialog] = problemModalStore(state => [
    state.stackableDialogs,
    state.popStackableDialog,
  ]);
  const setNativeInputLocation = problemModalStore(
    state => state.setNativeInputLocation
  );

  const overlay = useRef<HTMLDivElement>(null);

  const close = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    // Stackable dialogs are 'on top of' modals,
    // close one element per click
    if (stackedDialogs.length > 0) {
      popStackableDialog();
      return;
    }

    if (showAxesModal) {
      setShowAxesModal(false);
      return;
    }

    setNativeInputLocation(undefined);
  };

  useEffect(() => {
    if (!overlay.current) return;
    overlay.current.addEventListener('click', close);

    return () => {
      if (!overlay.current) return;
      document.removeEventListener('click', close);
    };
  }, [isShowingAnyModal]);

  if (!isShowingAnyModal) return null;

  return createPortal(
    <>
      <div
        ref={overlay}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          zIndex: MODAL_Z_INDEX - 1,
        }}
      ></div>
      {showAxesModal && <AxesModal />}

      {stackedDialogs.length > 0 && (
        <Dialog alert={stackedDialogs[stackedDialogs.length - 1]} />
      )}
    </>,
    document.body
  );
};
