import { CSSProperties, FC } from 'react';

import { useLayout } from '../../studentApp/hooks/useLayout';

import { LandscapeLayout } from './landscapeLayout';
import { MobileLayout } from './mobileLayout';
import { Modals } from './modals/modals';
import { PortraitLayout } from './portraitLayout';

const style: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

export const StudentApp: FC = () => {
  const { isMobile, isPortrait, isLandscape } = useLayout();

  return (
    <div className="no-select" style={style}>
      {isMobile && <MobileLayout />}
      {isPortrait && <PortraitLayout />}
      {isLandscape && <LandscapeLayout />}
      <Modals />
    </div>
  );
};
