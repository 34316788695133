import { bannerStore } from '../stores';
import { studentAppModalStore } from '../stores/studentAppModalStore';
import { getThemeColor } from '../types';

import { useLayout } from './useLayout';
import { useProcessedProblems } from './useProcessedProblems';
import { useProcessedWorkStore } from './useProcessedWorkStore';

interface bannerStore {
  showGamesMenu: boolean;
  showDisabledGamesMenu: boolean;
  isShowHand: boolean;
  themeColor: string;
  showSquares: boolean;
}

export const useProcessedBannerStore = (): bannerStore => {
  const [
    gameCredits,
    blockGames,
    blockGamesClass,
    userChoiceForShowSquares,
  ] = bannerStore(state => [
    state.gameCredits,
    state.blockGames,
    state.blockGamesClass,
    state.userChoiceForShowSquares,
  ]);
  const [games] = studentAppModalStore(state => [state.availableGames]);
  const [isAllowHand, teacherOnline, themeOption] = bannerStore(state => [
    state.allowHand,
    state.teacherOnline,
    state.themeOption,
  ]);
  const { currentWorkType } = useProcessedWorkStore();
  const { currentProblem } = useProcessedProblems();
  const { isLandscape } = useLayout();

  const showSquares =
    userChoiceForShowSquares !== undefined
      ? userChoiceForShowSquares
      : isLandscape;

  const showGamesMenu =
    !!gameCredits && games.length > 0 && !blockGames && !blockGamesClass;

  const showDisabledGamesMenu = games.length > 0 && !gameCredits;

  const isShowHand =
    !!currentProblem &&
    isAllowHand &&
    teacherOnline &&
    currentWorkType !== 'EXAM' &&
    currentWorkType !== 'NONE';

  const themeColor = getThemeColor(themeOption);

  return {
    showGamesMenu,
    showDisabledGamesMenu,
    isShowHand,
    themeColor,
    showSquares,
  };
};
