export function circle(
  ctx: CanvasRenderingContext2D,
  cx: number,
  cy: number,
  r: number,
  color = 'magenta',
  open = false,
  dontFillOpen = false,
  lt = 1
): void {
  ctx.save();
  ctx.beginPath();
  ctx.arc(cx, cy, r, 0, 2 * Math.PI, false);
  ctx.closePath();

  if (!open) {
    ctx.fillStyle = color;
    ctx.fill();
  } else {
    if (!dontFillOpen) {
      ctx.fillStyle = 'white';
      ctx.fill();
    }

    ctx.lineWidth = lt;
    ctx.strokeStyle = color;
    ctx.stroke();
  }

  ctx.restore();
}
