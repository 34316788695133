import {
  makeStyles,
  TextField as MuiTextField,
  TextFieldProps,
} from '@material-ui/core';
import { forwardRef, useMemo } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    '& fieldset': {
      background: theme.palette.background.paper,
      fontSize: theme.spacing(2),
      zIndex: -1,
    },
  },
  formHelperText: { fontSize: theme.spacing(1.5) },
  inputRoot: {
    fontSize: theme.spacing(2),
  },
  inputInput: {
    padding: theme.spacing(2, 1.5),
  },
}));

let idCounter = 1;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(props, ref) {
    const classes = useStyles();
    const id = useMemo(() => props.id ?? `text-field-${idCounter++}`, [
      props.id,
    ]);

    return (
      <MuiTextField
        {...props}
        className={classes.root}
        FormHelperTextProps={{ className: classes.formHelperText }}
        id={id}
        InputProps={{
          classes: { root: classes.inputRoot, input: classes.inputInput },
        }}
        inputRef={ref}
      />
    );
  }
);
