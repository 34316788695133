import { FC } from 'react';
import { ModalHeader } from '../modalHeader';
import { Picker } from './labelPicker';
import { ModalButton } from '../modalButton';
import { DIALOG_BLUE_BODY, DIALOG_BLUE_BUTTON } from '../constants';
import Draggable from 'react-draggable';
import { axesModalStore } from './axesModalStore';
import { modalStyle } from '../constants-react';

const numbers = ['1', '5', '10', '20', '50', '100', '200', '500'];

export const AxesModal: FC = () => {
  const [setVisible] = axesModalStore(state => [state.setVisible]);
  const [xScale, setXScale] = axesModalStore(state => [
    state.xScale,
    state.setXScale,
  ]);
  const [yScale, setYScale] = axesModalStore(state => [
    state.yScale,
    state.setYScale,
  ]);
  const [showLabelPickers] = axesModalStore(state => [state.showLabelPickers]);
  const [xLabel, setXLabel] = axesModalStore(state => [
    state.xLabel,
    state.setXLabel,
  ]);
  const [yLabel, setYLabel] = axesModalStore(state => [
    state.yLabel,
    state.setYLabel,
  ]);
  const [labelChoices] = axesModalStore(state => [state.labelChoices]);
  const [
    xScaleChoicesShowing,
    setXScaleChoicesShowing,
  ] = axesModalStore(state => [
    state.xScaleChoicesShowing,
    state.setXScaleChoicesShowing,
  ]);
  const [
    yScaleChoicesShowing,
    setYScaleChoicesShowing,
  ] = axesModalStore(state => [
    state.yScaleChoicesShowing,
    state.setYScaleChoicesShowing,
  ]);
  const [
    xLabelChoicesShowing,
    setXLabelChoicesShowing,
  ] = axesModalStore(state => [
    state.xLabelChoicesShowing,
    state.setXLabelChoicesShowing,
  ]);
  const [
    yLabelChoicesShowing,
    setYLabelChoicesShowing,
  ] = axesModalStore(state => [
    state.yLabelChoicesShowing,
    state.setYLabelChoicesShowing,
  ]);

  const setX = (value: string) => {
    setXScale(parseInt(value));
  };

  const setY = (value: string) => {
    setYScale(parseInt(value));
  };

  const close = () => {
    setVisible(false);
  };

  const closeAllChoices = () => {
    setXScaleChoicesShowing(false);
    setYScaleChoicesShowing(false);
    setXLabelChoicesShowing(false);
    setYLabelChoicesShowing(false);
  };

  return (
    <Draggable handle=".gmm-axes-modal">
      <div
        onClick={closeAllChoices}
        style={{
          ...modalStyle,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'left',
          backgroundColor: DIALOG_BLUE_BODY,
          border: '1px solid black',
        }}
      >
        <ModalHeader
          title={'Axes Settings'}
          close={close}
          dragHandle={'gmm-axes-modal'}
        />

        <div style={{ height: '20px' }}></div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Picker
            title={'X Scale'}
            current={xScale}
            setCurrent={setX}
            choices={numbers}
            choicesShowing={xScaleChoicesShowing}
            setChoicesShowing={setXScaleChoicesShowing}
          />
          <Picker
            title={'Y Scale'}
            current={yScale}
            setCurrent={setY}
            choices={numbers}
            choicesShowing={yScaleChoicesShowing}
            setChoicesShowing={setYScaleChoicesShowing}
          />
        </div>

        {showLabelPickers && (
          <div>
            <div style={{ height: '20px' }} />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Picker
                title={'X Label'}
                current={xLabel}
                setCurrent={setXLabel}
                choices={labelChoices}
                choicesShowing={xLabelChoicesShowing}
                setChoicesShowing={setXLabelChoicesShowing}
              />
              <Picker
                title={'Y Label'}
                current={yLabel}
                setCurrent={setYLabel}
                choices={labelChoices}
                choicesShowing={yLabelChoicesShowing}
                setChoicesShowing={setYLabelChoicesShowing}
              />
            </div>
          </div>
        )}

        <div style={{ height: '20px' }}></div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
          }}
        >
          <ModalButton color={DIALOG_BLUE_BUTTON} text="OK" close={close} />
        </div>
      </div>
    </Draggable>
  );
};
