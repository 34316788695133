import { sortBy } from 'lodash';

import { ExamProblem, NormalProblem, problemState } from '../stores';
import { OrderOption, Problems, Proficiency } from '../types';

export const colorsArray: Proficiency[] = [
  'untried',
  'red',
  'yellow',
  'green',
  'silver',
  'gold',
];

export const orderOptionsArray: OrderOption[] = [
  'Date Learned',
  '$ First',
  'Skill Level',
  'Date of Attempt',
];

export interface ColorData {
  iconColor: string;
  isStar: boolean;
  level: number;
}

export const getColorData = (proficiency: Proficiency): ColorData => {
  return colorData[proficiency];
};

export const colorData: Record<Proficiency, ColorData> = {
  untried: {
    iconColor: 'black',
    isStar: false,
    level: 0,
  },
  'not enough data': {
    iconColor: 'black',
    isStar: false,
    level: 0,
  },
  red: {
    iconColor: 'black',
    isStar: false,
    level: 1,
  },
  yellow: {
    iconColor: 'black',
    isStar: false,
    level: 2,
  },
  green: {
    iconColor: 'black',
    isStar: false,
    level: 3,
  },
  silver: {
    iconColor: 'gray',
    isStar: true,
    level: 4,
  },
  gold: {
    iconColor: 'gold',
    isStar: true,
    level: 5,
  },
};

export const getScore = (problem: NormalProblem): number => {
  const score = problem.rawScore || 0;
  const possiblePoints = [
    !problem.lvl ? -100 : 0,
    100,
    1000,
    10000,
    100000,
    1000000,
  ];
  const { level } = colorData[problem.lvl];

  const points = score + possiblePoints[level];

  return points;
};

export type ProblemShared = NormalProblem | ExamProblem;

export const isExamProblem = (
  problem: Omit<ProblemShared, 'id'>
): problem is ExamProblem => problem && 'unseen' in problem;

export const isExamProblemArray = (
  problemArray: ProblemShared[]
): problemArray is ExamProblem[] => problemArray.some(isExamProblem);

export const isNormalProblem = (
  problem: ProblemShared
): problem is NormalProblem => !isExamProblem(problem);

export const sortNormalProblems = (
  problemArray: NormalProblem[],
  dollars: string[],
  order: OrderOption
): NormalProblem[] => {
  if (order === '$ First') {
    return sortBy(problemArray, problem =>
      dollars.includes(problem.id) ? 0 : 1
    );
  }

  if (order === 'Date of Attempt')
    return sortBy(problemArray, 'lastAttempt', 'desc');
  if (order === 'Skill Level')
    return sortBy(problemArray, problem => getScore(problem));

  return sortBy(problemArray, p => Number(p.id), 'asc');
};

export const getOrderedIDs = ({
  dollars,
  orderBy,
  problems,
  dollarsOnly,
}: {
  dollars: string[];
  orderBy: OrderOption;
  problems: Problems;
  dollarsOnly?: boolean;
}): string[] => {
  const problemArray = dollarsOnly
    ? dollars.map(id => problems[id])
    : Object.values(problems);

  const sorted = isExamProblemArray(problemArray)
    ? sortBy(problemArray, 'testNumber')
    : sortNormalProblems(problemArray, dollars, orderBy);

  return sorted.map<string>((p: ProblemShared) => p.id);
};

export const getExamOrderedIds = (
  problems: Record<string, ExamProblem>
): string[] => {
  const problemArray = Object.values(problems);
  const sorted = sortBy(problemArray, 'testNumber');

  return sorted.map<string>((p: ExamProblem) => p.id);
};

export const getProblemIds = (problems: Problems): string[] => {
  const problemArray = Object.values(problems);

  return problemArray.map<string>((p: ProblemShared) => p.id);
};

export const allExamProblemsAreLocked = (): boolean => {
  return Object.values(problemState().problems)
    .filter(isExamProblem)
    .every(problem => (problem as ExamProblem).locked);
};
