import { Point } from '../../index';

import { Line } from './types';

export const isPointLeftOfLine = (
  point: Point,
  [linePoint1, linePoint2]: Line
): boolean =>
  (linePoint2.x - linePoint1.x) * (point.y - linePoint1.y) -
    (linePoint2.y - linePoint1.y) * (point.x - linePoint1.x) >
  0;
