import { Menu, MenuItem, IconButton } from '@mui/material';
import { useState, MouseEvent, FC } from 'react';

import {
  postRequestAssignmentsHistory,
  sendRaisedHand,
} from '../../../studentApp/api';
import { useProcessedWorkStore } from '../../../studentApp/hooks';
import { useLayout } from '../../../studentApp/hooks/useLayout';
import { useProcessedBannerStore } from '../../../studentApp/hooks/useProcessedBannerStore';
import { useProcessedStudentAppModalStore } from '../../../studentApp/hooks/useStudentAppModalStore';
import { bannerStore, problemStore } from '../../../studentApp/stores';
import { getGuid, getSicId } from '../../../studentApp/stores/globalState';
import {
  CHOOSE_WORK,
  EXAMS,
  GAMES,
  INFO,
  ModalType,
  SETTINGS,
  STATISTICS,
  SWITCH_CLASS,
  studentAppModalStore,
} from '../../../studentApp/stores/studentAppModalStore';

import { Hand } from './hand';

export const HamburgerMenu: FC = () => {
  const otherClasses = bannerStore(state => state.otherClasses);
  const setModal = studentAppModalStore(state => state.setCurrentModal);
  const { hasWorkChoices } = useProcessedWorkStore();
  const allowExamsOnMobile = bannerStore(state => state.allowExamsOnMobile);
  const { isMobile } = useLayout();
  const { showExamsMenu } = useProcessedStudentAppModalStore();
  const {
    showGamesMenu,
    showDisabledGamesMenu,
    isShowHand,
  } = useProcessedBannerStore();
  const [isHandRaised, setIsHandRaised] = bannerStore(state => [
    state.isHandRaised,
    state.setIsHandRaised,
  ]);
  const selectedId = problemStore(state => state.selectedID);

  const hideMobileExams = isMobile && !allowExamsOnMobile;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const launchModal = (modal: ModalType) => {
    setModal(modal);
    handleClose();
  };

  const handleAssignmentHistory = (): void => {
    postRequestAssignmentsHistory();
    handleClose();
  };

  const toggleRaiseHand = (): void => {
    sendRaisedHand(!isHandRaised, selectedId);
    setIsHandRaised(!isHandRaised);
  };

  return (
    <div style={{ display: 'flex' }}>
      <IconButton onClick={handleClick}>
        <img
          src="GMM-icons/GMM-icon-hamburger.svg"
          style={{ height: '30px' }}
        />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onKeyDown={handleKeyDown}
      >
        {isMobile && hasWorkChoices && (
          <MenuItem onClick={() => launchModal(CHOOSE_WORK)}>
            Choose Work
          </MenuItem>
        )}

        {isMobile && isShowHand && (
          <MenuItem onClick={toggleRaiseHand}>
            <Hand />
          </MenuItem>
        )}

        {otherClasses.length > 0 && (
          <MenuItem onClick={() => launchModal(SWITCH_CLASS)}>
            Switch Class
          </MenuItem>
        )}

        {showExamsMenu && !hideMobileExams && (
          <MenuItem onClick={() => launchModal(EXAMS)}>
            Exams & Follow-ups
          </MenuItem>
        )}

        {showGamesMenu && (
          <MenuItem onClick={() => launchModal(GAMES)}>Play Game</MenuItem>
        )}

        {showDisabledGamesMenu && <MenuItem disabled>Play Game</MenuItem>}

        <MenuItem onClick={() => launchModal(SETTINGS)}>Settings</MenuItem>

        <MenuItem onClick={() => launchModal(STATISTICS)}>Statistics</MenuItem>

        <MenuItem onClick={() => launchModal(INFO)}>Your Info</MenuItem>

        <MenuItem onClick={handleOpenPercentCalendar}>Calendar</MenuItem>

        <MenuItem onClick={handleAssignmentHistory}>Work History</MenuItem>

        <MenuItem>
          <a
            href="https://vimeo.com/showcase/7480752"
            rel="noreferrer"
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            App Video Tutorials
          </a>
        </MenuItem>
      </Menu>
    </div>
  );
};

const handleOpenPercentCalendar = (): void => {
  const win = window.open(
    'studentCalendar.html?guid=' +
      getGuid() +
      '&studentInClassId=' +
      getSicId(),
    '_blank'
  );

  win?.focus();
};
