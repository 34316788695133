import { sendObject } from './handler';

export type ToggleClock = { type: 'toggleClock'; showClock: 't' | 'f' };

type Options = {
  showClock: boolean;
};

export const postToggleClock = async ({
  showClock,
}: Options): Promise<void> => {
  return sendObject({ type: 'toggleClock', showClock: showClock ? 't' : 'f' });
};
