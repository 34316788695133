import { CSSProperties, FC } from 'react';

import { MODAL_Z_INDEX } from './constants';

export const DownTriangle: FC = () => {
  return (
    <div
      style={{
        width: 0,
        height: 0,
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderTop: '5px solid darkgray',
        marginRight: '5px',
        marginLeft: 'auto',
        display: 'inline-block',
      }}
    ></div>
  );
};

export const modalStyle: CSSProperties = {
  display: 'block',
  padding: '0px 20px 20px 20px',
  width: '340px',
  minWidth: '340px',
  maxWidth: '340px',
  maxHeight: '500px',
  position: 'fixed',
  top: '50px',
  left: 'calc(50% - 170px)',
  zIndex: MODAL_Z_INDEX,
  color: 'white',
};
