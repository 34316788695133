import { ClickAwayListener, Tooltip, Typography } from '@material-ui/core';
import { FC, useState } from 'react';

import { normalBoxClick, NormalProblem, problemStore } from '../../../stores';

import { BoxAnimation } from './boxAnimation';
import { CorrectionBox } from './correctionBox';
import { OuterBox } from './outerBox';

type Props = {
  problem: NormalProblem;
  replica?: boolean;
};

export const NormalBox: FC<Props> = ({ problem, replica }) => {
  const isDollar = problemStore(state => state.dollars.includes(problem.id));
  const dollarsOnly = problemStore(state => state.dollarsOnly);
  const isSelected = problemStore(state => state.selectedID === problem.id);
  const [showingTooltip, setShowingTooltip] = useState(false);

  const isCorrection = problem.type === 'EXAM_CORRECTIONS';

  const handleClick = (): void => {
    if (replica) return;

    if (dollarsOnly && !isDollar) {
      setShowingTooltip(true);

      return;
    }

    normalBoxClick(problem.id);
  };

  const handleTooltipClose = (): void => {
    setShowingTooltip(false);
  };

  return (
    <>
      {isCorrection ? (
        <CorrectionBox problem={problem} replica={replica} />
      ) : (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            open={showingTooltip}
            onClose={handleTooltipClose}
            placement="top"
            title={
              <Typography style={{ fontSize: 12 }}>
                You may only work on skills worth a point $
              </Typography>
            }
          >
            <OuterBox
              id={problem.id}
              isSelected={!!isSelected && !replica}
              onClick={handleClick}
            >
              <BoxAnimation
                problem={problem}
                key={problem.id}
                replica={replica}
              />
            </OuterBox>
          </Tooltip>
        </ClickAwayListener>
      )}
    </>
  );
};
