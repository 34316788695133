import { CSSProperties, FC } from 'react';

import { problemStore } from '../../../studentApp/stores/problemStore';
import { ProblemShared } from '../../../studentApp/utils';

import { Label } from './label';
import { Squares } from './squares';

const styles: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

interface Props {
  text: string | number | undefined;
  dateOrdinalIndicator?: string;
  problems: ProblemShared[];
}

export const LabelAndSquares: FC<Props> = ({
  text,
  dateOrdinalIndicator,
  problems,
}) => {
  const selectedID = problemStore(state => state.selectedID);
  const hasSelected = problems.some(p => p.id === selectedID);

  return (
    <div style={styles}>
      <Label
        text={text}
        dateOrdinalIndicator={dateOrdinalIndicator}
        hasSelected={hasSelected}
      />
      <Squares problems={problems} />
    </div>
  );
};
