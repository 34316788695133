import { FC, useEffect } from 'react';

import { MODAL_Z_INDEX } from '@gmm/problem';

import { scatterSmileys, setRewardImage } from '../../../studentApp/legacy';

interface SmileysProps {
  close: () => void;
}

export const Smileys: FC<SmileysProps> = ({ close }) => {
  useEffect(() => {
    setRewardImage();
    scatterSmileys();
  }, []);

  return <div id="smileys" onClick={close} style={{ zIndex: MODAL_Z_INDEX }} />;
};
