// This file is the 'model' for reading a panel aloud.

import { ReadAloudButtonDependencies } from './readAloudButton';
import { DEBUGGING_READ_ALOUD } from './readAloudUtil';

const PAUSE = ' : ';

export interface HighlightLocation {
  x: number;
  y: number;
  w: number;
  h: number;
}

// Server builds this per row panel when bundling problem state for client.
// See Problem Authoring: HTML.to, TTS.java, and method PanelSupplier.toTTS.
export interface TTSDTO {
  // What the SpeechSynthesizer should speak.
  spoken: string;

  // The indexes upon which the SpeechSynthesizer will fire 'onboundary' events.
  // See comment on spokenToDisplayed, below, for more info.
  spokenIndexes: number[];

  // The indexes of the corresponding characters in the displayed text.
  // See comment on spokenToDisplayed, below, for more info.
  displayIndexes: number[];

  highlightLocations?: HighlightLocation[];
}

// BasicObject has default implementations of these methods,
// then mathy subclasses override them.
// For examples, see the huge index.js file and search for
// setHighlightReadAloud and/or getPaintedCharCount.
interface Highlightable {
  // Highlight starting at index and stopping at next ' '.
  setHighlightReadAloud(index: number): void;
  // How many displayed chars does an element have?
  getPaintedCharCount(): number;
  // Stop highlighting
  clearHighlightReadAloud(): void;
}

export class TTS {
  // Feed this to the SpeechSynthesizer
  spokenText = '';

  // Index of character from spokenText mapped to
  // corresponding index of same character in displayed text.
  // For example:
  // spokenText: "3 minus x"
  // displayedText: "3 - x"
  // map: 0 => 0, 2 => 2, 8 => 4
  spokenToDisplayed: Map<number, number>;

  // The BasicObject(s) that make up the displayed text.
  // This is an Array so that we can bind more than one
  // panel with a single button. For example, some rows
  // have two panels, one with a short direction and one
  // with a mathy display. We want to bind both to the same
  // button.
  displayedElements = new Array<Highlightable>();

  problemJS: ReadAloudButtonDependencies;

  constructor(
    ttsDTO: TTSDTO,
    displayedElement: Highlightable,
    problemJS: ReadAloudButtonDependencies
  ) {
    this.spokenToDisplayed = new Map<number, number>();
    this.problemJS = problemJS;

    this.appendTTSDTO(ttsDTO, displayedElement);
  }

  startsWithPause(s: string): boolean {
    return s.indexOf(PAUSE) === 0;
  }

  endsWithPause(s: string): boolean {
    if (s.indexOf(PAUSE) > -1) {
      // pause is at the end of the string
      if (s.lastIndexOf(PAUSE) === s.length - PAUSE.length) return true;

      // remove trailing space(s) and check again
      // (i.e. additional space(s) after the pause)
      while (s.charAt(s.length - 1) === ' ') {
        return this.endsWithPause(s.substring(0, s.length - 1));
      }
    }

    return false;
  }

  // ProblemJS sometimes melds TTSDTO from several server-provided
  // panels into one client-side TTS object. For example, if a
  // problem instance has a single level with two panels, we
  // probably only want one read aloud icon. Ex: "Simplify: " and "X + X".
  // We insert a pause if there isn't one already.
  appendTTSDTO(ttsDTO: TTSDTO, displayedElement: Highlightable): void {
    let spokenStart = 0;

    if (this.spokenText.length > 0) {
      // insert a pause if there isn't one already
      const pause =
        this.endsWithPause(this.spokenText) ||
        this.startsWithPause(ttsDTO.spoken)
          ? ''
          : PAUSE;

      spokenStart = this.spokenText.length + pause.length;
      this.spokenText += pause + ttsDTO.spoken;
    } else {
      this.spokenText = ttsDTO.spoken;
    }

    // don't want dangling pauses at the end of the spoken text...
    // ...e.g. Chromebooks with newer OS versions read the pause as "colon"
    this.spokenText = this.removeTrailingPauses();

    const nextDisplayedChar = this.getPaintedCharCount();

    // incoming map needs to be tacked onto existing map
    for (let i = 0; i < ttsDTO.spokenIndexes.length; i++) {
      this.spokenToDisplayed.set(
        ttsDTO.spokenIndexes[i] + spokenStart,
        ttsDTO.displayIndexes[i] + nextDisplayedChar
      );
    }

    this.addDisplayedElement(displayedElement);
  }

  addDisplayedElement(displayedElement: Highlightable): void {
    this.displayedElements.push(displayedElement);
  }

  getPaintedCharCount(): number {
    return this.displayedElements.reduce(
      (acc, child) => acc + child.getPaintedCharCount(),
      0
    );
  }

  getSpokenText(): string {
    return this.spokenText;
  }

  removeTrailingPauses(): string {
    let spokenText = this.spokenText;

    if (this.endsWithPause(spokenText)) {
      spokenText = spokenText.substring(0, spokenText.lastIndexOf(PAUSE));
    }

    return spokenText;
  }

  // for debugging
  consoleMap(): void {
    console.log('spokenToDisplayed');
    this.spokenToDisplayed.forEach((value: number, key: number) => {
      console.log(key + ' => ' + value);
    });
  }

  setHighlightReadAloudFromSpokenIndex(spokenIndex: number): void {
    let displayedIndex = this.spokenToDisplayed.get(spokenIndex);

    if (displayedIndex === undefined) {
      let text = 'unknown';

      if (this.spokenText.length > spokenIndex) {
        text = this.spokenText.substring(spokenIndex);
      }

      if (DEBUGGING_READ_ALOUD) {
        console.log(
          'NO MAPPING for spokenIndex ' + spokenIndex + ' (maps to ' + text
        );
      }

      return;
    }

    if (DEBUGGING_READ_ALOUD) {
      console.log('mapped ' + spokenIndex + ' to ' + displayedIndex);
      console.log('spokenText: ' + this.spokenText);
    }

    // Find the element that needs to be highlighted.
    for (let x = 0; x < this.displayedElements.length; x++) {
      const nextDisplayedElement = this.displayedElements[x];

      nextDisplayedElement.clearHighlightReadAloud();
      const paintedCharCount = nextDisplayedElement.getPaintedCharCount();

      if (paintedCharCount > displayedIndex) {
        nextDisplayedElement.setHighlightReadAloud(displayedIndex);
        break;
      }

      // Individual elements internally start their indexes at 0.
      displayedIndex -= paintedCharCount;
    }

    this.problemJS.paintCanvas();
  }

  clearHighlightReadAloud(): void {
    this.displayedElements.forEach(displayedElement => {
      displayedElement.clearHighlightReadAloud();
    });
  }
}
