import { yupResolver } from '@hookform/resolvers/yup';
import { styled, Typography } from '@material-ui/core';
import axios, { AxiosError } from 'axios';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
// import { DialogText } from '../../../stores';
import { useAppContext } from '../../stores/appContext';
import { ModalContainer } from '../components/modalContainer';
import { useModal } from '../../hooks/useModal';
import { Button, TextField } from '../../ui';
import { DialogText } from '../../stores/modalStore';

const Form = styled('form')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1.75),
}));

const Fields = styled('div')(({ theme }) => ({
  width: theme.spacing(33.5),
  '& > div': {
    marginBottom: theme.spacing(0.75),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3.5),
}));

const getDialogText = (className: string): DialogText => {
  const buttonText = 'OKAY';
  const description = `Sorry, enrollment appears to be turned off for the class, ${className}.  Please contact your teacher for further assistance.`;
  const title = 'Enrollment Not Enabled';

  return { buttonText, description, title };
};

const introMessage = 'Please enter your information';
const alphaMessage = '${path} should only contain letters';
const alphaNumericMessage = '${path} should only contain letters and numbers';
const notAllowedMessage = 'Not allowed';
const passwordMatchMessage = 'Passwords must match';
const requiredMessage = 'Enter a ${path}';

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .label('First Name')
    .required(requiredMessage)
    .trim()
    .max(40)
    .matches(/^[a-zA-Z']+$/, alphaMessage),
  lastName: yup
    .string()
    .label('Last Name')
    .required(requiredMessage)
    .trim()
    .max(40)
    .matches(/^[a-zA-Z']+$/, alphaMessage),
  password: yup
    .string()
    .label('Password')
    .required(requiredMessage)
    .trim()
    .max(40)
    .matches(/[^']/, notAllowedMessage),
  passwordTwo: yup
    .string()
    .label('Confirm Password')
    .required(requiredMessage)
    .trim()
    .max(40)
    .oneOf([yup.ref('password')], passwordMatchMessage),
  username: yup
    .string()
    .label('Username')
    .required(requiredMessage)
    .trim()
    .max(40)
    .matches(/^[a-zA-Z0-9]+$/, alphaNumericMessage),
});

export interface RegisterValues {
  firstName: string;
  lastName: string;
  password: string;
  passwordTwo: string;
  username: string;
}

const defaultValues: RegisterValues = {
  firstName: '',
  lastName: '',
  password: '',
  passwordTwo: '',
  username: '',
};

interface ValidationParameterError {
  message: string;
  path: string;
}

const isAxiosError = (error: any): error is AxiosError => !!error?.isAxiosError;
const parseErrors = (errors: any): ValidationParameterError => {
  if (errors.NewPassword)
    return { path: 'password', message: errors.NewPassword[0] || '' };
  if (errors.UserName)
    return { path: 'username', message: errors.UserName[0] || '' };
  const path = Object.keys(errors)[0];

  return { path, message: errors[path][0] };
};

interface Props {
  onLogin: () => void;
}

export const Register: FC<Props> = ({ onLogin }) => {
  const { pubsub, urlParams } = useAppContext();
  const { classCode } = urlParams;
  const {
    formState,
    handleSubmit,
    register,
    setError,
  } = useForm<RegisterValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;

  const { show } = useModal();
  
  const notifyStudent = (): void =>
    show({
      dialogText: getDialogText(urlParams.className),
    });

  const onSubmit = async (data: RegisterValues): Promise<void> => {
    const { firstName, lastName, username, password } = data;

    const params = {
      firstName,
      lastName,
      username,
      password,
      classCode,
    };

    try {
      await axios.post(`${process.env.JAVA_URL}/student/api/accounts`, params);

      onLogin();
      pubsub.publish('login', {
        username,
        password,
      });
    } catch (error) {
      if (!isAxiosError(error)) return;

      if (error.response?.status === 404) {
        notifyStudent();

        return;
      }

      const { errors } = error.response?.data;

      const parsedError = parseErrors(errors);

      setError(parsedError.path as keyof RegisterValues, {
        type: 'server',
        message: parsedError.message,
      });
    }
  };

  return (
    <Form id="registerForm" onSubmit={handleSubmit(onSubmit)}>
      <Title align="center" variant="h4">
        {"Let's create your Get More Math account"}
      </Title>
      <ModalContainer />
      <Title align="center" variant="h6">
        {introMessage}
      </Title>
      <Fields>
        <TextField
          {...register('firstName')}
          autoFocus
          defaultValue={defaultValues.firstName}
          error={!!errors.firstName?.message}
          fullWidth
          helperText={errors.firstName?.message ?? ' '}
          label="First name"
          variant="outlined"
        />
        <TextField
          {...register('lastName')}
          defaultValue={defaultValues.lastName}
          error={!!errors.lastName?.message}
          fullWidth
          helperText={errors.lastName?.message ?? ' '}
          label="Last name"
          variant="outlined"
        />
        <TextField
          {...register('username')}
          defaultValue={defaultValues.username}
          error={!!errors.username?.message}
          fullWidth
          helperText={errors.username?.message ?? ' '}
          label="Pick a username"
          variant="outlined"
        />
        <TextField
          {...register('password')}
          defaultValue={defaultValues.password}
          error={!!errors.password?.message}
          fullWidth
          helperText={errors.password?.message ?? ' '}
          label="Set a password"
          type="password"
          variant="outlined"
        />
        <TextField
          {...register('passwordTwo')}
          defaultValue={defaultValues.passwordTwo}
          error={!!errors.passwordTwo?.message}
          fullWidth
          helperText={errors.passwordTwo?.message ?? ' '}
          label="Confirm password"
          type="password"
          variant="outlined"
        />
        <Button color="primary" fullWidth type="submit" variant="contained">
          Create Account
        </Button>
        <Button onClick={onLogin} fullWidth color="primary" variant="text">
          I already have an account
        </Button>
      </Fields>
    </Form>
  );
};
