import { FC } from 'react';
import Draggable from 'react-draggable';

import { DIALOG_BLUE_BODY, ModalHeader, modalStyle } from '@gmm/problem';

interface MessageFromTeacherProps {
  close: () => void;
  text: string;
}

export const MessageFromTeacher: FC<MessageFromTeacherProps> = ({
  close,
  text,
}) => {
  return (
    <Draggable handle=".gmm-message-from-teacher">
      <div style={{ ...modalStyle, backgroundColor: DIALOG_BLUE_BODY }}>
        <ModalHeader
          title="Message"
          close={close}
          dragHandle={'gmm-message-from-teacher'}
        />

        <div style={{ height: '20px' }}></div>

        <span>From: Your Teacher</span>

        <div style={{ height: '20px' }}></div>

        {/* as of April 2024, we've decided to not support read aloud of teacher-to-student messages */}
        <div
          style={{
            maxHeight: '100px',
            overflowY: 'auto',
            borderStyle: 'solid',
            borderColor: 'white',
            borderWidth: '1px',
            color: 'white',
            padding: '5px',
          }}
        >
          {text}
        </div>
      </div>
    </Draggable>
  );
};
