import gameMaster from '../../legacy/gameengine';
import { LaunchGame } from '../responseHandlerShared';

import { sendObject } from './handler';

export type Spend = {
  type: 'spend';
  target: 'game';
  game: string;
  checkSaved: boolean;
  credits: 1;
};

export const postSpendGameCredit = async (
  game: string,
  onSuccess: (data: LaunchGame) => void
): Promise<void> => {
  if (!game) return;

  const checkSaved = !!gameMaster.hasGame(game!);

  return sendObject<LaunchGame>(
    {
      game,
      checkSaved,
      type: 'spend',
      target: 'game',
      credits: 1,
    },
    { onSuccess }
  );
};
