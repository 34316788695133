import { BasicObject } from '../uiObjects';

// getFullTopLeftLocation and getFullTopLeftLocation2 were
// both members of getBasicObject. Ideally, we would distribute
// their logic into the BasicObject class hierarchy at some point.
// However, for now, stashing them here to achieve zero-dependency
// BasicObject.
export const getFullTopLeftLocation = function (
  basicObject: BasicObject
): { x: number; y: number } {
  let ret;

  if (basicObject.locationParent) {
    const ploc = getFullTopLeftLocation(basicObject.locationParent);

    ret = {
      x:
        ploc.x +
        basicObject.viewportX +
        basicObject.locationParent.viewportSlideX,
      y:
        ploc.y +
        basicObject.viewportY +
        basicObject.locationParent.viewportSlideY,
    };
  } else {
    ret = { x: basicObject.viewportX, y: basicObject.viewportY };
  }

  return ret;
};

// See above comment on getFullTopLeftLocation to get a sense for
// why we are at least for now using 'any' in this parameter
export const getFullTopLeftLocation2 = function (
  basicObject: any
): { x: number; y: number } {
  let ret;

  if (
    basicObject.gmmName === 'valueTableGetter' ||
    (basicObject.gmmName === 'answerPlusSupplier' &&
      basicObject.getter &&
      basicObject.getter.gmmName === 'valueTableGetter')
  ) {
    const table = basicObject.getter
      ? basicObject.getter.valueTable
      : basicObject.valueTable;
    const ploc = basicObject.getter
      ? getFullTopLeftLocation(basicObject)
      : getFullTopLeftLocation(table);
    let editLineX = 0;

    for (let i = 0; i < table.editableLines.length; i++) {
      if (table.editableLines[i].isFocused()) {
        editLineX = table.editableLines[i].viewportX;
        break;
      }
    }

    ret = {
      x: ploc.x - editLineX,
      y: ploc.y,
    };
  } else if (basicObject.parent) {
    const ploc = getFullTopLeftLocation2(basicObject.parent);

    ret = {
      x: ploc.x + basicObject.viewportX + basicObject.parent.viewportSlideX,
      y: ploc.y + basicObject.viewportY + basicObject.parent.viewportSlideY,
    };
  } else if (basicObject.gmmName === 'line') {
    if (basicObject.normalGetter) {
      const ploc = getFullTopLeftLocation(basicObject.normalGetter);

      ret = {
        x: ploc.x + basicObject.viewportX,
        y: ploc.y + basicObject.viewportY,
      };
    } else {
      // tableGetter
      const ploc = getFullTopLeftLocation(basicObject.tableGetter);

      ploc.x += basicObject.uiCell.viewportX;
      ploc.y += basicObject.uiCell.viewportY;
      ret = {
        x: ploc.x,
        y: ploc.y + basicObject.viewportY,
      };
    }
  } else {
    ret = { x: basicObject.viewportX, y: basicObject.viewportY };
  }

  return ret;
};
