import { FC } from 'react';
import Draggable from 'react-draggable';

import { ModalHeader, modalStyle } from '@gmm/problem';

import { useProcessedWorkStore } from '../../../studentApp/hooks';
import { bannerStore } from '../../../studentApp/stores';
import { userStore } from '../../../studentApp/stores/userStore';
import { workStore } from '../../../studentApp/stores/workStore';

interface InfoProps {
  close: () => void;
}

export const Info: FC<InfoProps> = ({ close }) => {
  const [
    pointsThisWeek,
    gameCredits,
    pointsToday,
    dailyGoal,
    teacherName,
    className,
  ] = bannerStore(state => [
    state.pointsThisWeek,
    state.gameCredits,
    state.pointsToday,
    state.dailyGoal,
    state.teacherName,
    state.className,
  ]);
  const studentName = userStore(state => state.studentName);
  const work = workStore(state => state.currentWork);
  const progress = useProcessedWorkStore().currentWorkProgress;

  return (
    <Draggable handle=".gmm-info-modal">
      <div className="blue" style={modalStyle}>
        <ModalHeader
          close={close}
          title={'Info'}
          dragHandle={'gmm-info-modal'}
        />

        <div style={{ height: '5px' }}></div>

        <OneLine text={'Name: ' + studentName} />
        <OneLine text={'Work: ' + work.name} />
        {work.type !== 'SPIRAL_REVIEW' && (
          <OneLine text={'Progress: ' + progress} />
        )}
        <OneLine text={'Points today: ' + pointsToday} />
        <OneLine text={'Points this week: ' + pointsThisWeek} />
        <OneLine text={'Game credits: ' + gameCredits} />

        {!!dailyGoal && pointsToday >= dailyGoal && <GoalCompleted />}

        {!!dailyGoal && pointsToday < dailyGoal && (
          <OneLine text={'Points remaining: ' + (dailyGoal - pointsToday)} />
        )}

        <OneLine text={'Teacher: ' + teacherName} />
        <OneLine text={'Class: ' + className} />
      </div>
    </Draggable>
  );
};

interface OneLineProps {
  text: string;
}

const OneLine: FC<OneLineProps> = ({ text }) => {
  return <div style={{ padding: '10px' }}>{text}</div>;
};

const GoalCompleted: FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      <div className="homework-done-circle">
        <img src="GMM-icons/GMM-icon-check.svg" />
      </div>
      <span style={{ paddingLeft: '10px' }}>Goal completed!</span>
    </div>
  );
};
