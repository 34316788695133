import { styled } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';

import { BOX_SHADOW } from '../../../../cnusr/constants';
import { NormalProblem, normalBoxClick, problemStore } from '../../../stores';

import { boxIcons } from './boxIcons';

interface Props {
  problem: NormalProblem;
  replica?: boolean;
}
const InnerBox = styled('div')({
  height: 24,
  width: 24,
  position: 'relative',
});

export const CorrectionBox: FC<Props> = ({ problem, replica }) => {
  const selectedID = problemStore(state => state.selectedID);
  const { id, isUnfixedExamCorrection } = problem;
  const isSelected = selectedID === id;

  const handleClick = (): void => {
    normalBoxClick(problem.id);
  };

  return (
    <div
      className={clsx('box', {
        'box-selected': isSelected && !replica,
      })}
      style={{ cursor: replica ? 'default' : 'pointer' }}
      id={id}
      onClick={handleClick}
    >
      <div
        className={clsx('innerBox center', { 'test-valid': true })}
        style={{ boxShadow: replica ? '' : BOX_SHADOW }}
      >
        <InnerBox>
          {isUnfixedExamCorrection
            ? boxIcons['exclamation-white']
            : boxIcons['check']}
        </InnerBox>
      </div>
    </div>
  );
};
