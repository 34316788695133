import { line } from './line';

export function lineA(
  ctx: CanvasRenderingContext2D,
  [x1, y1, x2, y2]: [x1: number, y1: number, x2: number, y2: number],
  thick = 1,
  color = 'black'
): void {
  return line(ctx, x1, y1, x2, y2, thick, color);
}
