import { arrow, circle, line } from '../renderers';
import { drawImage, getImage } from '../utils';

import { BasicObject } from './basicObject';

// Draws up to one user-created (via mouseDown) segment
// at a time on top of this object (and all of its children).
// Used by Problem314020.
export class ScribbleObject extends BasicObject {
  SCRIBBLE_COLOR = 'red';
  PENCIL_SIZE = 30;
  NUDGE_PENCIL_UP_AT_START = 5;

  onLoad: () => void;

  constructor(onLoad: () => void) {
    super();
    this.onLoad = onLoad;
  }

  scribbles = new Array<[number, number]>();

  scribble(x: number, y: number): void {
    if (this.scribbles.length == 2) {
      this.scribbles = [];
    }

    this.scribbles.push([x, y]);
  }

  mouseDownResponse(x: number, y: number): boolean {
    this.scribble(x, y);

    return true;
  }

  paint(ctx: CanvasRenderingContext2D): void {
    super.paint(ctx);
    ctx.save();
    ctx.translate(this.viewportX, this.viewportY);

    if (this.scribbles.length == 2) {
      const ptA = this.scribbles[0];
      const ptB = this.scribbles[1];
      let x1 = ptA[0];
      let y1 = ptA[1];
      let x2 = ptB[0];
      let y2 = ptB[1];

      if (x1 > x2) {
        const tx = x1;
        const ty = y1;

        x1 = x2;
        y1 = y2;
        x2 = tx;
        y2 = ty;
      }

      const m = (y2 - y1) / (x2 - x1);

      x1 = x1 - 65;
      y1 = y1 - m * 65;
      x2 += 65;
      y2 += m * 65;
      line(ctx, x1, y1, x2, y2, 2, this.SCRIBBLE_COLOR);
      ctx.beginPath();
      ctx.strokeStyle = this.SCRIBBLE_COLOR;
      ctx.lineWidth = 2;
      arrow(ctx, x1, y1, x2, y2, 'small');
      arrow(ctx, x2, y2, x1, y1, 'small');
      ctx.stroke();
    }

    for (let a = 0; a < this.scribbles.length; a++) {
      const pt = this.scribbles[a];

      circle(ctx, pt[0], pt[1], 4, this.SCRIBBLE_COLOR, false);
    }

    ctx.translate(
      this.viewportW - this.PENCIL_SIZE,
      -this.NUDGE_PENCIL_UP_AT_START
    );

    const pencilImage = getImage('pencilenabled', this.onLoad);

    drawImage(ctx, pencilImage, 0, 0, this.PENCIL_SIZE, this.PENCIL_SIZE);

    ctx.restore();
  }
}
