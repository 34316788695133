import axios from 'axios';

import {
  getApiUrl,
  getGuid,
  getSs,
  getUsername,
} from '../../stores/globalState';
import { reboot } from '../../utils/gmmUtils';
import { isAxiosError, consoleAxiosError, toBody } from '../utils';

const sessionClosedMessage = 'Session closed -- did you log in somewhere else?';

interface SendRaisedHandAttempt {
  a: number;
  guid: string;
  id: string;
  raise: boolean;
  ss: number;
  user: string;
}

export const sendRaisedHand = async (
  value: boolean,
  currentProblemId: string
): Promise<void> => {
  try {
    // server has it backwards, sigh
    const realValue = !value;

    const attempt: SendRaisedHandAttempt = {
      a: new Date().getTime(),
      guid: getGuid() || '',
      id: currentProblemId,
      raise: realValue,
      ss: getSs() || 0,
      user: getUsername() || '',
    };

    const url = `${getApiUrl()}/Hand`;

    const { data } = await axios.post(url, toBody(attempt));

    if (data.guidFail) {
      reboot({ msg: data.guidFail });

      return;
    }

    if (data.sessionClosed) {
      reboot({ msg: sessionClosedMessage });

      return;
    }
  } catch (err) {
    if (isAxiosError(err)) {
      return consoleAxiosError(err);
    }

    console.error({ err });

    return;
  }
};
