import { parseFont } from './parseFont';

interface Obj {
  setFont(font: string): void;
}

export function scaleFont<T extends Obj>(
  obj: T,
  font: string,
  scale: number
): void {
  const { fontFamily, fontSize, fontWeight } = parseFont(font);

  // converts `'12px'` to `12`
  const numericFontSize = parseInt(fontSize, 10);
  const roundedFontSize = Math.ceil(numericFontSize * scale);

  obj.setFont(
    [fontWeight, `${roundedFontSize}px`, fontFamily]
      .filter(style => style)
      .join(' ')
  );
}
