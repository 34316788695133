import { FC } from 'react';
import Draggable from 'react-draggable';

import {
  DIALOG_BLUE_BODY,
  DIALOG_BLUE_BUTTON,
  ModalButton,
  ModalHeader,
  READ_ALOUD_HIGHLIGHT_BLUE,
  Readable,
  modalStyle,
} from '@gmm/problem';

import { postReplace } from '../../../studentApp/api/sendObject/postReplace';
import { bannerStore, problemState } from '../../../studentApp/stores';
import { showAnswer } from '../../../studentApp/utils/gmmUtils';

interface NeedHelpModalProps {
  close: () => void;
}

export const NeedHelpModal: FC<NeedHelpModalProps> = ({ close }) => {
  const [replacementsPerDay, replacementsUsedToday] = bannerStore(state => [
    state.replacementsPerDay,
    state.replacementsUsedToday,
  ]);

  const replacmentWord =
    replacementsPerDay - replacementsUsedToday === 1
      ? 'replacement'
      : 'replacements';

  const message = `Do you want to see the answer and replace this problem? 
        You have ${replacementsPerDay - replacementsUsedToday} 
        ${replacmentWord} remaining.`;

  const launchAnswer = () => {
    close();
    postReplace(problemState().selectedID, showAnswer);
  };

  return (
    <Draggable handle=".gmm-need-help-modal">
      <div style={{ ...modalStyle, backgroundColor: DIALOG_BLUE_BODY }}>
        <ModalHeader
          close={close}
          title={'Replace Problem?'}
          dragHandle="gmm-need-help-modal"
        />

        <div style={{ height: '20px' }}></div>

        <Readable highlightColor={READ_ALOUD_HIGHLIGHT_BLUE} text={message} />

        <div style={{ height: '20px' }}></div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
          }}
        >
          <ModalButton
            color={DIALOG_BLUE_BUTTON}
            text="REPLACE"
            close={launchAnswer}
          />
        </div>
      </div>
    </Draggable>
  );
};
