import { FC } from 'react';
import Draggable from 'react-draggable';

import {
  Readable,
  DIALOG_GREEN_BODY,
  DIALOG_GREEN_BUTTON,
  READ_ALOUD_HIGHLIGHT_GREEN,
  ModalHeader,
  ModalButton,
  modalStyle,
} from '@gmm/problem';

import { postExamRequest } from '../../../studentApp/api';

export const NEW_EXAM_AVAILABLE_MESSAGE =
  'Your teacher has allowed you to take an exam. You can begin the exam now, or start it later from the menu in the top right.';
export const NEW_EXAMS_AVAILABLE_MESSAGE =
  'You have new exams available. You can begin one of them from the menu in the top right.';

type NewExamMessage =
  | typeof NEW_EXAM_AVAILABLE_MESSAGE
  | typeof NEW_EXAMS_AVAILABLE_MESSAGE;

export interface NewExamDialogOptions {
  message: NewExamMessage;
  sitId?: number;
}

interface NewExamDialogProps {
  close: () => void;
  options: NewExamDialogOptions;
}

export const NewExamDialog: FC<NewExamDialogProps> = ({ close, options }) => {
  const title =
    options.message === NEW_EXAM_AVAILABLE_MESSAGE
      ? 'New Exam Available'
      : 'New Exams Available';

  const launchExam = () => {
    close();
    postExamRequest({ sitId: options.sitId! });
  };

  return (
    <Draggable handle=".gmm-new-exam-dialog">
      <div style={{ ...modalStyle, backgroundColor: DIALOG_GREEN_BODY }}>
        <ModalHeader
          title={title}
          close={close}
          dragHandle={'gmm-new-exam-dialog'}
        />

        <div style={{ height: '20px' }} />

        <Readable
          text={options.message}
          highlightColor={READ_ALOUD_HIGHLIGHT_GREEN}
        />

        <div style={{ height: '20px' }} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
          }}
        >
          {options.sitId && (
            <>
              <span
                onClick={close}
                style={{
                  margin: '10px',
                  cursor: 'pointer',
                }}
              >
                Dismiss
              </span>

              <div onClick={close} style={{ width: '20px' }}></div>

              <ModalButton
                color={DIALOG_GREEN_BUTTON}
                text="BEGIN EXAM"
                close={launchExam}
              />
            </>
          )}

          {!options.sitId && (
            <ModalButton color={DIALOG_GREEN_BUTTON} text="OK" close={close} />
          )}
        </div>
      </div>
    </Draggable>
  );
};
