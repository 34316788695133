import { getReadAloudButtonWidth } from '../state';
import {
  BasicObject,
  ReadAloudButton,
  ReadAloudButtonDependencies,
  TTS,
  TTSDTO,
} from '../uiObjects';

export const DEBUGGING_READ_ALOUD = false;

const speechSynthesis = window.speechSynthesis;
let voice: SpeechSynthesisVoice | undefined;

// sleight of hand to communicate internal testing tools'
// experimental voices to "problemJS" readAloud functions
declare global {
  interface Window {
    gmmVoicePicker: SpeechSynthesisVoice;
  }
}

const DEFAULT_SPEECH_RATE = 0.7;

// We've found that there some Voices handle speeds differently. For example,
// the default voice on Android sounds much too slow at .5
const speechRates: Map<string, number> = new Map([
  ['Alex', 0.5],
  ['Microsoft David - English (United States)', 0.6],
]);

// Get preferred speed for Voice
export function getVoiceRate(voice: SpeechSynthesisVoice): number {
  return speechRates.get(voice.name) || DEFAULT_SPEECH_RATE;
}

// Whether we have ever called the speech synthesis 'speak' method.
// Needed as part of working around iOS's requirement for user interaction
// coupled with its refusal to recognize a touchstart on a canvas as user interaction.
let spoke = false;

export function hasSpoken(): boolean {
  return spoke;
}

export function setSpoke(spokeValue: boolean): void {
  spoke = spokeValue;
}

export function getVoice(): SpeechSynthesisVoice | undefined {
  // Use internal tool chosen voice, if it exists (see 'qa' or 'WebProblemTester' tools)
  return window.gmmVoicePicker || voice;
}

export function isSpeechAvailable(): boolean {
  return !!voice;
}

export type Language = 'en_US';

function getVoicesByLanguage(
  language: Language,
  voices?: SpeechSynthesisVoice[]
): SpeechSynthesisVoice[] {
  if (!voices && !isSpeechAvailable()) {
    throw new Error('Speech is not available');
  }

  // Android uses a dash, rest of world uses an underscore
  const otherVersion: string = language.replace('_', '-');

  if (!voices) {
    voices = speechSynthesis.getVoices();
  }

  return voices.filter((v): boolean => {
    return !!(
      v.lang &&
      (v.lang.indexOf(language) > -1 || v.lang.indexOf(otherVersion) > -1)
    );
  });
}

/* Load voices by a variety of strategies:
 *
 * - detect that SpeechSynthesis is available, if not -> fail
 * - load voices directly, which may occur async, so...
 * - if not loaded but `onvoiceschanged` is available: use `onvoiceschanged`
 * - if `onvoiceschanged` is not available: fallback to timeout
 * - if `onvoiceschanged` is fired but no voices available: fallback to timeout
 * - timeout reloads voices in a given `interval` until a `maxTimeout` is reached
 * - if voices are loaded until then -> complete
 * - if no voices found -> fail
 */
let singleCall = false;

export function initSpeech(
  paintCanvas: () => void,
  maxTimeout = 5000,
  interval = 250
): void {
  // only call once
  if (singleCall) return;
  singleCall = true;

  // speech unsupported by browser
  if (!window.speechSynthesis) return;

  // already loaded (shouldn't be possible)
  if (voice) return;

  let timer: any;
  let completeCalled = false;
  let voicesChangedListener: any;

  const complete = (): void => {
    // avoid race-conditions between listeners and timeout
    if (completeCalled) {
      return;
    }

    completeCalled = true;

    // cleanup events and timer
    clearInterval(timer);
    speechSynthesis.onvoiceschanged = null;

    if (voicesChangedListener) {
      speechSynthesis.removeEventListener(
        'voiceschanged',
        voicesChangedListener
      );
    }

    // repaint canvas so that ReadAloudButtons appear enabled
    paintCanvas();
  };

  const fail = (errorMessage: string): void => {
    console.error(errorMessage);
    complete();
  };

  const voicesLoaded = (): boolean => {
    const voices = speechSynthesis.getVoices() || [];

    if (voices.length > 0) {
      const en_USVoices = getVoicesByLanguage('en_US', voices);

      for (let i = 0; i < en_USVoices.length; i++) {
        const name = en_USVoices[i].name;

        // for testers to see what other voices are available in logs
        if (DEBUGGING_READ_ALOUD) console.log('en_US voice ' + i + ': ' + name);

        if (name === 'Alex' || name === 'Samantha') {
          voice = en_USVoices[i];
        }
      }

      // No voice yet? Let's use the first element in the English array
      if (!voice) voice = en_USVoices[0];

      // Still no voice? Use whatever we can get
      if (!voice) {
        voice = voices[0];
      }

      if (DEBUGGING_READ_ALOUD) {
        console.log('voice name: ' + voice?.name);
        console.log('voice lang: ' + voice?.lang);
      }

      return true;
    }

    return false;
  };

  // best case: detect if voices can be loaded directly
  if (voicesLoaded()) {
    complete();

    return;
  }

  // fallback method: keep trying via timer interval
  const loadViaTimeout = (): void => {
    let timeout = 0;

    timer = setInterval((): void => {
      if (voicesLoaded()) {
        complete();

        return;
      }

      if (timeout > maxTimeout) {
        fail('speech unavailable: browser has no voices (timeout)');
      }

      timeout += interval;
    }, interval);
  };

  // detect if voices can be loaded after onveoiceschanged,
  // but only if the browser supports this event
  if (speechSynthesis.hasOwnProperty('onvoiceschanged')) {
    speechSynthesis.onvoiceschanged = (): void => {
      if (voicesLoaded()) {
        complete();

        return;
      }

      // some browsers (like chrome on android) still don't have all
      // voices loaded at this point, which is why we need to enter
      // the timeout-based method here.
      loadViaTimeout();
    };

    // there is an edge-case where browser provides onvoiceschanged,
    // but never loads the voices, so init would never complete
    // in such case we need to fail after maxTimeout
    setTimeout((): void => {
      if (voicesLoaded()) {
        complete();
      }

      fail('speech unavailable: browser has no voices (timeout)');
    }, maxTimeout);
  } else {
    // this is a very problematic case, since we don't really know whether
    // this event will fire at all, so we need to set up both a listener AND
    // run the timeout and make sure on of them "wins"
    // affected browsers may be: MacOS Safari
    if (speechSynthesis.hasOwnProperty('addEventListener')) {
      const voicesChangedListener = (): void => {
        if (voicesLoaded()) {
          complete();
        }
      };

      speechSynthesis.addEventListener('voiceschanged', voicesChangedListener);
    }

    // for all browser not supporting onveoiceschanged we start a timer
    // until we reach a certain timeout and try to get the voices
    loadViaTimeout();
  }
}

// if client modifies char count (e.g. stripping space from a line in a SAVP),
// then we need to update the displayIndexes array to account for the modification
export function shiftIndexes(
  arr: number[],
  threshold: number,
  shift: number
): void {
  for (let i = 0; i < arr.length; i++) {
    // update all elements of displayIndexes that occur after threshold
    if (arr[i] > threshold) arr[i] += shift;
  }
}

// Add a ReadAloudButton to the target object.
// If 'target' and 'displayedElement' are different,
// appends displayedElement's ttsDTO to target's
// ReadAloudButton TTS (useful when using one button to
// trigger reading of multiple panels).
export function injectReadAloudButton(
  target: BasicObject,
  ttsData: TTSDTO,
  readAloudButtonDependencies: ReadAloudButtonDependencies,
  displayedElement: BasicObject
): ReadAloudButton | undefined {
  if (!ttsData) return;
  if (!ttsData.spoken || ttsData.spoken.length === 0) return;

  // Does target already have a ReadAloudButton?
  let readAloudButton = getReadAloudDescendant(target);

  if (!readAloudButton) {
    const tts = new TTS(ttsData, displayedElement, readAloudButtonDependencies);

    readAloudButton = new ReadAloudButton(tts, readAloudButtonDependencies);
    target.add(readAloudButton, 0, true);
  } else {
    readAloudButton.tts.appendTTSDTO(ttsData, displayedElement);
  }

  return readAloudButton;
}

// returns first ReadAloudButton found in children
function getReadAloudDescendant(
  parent: BasicObject
): ReadAloudButton | undefined {
  for (let a = 0; a < parent.children.length; a++) {
    const child = parent.children[a];

    if (child instanceof ReadAloudButton) return child;

    const ret = getReadAloudDescendant(child);

    if (ret !== undefined) return ret;
  }

  return undefined;
}

// Usually, we build TTS data on the server,
// but sometimes it is easier to build on
// the client, such as for prompts in some getters.
export function buildTTSDTO(spoken: string): TTSDTO {
  const spokenIndexes: number[] = [];
  const displayIndexes: number[] = [];

  // replace hyphens (technically, minus signs)
  // in words with spaces so readers don't say "minus"
  // e.g. "drop-down" on Chromebooks was said as "drop minus down"
  spoken = spoken.replace(/\u2212/g, ' ');

  // loop through spoken string and find indexes of each character
  for (let i = 0; i < spoken.length; i++) {
    if (i === 0 || spoken[i - 1] === ' ') {
      spokenIndexes.push(i);
      displayIndexes.push(i);
    }
  }

  return {
    spoken,
    spokenIndexes,
    displayIndexes,
  };
}

export function getReadAloudWrapper(): ReadAloudWrapper {
  return new ReadAloudWrapper();
}

// A BasicObject that expects an audio icon as the first child
// and exactly one more BasicOBject as the second child. This wrapper
// protects the audio icon from getting shrinked, instead passing
// along a diminished maxWidth to the second child.
// Powerful IF used as expected. User is responsible for setting up the children.
class ReadAloudWrapper extends BasicObject {
  gmmName = 'ReadAloudWrapper';

  setMaxWidth(maxWidth: number): void {
    this.children[1].setMaxWidth(maxWidth - getReadAloudButtonWidth());
    this.sizeMeToLowestAndRightmostChildren();
    this.centerVertically();
  }
}
