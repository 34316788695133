import { FC, useState } from 'react';

import { postUnlockRequest } from '../../../studentApp/api';
import { workStore } from '../../../studentApp/stores/workStore';
import { Button } from '../util/constants';

export const RequestUnlockButton: FC = () => {
  const currentWork = workStore(state => state.currentWork);
  const [hasClicked, setHasClicked] = useState(false);

  const onClick = () => {
    if (hasClicked) return;
    setHasClicked(true);

    postUnlockRequest(currentWork.sitId!);
  };

  return (
    <Button
      text="REQUEST UNLOCK"
      onClick={onClick}
      disabled={hasClicked}
      rightSide={true}
      width={154}
    />
  );
};
