import { styled } from '@material-ui/core';
import { FC } from 'react';

import { WelcomeMessage } from '../components/welcomeMessage';
import { Button } from '../../ui';

const Container = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1.75),
}));

const Fields = styled('div')(({ theme }) => ({
  width: theme.spacing(33.5),
  '& > div': {
    marginBottom: theme.spacing(0.75),
  },
}));

interface Props {
  onRegister: () => void;
  onLogin: () => void;
}

export const GetStarted: FC<Props> = ({ onRegister, onLogin }) => {
  return (
    <Container id="getStarted">
      <WelcomeMessage />
      <Fields>
        <Button
          color="primary"
          fullWidth
          variant="contained"
          onClick={onRegister}
        >
          Get Started
        </Button>
        <Button onClick={onLogin} fullWidth color="primary" variant="text">
          I already have an account
        </Button>
      </Fields>
    </Container>
  );
};
