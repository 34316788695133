import { gmmAlert } from '../../utils';
import { alerts } from '../alerts';

import {
  NoClassResponse,
  InactiveClassResponse,
  LockedOutResponse,
  SevereErrorResponse,
  IsTeacherResponse,
  OldVersionResponse,
  BadCredentialsResponse,
} from './types';

export const isObject = (
  value: unknown
): value is Record<string, typeof value> =>
  !!value && typeof value === 'object';

export const isLockedOut = (value: unknown): value is LockedOutResponse =>
  isObject(value) && 'isLockedOut' in value;

export const handleLockoutResponse = (data: LockedOutResponse): void => {
  // Default to 1 min
  let lockoutRemainingMinutes = 1;

  // If they supplied us with a value and it's valid
  if (data.lockoutRemainingMinutes) {
    // Use that value for the remaining lockout duration
    lockoutRemainingMinutes = data.lockoutRemainingMinutes;
  }

  const lockoutAlert = alerts.lockedOut(lockoutRemainingMinutes);

  gmmAlert(lockoutAlert);
};

type ForbiddenResponseTypes = 'teacher' | 'clasOff' | 'noClas' | 'oldVersion';

export const isTeacher = (value: unknown): value is IsTeacherResponse =>
  isObject(value) && 'teacher' in value;

export const inactiveClass = (value: unknown): value is InactiveClassResponse =>
  isObject(value) && 'clasOff' in value;

export const noClass = (value: unknown): value is NoClassResponse =>
  isObject(value) && 'noClas' in value;

export const usingOldVersion = (value: unknown): value is OldVersionResponse =>
  isObject(value) && 'oldVersion' in value;

export const handleForbiddenResponseType = (
  type: ForbiddenResponseTypes
): void => gmmAlert(alerts[type]);

export const badCredentials = (
  value: unknown
): value is BadCredentialsResponse =>
  isObject(value) && 'badCredentials' in value;

export const severeError = (value: unknown): value is SevereErrorResponse =>
  isObject(value) && 'severeError' in value;

type ResponseData = Record<string, unknown>;

export const loginErrorResponseHandler = (data: ResponseData): boolean => {
  if (isLockedOut(data)) {
    handleLockoutResponse(data);

    return true;
  }

  if (isTeacher(data)) {
    handleForbiddenResponseType('teacher');

    return true;
  }

  if (inactiveClass(data)) {
    handleForbiddenResponseType('clasOff');

    return true;
  }

  if (noClass(data)) {
    handleForbiddenResponseType('noClas');

    return true;
  }

  if (usingOldVersion(data)) {
    handleForbiddenResponseType('oldVersion');

    return true;
  }

  if (badCredentials(data)) {
    alert(alerts.badCredentials.msg);

    return true;
  }

  if (severeError(data)) {
    gmmAlert(alerts.severeError(data.severeError));

    return true;
  }

  return false;
};
