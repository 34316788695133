import { arrow } from './arrow';
import { circle } from './circle';

export function numLineDrawing(
  ctx: CanvasRenderingContext2D,
  t: string,
  col = 'magenta'
): void {
  const radius = 6;
  const xOffset = radius * 2;
  const yOffset = 8;
  const lineLength = 30;

  ctx.save();
  col = col || 'magenta';
  const tran = col === 'magenta' ? 1 : 0.85;

  ctx.globalAlpha = tran;

  if (t === 'X-->' || t === 'O-->') {
    const o = t === 'O-->';

    circle(ctx, xOffset, yOffset, o ? 6 : 7.5, col, o, true, 3);
    ctx.beginPath();
    ctx.strokeStyle = col;
    ctx.lineWidth = 3;
    ctx.moveTo(xOffset + radius, yOffset);
    ctx.lineTo(43, yOffset);
    arrow(ctx, xOffset, yOffset, xOffset + lineLength, yOffset, 'smaller');
    ctx.stroke();
  } else {
    const o1 = t.substring(0, 1) === 'O';
    const o2 = t.substring(3) === 'O';

    circle(ctx, xOffset, yOffset, o1 ? 6 : 7.5, col, o1, true, 3);
    circle(ctx, xOffset + lineLength, yOffset, o2 ? 6 : 7.5, col, o2, true, 3);
    ctx.beginPath();
    ctx.strokeStyle = col;
    ctx.lineWidth = 3;
    ctx.moveTo(xOffset + radius, yOffset);
    ctx.lineTo(xOffset + lineLength - 6, yOffset);
    ctx.stroke();
  }

  ctx.restore();
}
