const finalAsciiCode = 127;

/**
 * Encodes non-ASCII characters
 */
export function encodeText(text: string): string {
  if (!text) return '';

  for (let i = 0; i < text.length; i++) {
    const code = text.charCodeAt(i);

    if (code <= finalAsciiCode) continue;

    const start = text.substring(0, i);
    const end = text.substring(i + 1);

    text = `${start}&encu${code.toString(16)};${end}`;
  }

  return text;
}
