import { useMemo } from 'react';

import { NO_MODAL, studentAppModalStore } from '../stores/studentAppModalStore';

interface TopScoreRow {
  score: number;
  name: string;
}
interface UseStudentAppModals {
  showExamsMenu: boolean;
  isShowingModal: boolean;
  topScoreRows: TopScoreRow[];
}

export const useProcessedStudentAppModalStore = (): UseStudentAppModals => {
  const exams = studentAppModalStore(state => state.availableExams);

  const [
    currentModal,
    newExamDialog,
    finishedWork,
    stackableDialogs,
    stackableMessagesFromTeacher,
    topScoresAndNames,
  ] = studentAppModalStore(state => [
    state.currentModal,
    state.newExamDialog,
    state.finishedWork,
    state.stackableDialogs,
    state.stackableMessagesFromTeacher,
    state.topScores,
  ]);

  const showExamsMenu = exams.length > 0;

  const isShowingModal = useMemo(
    () =>
      currentModal !== NO_MODAL ||
      !!newExamDialog ||
      !!finishedWork ||
      stackableDialogs.length > 0 ||
      stackableMessagesFromTeacher.length > 0,
    [
      currentModal,
      stackableDialogs,
      stackableMessagesFromTeacher,
      newExamDialog,
      finishedWork,
    ]
  );

  const topScoreRows = [];

  // multiple names with same top score are appended together into one string
  // ... insert a space after each comma for ease of reading
  for (let i = 0; i < topScoresAndNames.length; i += 2) {
    topScoreRows.push({
      score: parseInt(topScoresAndNames[i]),
      name: topScoresAndNames[i + 1].replace(/,/g, ', '),
    });
  }

  return {
    showExamsMenu,
    isShowingModal,
    topScoreRows,
  };
};
