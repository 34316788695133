import { FC, useEffect, useRef } from 'react';

import { applyPixelRatioToCanvas } from '@gmm/problem';

import { bannerStore } from '../../../studentApp/stores';
import { GRAY, POINTS_TODAY_FONT } from '../../constants';

const WIDTH = 219;
const HEIGHT = 20;
const OUTER_THICKNESS = 1.5;
const INNER_HEIGHT = 14;

export const GameCredits: FC = () => {
  const [
    gameCredits,
    pointsTowardGameCredit,
    pointsForGameCredit,
  ] = bannerStore(state => [
    state.gameCredits,
    state.pointsTowardGameCredit,
    state.pointsForGameCredit,
  ]);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) return;

    paint(canvas, gameCredits, pointsTowardGameCredit, pointsForGameCredit);
  }, [gameCredits, pointsTowardGameCredit, pointsForGameCredit]);

  return <canvas ref={canvasRef} id="gameCredits" />;
};

function paint(
  canvas: HTMLCanvasElement,
  gameCredits: number,
  pointsTowardsNextCredit: number,
  pointsToEarnCredit: number
) {
  if (!canvas) return;

  applyPixelRatioToCanvas(canvas, WIDTH, HEIGHT);

  const ctx = canvas.getContext('2d')!;

  const creditsWord = gameCredits === 1 ? 'credit' : 'credits';
  const text = `${gameCredits} game ${creditsWord}`;

  ctx.clearRect(0, 0, WIDTH, HEIGHT);

  ctx.fillStyle = GRAY;
  ctx.strokeStyle = GRAY;

  const outerInset = OUTER_THICKNESS / 2;
  const outerWidth = WIDTH - OUTER_THICKNESS;
  const outerHeight = HEIGHT - OUTER_THICKNESS;

  roundedRect(ctx, outerInset, outerInset, outerWidth, outerHeight, 10);
  ctx.lineWidth = OUTER_THICKNESS;
  ctx.stroke();

  const innerInsetY = (HEIGHT - INNER_HEIGHT) / 2;
  const innerInsetX = innerInsetY + 1;
  const innerWidth = WIDTH - 2 * innerInsetX;

  const progressPercent = pointsTowardsNextCredit / pointsToEarnCredit;

  // clip the inner rounded rectangle to the progress (color inversion effect)
  ctx.save();
  ctx.beginPath();
  ctx.rect(
    innerInsetX,
    innerInsetY,
    innerWidth * progressPercent,
    INNER_HEIGHT
  );
  ctx.clip();

  roundedRect(ctx, innerInsetX, innerInsetY, innerWidth, INNER_HEIGHT, 7);
  ctx.fill();

  ctx.fillStyle = 'white';
  ctx.font = POINTS_TODAY_FONT;
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center';
  ctx.fillText(text, WIDTH / 2, HEIGHT / 2);

  ctx.restore();

  // remainder of inner zone: no progress
  ctx.save();
  ctx.beginPath();
  ctx.rect(
    innerInsetX + innerWidth * progressPercent,
    innerInsetY,
    innerWidth,
    INNER_HEIGHT
  );
  ctx.clip();

  ctx.fillStyle = GRAY;
  ctx.font = POINTS_TODAY_FONT;
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center';
  ctx.fillText(text, WIDTH / 2, HEIGHT / 2);

  ctx.restore();
}

function roundedRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number
) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.arcTo(x + width, y, x + width, y + height, radius);
  ctx.arcTo(x + width, y + height, x, y + height, radius);
  ctx.arcTo(x, y + height, x, y, radius);
  ctx.arcTo(x, y, x + width, y, radius);
  ctx.closePath();
}
