import { CSSProperties, FC } from 'react';

import { MODAL_Z_INDEX } from '@gmm/problem';

const loadingOverlayStyle: CSSProperties = {
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

const loadingSpinnerStyle: CSSProperties = {
  position: 'fixed',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50px',
  zIndex: MODAL_Z_INDEX,
};

export const Loading: FC = () => {
  return (
    <div style={loadingOverlayStyle}>
      <img src="GMM-images/loading.gif" style={loadingSpinnerStyle}></img>
    </div>
  );
};
