import { TTSInterface } from '..';

import {
  DEBUGGING_READ_ALOUD,
  getVoiceRate,
  getVoice,
  setSpoke,
} from './readAloudUtil';

let mostRecentTTSInterface: TTSInterface | undefined;
let currentReadAloudPromise: Promise<void> | null = null;

/**
 * Some system events need the able to forcibly cancel read aloud.
 * For examples: when a dialog is closed or when a problem is submitted.
 */
export function cancelReadAloud(): void {
  window.speechSynthesis?.cancel();
  mostRecentTTSInterface?.readAloudEnded?.();
  mostRecentTTSInterface = undefined;
}

/**
 * Expose speech functionality to wider scope so student app can
 * call it for reading modal text.
 * @param tts TTSInterface
 */
export function readAloud(tts: TTSInterface): void {
  // Avoid fast double-clicks causing read aloud icon to show as loading wheel
  // but not speak because cancel was fired faster than speech synthesis could start.
  if (currentReadAloudPromise !== null) return;

  // First, cancel any existing read-aloud operation
  cancelReadAloud();

  // Second, start new read-aloud operation.
  // iOS 'cancel' also fires 'onend', so we need to wait a bit for cancel to finish.
  // Otherwise, SpeechSynthesis gets set up with a new utterance,
  // and a moment later it fires 'onend' during the 'old' cancel. This
  // accesses the current Utterance -- the one we just assigned --
  // So... our new read aloud process is told to end right when it starts.
  // This tiny delay coupled with some SpeechSynthesis potential for delay can
  // confuse users, so show 'loading' graphic.
  currentReadAloudPromise = new Promise<void>(resolve => {
    setTimeout(() => {
      // Perform the text-to-speech operation
      readAloudHelper(tts);
      resolve();
    }, 300);
  });

  currentReadAloudPromise.then(() => {
    currentReadAloudPromise = null; // Reset the Promise
  });
}

function readAloudHelper(tts: TTSInterface): void {
  const voice = getVoice();

  // should not be possible, since ReadAloudButton is disabled until a voice is set up
  if (!voice) return;

  mostRecentTTSInterface = tts;

  // Save global reference to prevent garbage collection during long speech on some platforms
  window.speechUtterance = new SpeechSynthesisUtterance();

  const speechUtterance = window.speechUtterance;

  speechUtterance.voice = voice;
  speechUtterance.rate = getVoiceRate(voice); // 0.1 to 10
  speechUtterance.text = tts.getSpokenText();
  speechUtterance.pitch = 1; // 0 to 2
  speechUtterance.volume = 1; // 0 to 1

  // SpeechSynthesizer is supposed to fire 'onboundary' events
  // every time it begins to read a part of the spoken string.
  // See comments in TTS class for examples (in TTS.ts).
  // Note that it does not always work as expected, varying
  // sometimes per voice and sometimes per platform.
  speechUtterance.onboundary = event => {
    tts.setHighlightReadAloudFromSpokenIndex?.(event.charIndex);
  };

  speechUtterance.onend = () => {
    tts.readAloudEnded?.();
  };

  // If the mysterious Chrome audio fail bug becomes an issue, we may want to bring this back:
  // speechUtterance.onerror = event => {
  // let header = 'Read Aloud Error ';

  // header += event.error === 'interrupted' ? 'interrupted' : 'other';
  // const error = header + '\n' + eventToString(event, 2);

  // console.error(error);
  // tts.errorToServer?.(error);
  // };

  if (DEBUGGING_READ_ALOUD) {
    console.log('speechUtterance: ' + speechUtterance);
    console.log('speechUtterance.text: ' + speechUtterance.text);
  }

  tts.readAloudStarted?.();

  window.speechSynthesis.speak(speechUtterance);

  setSpoke(true);
}

// Support for clean stringify of speech error event. JSON.stringify did not suffice.
// Loop through every property of 'obj' and stringify it recursively,
// but do not include functions. Format result nicely -- make it look
// like a json object.
const eventToString = (
  obj: any,
  indent: number,
  utterance?: SpeechSynthesisUtterance | undefined
): string => {
  let result = '{\n';

  for (const key in obj) {
    if (obj[key] && typeof obj[key] !== 'function') {
      if (key === 'utterance') utterance = obj[key];

      if (typeof obj[key] === 'object') {
        // SpeechUtteranceErrorEvents on macOS (at least) have four references to identical SpeechUtterances,
        // no need to send them to the server unless they are different
        const skip =
          obj[key] instanceof SpeechSynthesisUtterance &&
          key !== 'utterance' &&
          ignoreUtterance(obj[key], utterance);

        if (!skip)
          result +=
            ' '.repeat(indent) +
            key +
            ': ' +
            eventToString(obj[key], indent + 2, utterance) +
            '\n';
      } else {
        result +=
          ' '.repeat(indent) +
          key +
          ': ' +
          JSON.stringify(obj[key], null, 2) +
          ',\n';
      }
    }
  }

  result += ' '.repeat(indent - 2) + '}';

  return result;
};

// Helper for eventToString.
// Returns true if every property of a is equal to the corresponding property of b.
const ignoreUtterance = (a: any, b: any): boolean => {
  for (const key in a) {
    if (a[key] !== b[key]) {
      return false;
    }
  }

  return true;
};
