// Keep this here for reusing (flyweight style)
const span = document.createElement('span');

interface ParsedFont {
  fontFamily: string;
  fontSize: string;
  fontWeight: string;
}

export function parseFont(font: string): ParsedFont {
  span.setAttribute('style', `font:${font}`);

  return {
    fontFamily: span.style.fontFamily,
    fontSize: span.style.fontSize,
    fontWeight: span.style.fontWeight,
  };
}
