import { studentAppModalState } from '../../stores/studentAppModalStore';

import { sendObject } from './handler';

export type RequestTest = { type: 'requestTest'; sitId: string };

type Options = {
  sitId: number;
};

export const postExamRequest = async ({ sitId }: Options): Promise<void> => {
  if (!sitId) return;
  studentAppModalState().setExamLoading(true);

  return sendObject({ type: 'requestTest', sitId: sitId.toString() });
};
