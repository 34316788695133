import { CSSProperties, FC, MouseEvent } from 'react';

import { DIALOG_BLUE_BODY } from '@gmm/problem';

import {
  GETTING_STARTED_VIDEO_URL,
  WELCOME_STRIP_HEIGHT,
} from '../../constants';

const welcomeStripStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: WELCOME_STRIP_HEIGHT,
  minHeight: WELCOME_STRIP_HEIGHT,
  width: '100%',
  backgroundColor: DIALOG_BLUE_BODY,
  color: 'white',
  padding: '0 10px',
};

const closeButtonStyle: CSSProperties = {
  cursor: 'pointer',
  background: 'transparent',
  border: 'none',
  color: 'white',
  fontSize: '140%',
  fontWeight: 'bold',
};

const linkContainerStyle: CSSProperties = {
  flex: 1,
  textAlign: 'center',
};

const linkStyle: CSSProperties = {
  color: 'white',
  cursor: 'pointer',
};

interface WelcomeStripProps {
  closer: () => void;
}

export const WelcomeStrip: FC<WelcomeStripProps> = ({ closer }) => {
  const handleLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    closer();
    window.open(GETTING_STARTED_VIDEO_URL, '_blank', 'noreferrer');
  };

  return (
    <div style={welcomeStripStyle}>
      <div style={linkContainerStyle}>
        <a
          href={GETTING_STARTED_VIDEO_URL}
          onClick={handleLinkClick}
          style={linkStyle}
          rel="noreferrer"
        >
          Get started quickly with Get More Math - watch this short video
        </a>
      </div>
      <button style={closeButtonStyle} onClick={closer}>
        &times;
      </button>
    </div>
  );
};
