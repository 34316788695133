import { FC } from 'react';

import { useProcessedProblems } from '../../../studentApp/hooks';
import {
  ExamProblem,
  getNumUnfinished,
  problemState,
} from '../../../studentApp/stores';
import { getGmm } from '../../../studentApp/stores/globalState';
import {
  EXAM_WARNING,
  studentAppModalState,
} from '../../../studentApp/stores/studentAppModalStore';
import { Button } from '../util/constants';

export const HandInButton: FC = () => {
  const currentProblem = useProcessedProblems().currentProblem as ExamProblem;

  const handleClick = () => {
    // If there is an unsubmitted attempt, submit it before launching the
    // modal warning 'are you sure' about turning in the exam.
    if (currentProblem !== undefined && currentProblem.uncertain) {
      problemState().updateProblem(currentProblem.id, { uncertain: false });

      // auto-submit current problem, then show the warning
      getGmm()?.submitAllAgs(() => {
        updateExamHandInWarning();
        studentAppModalState().setCurrentModal(EXAM_WARNING);
      });

      return;
    }

    updateExamHandInWarning();
    studentAppModalState().setCurrentModal(EXAM_WARNING);
  };

  return <Button onClick={handleClick} text="HAND IN" />;
};

const updateExamHandInWarning = () => {
  const numUnfinished = getNumUnfinished();
  let msg = '';

  if (numUnfinished) {
    const word = numUnfinished != 1 ? 'problems' : 'problem';

    msg = `WARNING: you still need to finish ${numUnfinished} ${word}!  `;
  }

  msg += 'Are you sure you want to hand in your exam?';

  studentAppModalState().showExamHandInWarning(msg);
};
