import { ShowAnswer } from '../../utils/gmmUtils';
import { sendObject } from './handler';

export type Replace = {
  type: 'replace';
  restoreId: string;
};

export const postReplace = async (
  restoreId: string,
  onSuccess: (data: ShowAnswer) => void
): Promise<void> => {
  return sendObject<ShowAnswer>(
    {
      restoreId,
      type: 'replace',
    },
    { onSuccess }
  );
};
