import { numbersEqual } from '..';
import { Point } from '../../index';

import { pointsDistance } from './pointsDistance';

export function pointsEqual(point1: Point, point2: Point): boolean {
  if (!point1) return false;
  if (!point2) return false;

  // return point1.x == point2.x && point1.y == point2.y;
  return numbersEqual(pointsDistance(point1, point2), 0);
}
