import { Value } from './Value';

const SIZE = 20;
const THICKNESS = 2;

export function getBox(): Box {
  return new Box();
}

export class Box extends Value {
  constructor() {
    super();
    this.setAllDim(SIZE);
  }

  paintMe(ctx: CanvasRenderingContext2D): void {
    ctx.save();
    ctx.strokeStyle = 'black';
    ctx.lineWidth = THICKNESS;
    // Shimmy to get full thickness painted in the clip
    ctx.rect(THICKNESS / 2, THICKNESS / 2, SIZE - THICKNESS, SIZE - THICKNESS);
    ctx.stroke();
    ctx.restore();
  }

  getPaintedCharCount(): number {
    return 1;
  }
}
