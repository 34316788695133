import { FC } from 'react';

import { useProcessedWorkStore } from '../../studentApp/hooks';
import { bannerStore } from '../../studentApp/stores';

import { Banner } from './banner/banner';
import { ExamButtons } from './banner/examButtons';
import { PointsAndGameCredits } from './banner/pointsAndGameCredits';
import { WelcomeStrip } from './banner/welcomeStrip';
import { SquaresAndProblem } from './squares/squaresAndProblem';

export const LandscapeLayout: FC = () => {
  const { isTesting } = useProcessedWorkStore();
  const [hideWelcomeStrip, setHideWelcomeStrip] = bannerStore(state => [
    state.hideWelcomeStrip,
    state.setHideWelcomeStrip,
  ]);

  const welcomeCloser = () => setHideWelcomeStrip(true);

  return (
    <>
      {!hideWelcomeStrip && <WelcomeStrip closer={welcomeCloser} />}
      {!isTesting && <PointsAndGameCredits />}
      <Banner />
      {isTesting && <ExamButtons />}
      <SquaresAndProblem />
    </>
  );
};
