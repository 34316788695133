import { FC } from 'react';
import Draggable from 'react-draggable';

import {
  DIALOG_BLUE_BODY,
  DIALOG_BLUE_BUTTON,
  ModalButton,
  ModalHeader,
  READ_ALOUD_HIGHLIGHT_BLUE,
  Readable,
  modalStyle,
} from '@gmm/problem';

import { studentAppModalStore } from '../../../studentApp/stores/studentAppModalStore';
import { submitTest } from '../../../studentApp/utils';

interface ExamWarningProps {
  close: () => void;
}

export const ExamHandInWarning: FC<ExamWarningProps> = ({ close }) => {
  const text = studentAppModalStore(state => state.examHandInText);

  const handIn = () => {
    submitTest();
    close();
  };

  return (
    <Draggable handle=".gmm-exam-warning">
      <div style={{ ...modalStyle, backgroundColor: DIALOG_BLUE_BODY }}>
        <ModalHeader
          title="Exam Warning"
          close={close}
          dragHandle="gmm-exam-warning"
        />

        <div style={{ height: '20px' }} />

        <div>
          <Readable text={text} highlightColor={READ_ALOUD_HIGHLIGHT_BLUE} />

          <div style={{ height: '20px' }}></div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ModalButton color={DIALOG_BLUE_BUTTON} text="Yes" close={handIn} />
            <div style={{ width: '20px' }}></div>
            <ModalButton color={DIALOG_BLUE_BUTTON} text="No" close={close} />
          </div>
        </div>
      </div>
    </Draggable>
  );
};
