import { Point } from '../../index';

import { checkLineIntersection } from './checkLineIntersection';
import { clockwisePolygon } from './clockwisePolygon';
import { isPointInsidePolygon } from './isPointInsidePolygon';
import { isPointLeftOfLine } from './isPointLeftOfLine';
import { justAfterPointOfACrossPoint } from './justAfterPointOfACrossPoint';
import { justBeforePointOfACrossPoint } from './justBeforePointOfACrossPoint';
import { pointsDistance } from './pointsDistance';
import { pointsEqual } from './pointsEqual';
import {
  Line,
  PointExpanded,
  Polygon,
  PolygonExpandedDictionary,
} from './types';

//* **************** Polygon.js embedded *****************

export function getIntersectingPolygon(
  polygon1: Polygon,
  polygon2: Polygon,
  errorToServer: (msg: string) => void
): Array<Point | PointExpanded> {
  // Sanity check
  if (polygon1.length == 0 || polygon2.length == 0) return [];

  polygon1 = clockwisePolygon(polygon1);
  polygon2 = clockwisePolygon(polygon2);

  // Check for duplicate polygons and skip intersection logic
  if (polygon1.length == polygon2.length) {
    const different = polygon1.some(
      ({ x, y }, index) => polygon2[index].x !== x || polygon2[index].y !== y
    );

    if (!different) return polygon1;
  }

  const polygon1ExpandedDict: PolygonExpandedDictionary = {};
  const polygon2ExpandedDict: PolygonExpandedDictionary = {};

  for (let i = 0; i < polygon1.length; i++) {
    const polygon1Line: Line = [
      polygon1[i],
      polygon1[(i + 1) % polygon1.length],
    ];

    polygon1ExpandedDict[i] = [{ ...polygon1[i] } as PointExpanded];

    for (let j = 0; j < polygon2.length; j++) {
      if (i == 0)
        polygon2ExpandedDict[j] = [{ ...polygon2[j] } as PointExpanded];

      const polygon2Line: Line = [
        polygon2[j],
        polygon2[(j + 1) % polygon2.length],
      ];
      const intersectionResult = checkLineIntersection(
        polygon1Line,
        polygon2Line
      );

      if (intersectionResult.onLine1 && intersectionResult.onLine2) {
        let lastIndex = polygon1ExpandedDict[i].length - 1;

        if (pointsEqual(intersectionResult, polygon1[i])) {
          polygon1ExpandedDict[i][0].isCrossPoint = true;
          polygon1ExpandedDict[i][0].isOriginalPoint = true;
          polygon1ExpandedDict[i][0].crossingLine = polygon2Line;
        } else if (
          pointsEqual(intersectionResult, polygon1[(i + 1) % polygon1.length])
          // eslint-disable-next-line no-empty
        ) {
        } else {
          const newPolygon1Point: PointExpanded = {
            ...intersectionResult,
            crossingLine: polygon2Line,
            distanceFromPreviousPoint: pointsDistance(
              polygon1[i],
              intersectionResult
            ),
            isCrossPoint: true,
          };

          while (
            polygon1ExpandedDict[i][lastIndex].distanceFromPreviousPoint &&
            polygon1ExpandedDict[i][lastIndex].distanceFromPreviousPoint >
              newPolygon1Point.distanceFromPreviousPoint
          ) {
            lastIndex--; // maybe current polygon1Line will be intersected in many places,
            // when intersection points are added to polygon1Expanded, they need to be properly sorted
          }

          if (
            !pointsEqual(
              polygon1ExpandedDict[i][lastIndex],
              newPolygon1Point
            ) &&
            !pointsEqual(
              polygon1ExpandedDict[i][lastIndex + 1],
              newPolygon1Point
            )
          ) {
            polygon1ExpandedDict[i].splice(lastIndex + 1, 0, newPolygon1Point);
          }
        }

        if (pointsEqual(intersectionResult, polygon2[j])) {
          polygon2ExpandedDict[j][0].isCrossPoint = true;
          polygon2ExpandedDict[j][0].isOriginalPoint = true;
          polygon2ExpandedDict[j][0].crossingLine = polygon1Line;
        } else if (
          pointsEqual(intersectionResult, polygon2[(j + 1) % polygon2.length])
          // eslint-disable-next-line no-empty
        ) {
        } else {
          const newPolygon2Point: PointExpanded = {
            ...intersectionResult,
            crossingLine: polygon1Line,
            distanceFromPreviousPoint: pointsDistance(
              polygon2[j],
              intersectionResult
            ),
            isCrossPoint: true,
          };

          lastIndex = polygon2ExpandedDict[j].length - 1;

          while (
            polygon2ExpandedDict[j][lastIndex].distanceFromPreviousPoint &&
            polygon2ExpandedDict[j][lastIndex].distanceFromPreviousPoint >
              newPolygon2Point.distanceFromPreviousPoint
          ) {
            lastIndex--;
          }

          if (
            !pointsEqual(
              polygon2ExpandedDict[j][lastIndex],
              newPolygon2Point
            ) &&
            !pointsEqual(
              polygon2ExpandedDict[j][lastIndex + 1],
              newPolygon2Point
            )
          ) {
            polygon2ExpandedDict[j].splice(lastIndex + 1, 0, newPolygon2Point);
          }
        }
      }
    }
  }

  const polygon1Expanded = [];

  for (let i = 0; i < polygon1.length; i++) {
    for (let j = 0; j < polygon1ExpandedDict[i].length; j++) {
      const polygon1ExpandedPoint = polygon1ExpandedDict[i][j];

      polygon1Expanded.push(polygon1ExpandedPoint);
    }
  }

  const polygon2Expanded = [];
  let startingPoint: PointExpanded | null = null;

  let polygon2ExpandedIndex = -1;
  let index = 0;

  for (let i = 0; i < polygon2.length; i++) {
    try {
      for (let j = 0; j < polygon2ExpandedDict[i].length; j++) {
        const polygon2ExpandedPoint = polygon2ExpandedDict[i][j];

        polygon2Expanded.push(polygon2ExpandedPoint);

        if (startingPoint == null && polygon2ExpandedPoint.isCrossPoint) {
          startingPoint = polygon2ExpandedPoint;
          polygon2ExpandedIndex = index;
        }

        index++;
      }
    } catch (er) {
      let msg = 'Polygon intersection error.\n';

      msg += 'polygon1: ';

      if (!polygon1) msg += 'undefined';
      else {
        for (let p1x = 0; p1x < polygon1.length; p1x++) {
          msg += '(' + polygon1[p1x].x + ', ' + polygon1[p1x].y + ') ';
        }
      }

      msg += '\n';

      msg += 'polygon2: ';

      if (!polygon2) msg += 'undefined';
      else {
        for (let p2x = 0; p2x < polygon2.length; p2x++) {
          msg += '(' + polygon2[p2x].x + ', ' + polygon2[p2x].y + ') ';
        }
      }

      errorToServer(msg);
    }
  }

  let currentPolygon = polygon1Expanded;
  let otherPolygon = polygon2Expanded;
  let currentIndex = -1;

  if (startingPoint == null) {
    // either polygons are separated, or one contains another <==> polygons' lines never intersect one another
    const isPolygon2WithinPolygon1 = isPointInsidePolygon(
      polygon2[0],
      polygon1
    );

    if (isPolygon2WithinPolygon1) {
      startingPoint = polygon2Expanded[0];
      currentPolygon = polygon2Expanded;
      otherPolygon = polygon1Expanded;
      currentIndex = 0;
    } else {
      const isPolygon1WithinPolygon2 = isPointInsidePolygon(
        polygon1[0],
        polygon2
      );

      if (isPolygon1WithinPolygon2) {
        startingPoint = polygon1Expanded[0];
        currentPolygon = polygon1Expanded;
        otherPolygon = polygon2Expanded;
        currentIndex = 0;
      } else {
        // these two polygons are completely separated
        return [];
      }
    }
  } else {
    currentPolygon = polygon2Expanded;
    otherPolygon = polygon1Expanded;
    currentIndex = polygon2ExpandedIndex;
  }

  const newStartingPoint = startingPoint;
  const intersectingPolygon = [newStartingPoint];

  if (newStartingPoint.isCrossPoint) {
    const pointJustAfterStartingPoint = justAfterPointOfACrossPoint(
      currentIndex,
      currentPolygon
    );

    if (!isPointInsidePolygon(pointJustAfterStartingPoint, otherPolygon)) {
      const temp = currentPolygon;

      currentPolygon = otherPolygon;
      otherPolygon = temp;

      currentIndex = currentPolygon.findIndex(point =>
        pointsEqual(newStartingPoint, point)
      );
    }
  }

  let currentPoint = currentPolygon[(currentIndex + 1) % currentPolygon.length];

  currentIndex = (currentIndex + 1) % currentPolygon.length;

  while (!pointsEqual(currentPoint, newStartingPoint)) {
    intersectingPolygon.push(currentPoint);

    if (currentPoint.isCrossPoint) {
      if (currentPoint.crossingLine && currentPoint.isOriginalPoint) {
        const pointJustBeforeCurrent = justBeforePointOfACrossPoint(
          currentIndex,
          currentPolygon
        );
        const pointJustAfterCurrent = justAfterPointOfACrossPoint(
          currentIndex,
          currentPolygon
        );

        const isBeforeLeft = isPointLeftOfLine(
          pointJustBeforeCurrent,
          currentPoint.crossingLine
        );
        const isAfterLeft = isPointLeftOfLine(
          pointJustAfterCurrent,
          currentPoint.crossingLine
        );

        if (isBeforeLeft !== isAfterLeft) {
          const temp = currentPolygon;

          currentPolygon = otherPolygon;
          otherPolygon = temp;
        }
      } else {
        const temp = currentPolygon;

        currentPolygon = otherPolygon;
        otherPolygon = temp;
      }

      currentIndex = currentPolygon.findIndex(point =>
        pointsEqual(currentPoint, point)
      );
    }

    currentIndex = (currentIndex + 1) % currentPolygon.length;
    currentPoint = currentPolygon[currentIndex];
  }

  return intersectingPolygon;
}
