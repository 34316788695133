import { Link, withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FC } from 'react';

const StyledAlert = withStyles(theme => ({
  root: {
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  icon: {
    flexBasis: 34,
    flexShrink: 0,
    margin: 0,
    minWidth: 34,
    width: 34,
  },
}))(Alert);

export const OldDeviceAlert: FC = () => (
  <StyledAlert severity="info">
    You appear to be using an old iOS device. Please{' '}
    <Link
      href="https://support.apple.com/en-us/HT204204"
      target="_blank"
      rel="noreferrer nofollow"
    >
      upgrade
    </Link>{' '}
    your operating system or try a different device.
  </StyledAlert>
);
