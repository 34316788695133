import { makeStyles } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import Draggable from 'react-draggable';

import { postToggleClock } from '../../../studentApp/api';
import { bannerStore } from '../../../studentApp/stores';
import { studentAppModalStore } from '../../../studentApp/stores/studentAppModalStore';
import { FLOATER_BLUE_BACKGROUND } from '../../constants';
import { DraggableTop } from '../util/constants';

interface StyleProps {
  isCollapsed: boolean;
  messageColor: string;
}

const useStyles = makeStyles({
  clockHandle: {
    position: 'fixed',
    top: 0,
    left: '50%',
    backgroundColor: FLOATER_BLUE_BACKGROUND,
    width: '150px',
    height: (props: StyleProps) => (props.isCollapsed ? '25px' : '65px'),
  },
  messageBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    padding: '10px',
    color: (props: StyleProps) => props.messageColor,
  },
});

export const Clock: FC = () => {
  const setShowClock = bannerStore(state => state.setShowClock);
  const minutes = studentAppModalStore(state => state.timeStuck);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const messageColor = minutes > 5 ? 'red' : 'white';
  const classes = useStyles({ isCollapsed, messageColor });

  const [minimizedMessage, setMinimizedMessage] = useState('');
  const [message, setMessage] = useState('');

  const close = () => {
    setShowClock(false);
    postToggleClock({ showClock: false });
  };

  useEffect(() => {
    if (minutes === 0) {
      setMinimizedMessage('< 1 min.');
      setMessage('< 1 min.');
    } else if (minutes === 1) {
      setMinimizedMessage('1 min.');
      setMessage('1 minute');
    } else {
      setMinimizedMessage(minutes + ' mins.');
      setMessage(minutes + '+ minutes');
    }
  }, [minutes, setMessage, setMinimizedMessage]);

  return (
    <Draggable handle=".gmm-clock-handle">
      <div className={classes.clockHandle}>
        <DraggableTop
          backgroundColor={FLOATER_BLUE_BACKGROUND}
          close={close}
          isCollapsed={isCollapsed}
          messageColor={messageColor}
          minimizedMessage={minimizedMessage}
          toggleCollapse={() => setIsCollapsed(!isCollapsed)}
          dragHandle="gmm-clock-handle"
        />

        {!isCollapsed && <div className={classes.messageBody}>{message}</div>}
      </div>
    </Draggable>
  );
};
