import audioIcon from '../images/audioIcon.png';
import audioStopIcon from '../images/audioStopIcon.png';
import gearIcon from '../images/gear.png';
import gear2Icon from '../images/gear2.png';
import gearAnimatedIcon from '../images/gearAnimated.png';
import checkIcon from '../images/GMM-icon-checkgreen.svg';
import checklightIcon from '../images/GMM-icon-checklg.svg';
import pencilenabledIcon from '../images/GMM-icon-pencil-42.svg';
import trashIcon from '../images/GMM-icon-trash.svg';
import trashlightIcon from '../images/GMM-icon-trashlight.svg';
import gmmLogo from '../images/gmm.png';
import lineDottedNoArrowsIcon from '../images/LINE_DOTTED_NO_ARROWS.png';
import lineSolidNoArrowsIcon from '../images/LINE_SOLID_NO_ARROWS.png';
import loadingIcon from '../images/loading.gif';
import loadingwheelIcon from '../images/loadingwheel.gif';

const imageSizes: Record<string, { width: number; height: number }> = {
  gear: { width: 23, height: 24 },
  gear2: { width: 23, height: 24 },
  gearAnimated: { width: 23, height: 24 },
  loadingwheel: { width: 50, height: 50 },
  LINE_DOTTED_NO_ARROWS: { width: 40, height: 20 },
  LINE_SOLID_NO_ARROWS: { width: 40, height: 20 },
};

const images: Record<string, HTMLImageElement> = {};

const imageUrls = {
  trash: trashIcon,
  trashlight: trashlightIcon,
  check: checkIcon,
  checklight: checklightIcon,
  pencilenabled: pencilenabledIcon,
  gear: gearIcon,
  gear2: gear2Icon,
  gearAnimated: gearAnimatedIcon,
  gmm: gmmLogo,
  loadingwheel: loadingwheelIcon,
  loading: loadingIcon,
  // Image Library (to match images produced by code on server)
  LINE_DOTTED_NO_ARROWS: lineDottedNoArrowsIcon,
  LINE_SOLID_NO_ARROWS: lineSolidNoArrowsIcon,
  audioIcon: audioIcon,
  audioStopIcon: audioStopIcon,
} as const;

type ImageUrlKey = keyof typeof imageUrls;

export const getImageUrl = (key: ImageUrlKey): string => imageUrls[key];

export function getImage(
  key: ImageUrlKey,
  onload?: () => void
): HTMLImageElement {
  let image = document.getElementById(
    'student-icon-lib-' + key
  ) as HTMLImageElement;

  if (!(image instanceof HTMLImageElement)) {
    if (images[key]) return images[key];
    const dim = imageSizes[key];

    if (dim) {
      image = new Image(dim.width, dim.height);
    } else {
      image = new Image();
    }

    image.src = getImageUrl(key);
    images[key] = image;
    if (onload) image.onload = onload;
  }

  return image;
}
