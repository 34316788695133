import { FC } from 'react';

import {
  useProcessedProblems,
  useProcessedWorkStore,
} from '../../../studentApp/hooks';
import { problemStore } from '../../../studentApp/stores';
import { squareGroupsStyle } from '../util/constants';

import { LabelAndSquares } from './labelAndSquares';
import { RegionLabel } from './regionLabel';

export const ExamCorrectionsRegion: FC = () => {
  const { examCorrectionsProblems } = useProcessedProblems();
  const selectedId = problemStore(state => state.selectedID);
  const progress = useProcessedWorkStore().examErrorsFixedProgress;
  const selectedProblem = examCorrectionsProblems.find(
    p => p.id === selectedId
  );
  const labelText = selectedProblem ? '#' + selectedProblem.number : '';

  return (
    <>
      <RegionLabel
        text={'Exam Corrections'}
        score={progress}
        id="fixExamErrorsProgress"
      />
      <div
        className="scrollable"
        style={{ ...squareGroupsStyle, maxHeight: '82px' }}
      >
        <LabelAndSquares text={labelText} problems={examCorrectionsProblems} />
      </div>
    </>
  );
};
