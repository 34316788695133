import { BasicObject } from '../uiObjects';

// Most of our Value types are created on the fly
// in the megafile. For example, see getFraction.
// As of the creation of this file, future Value types
// should be defined in separate files instead of the
// megafile. As with AttemptGetters, this means we'll
// have a split: some Value types from the megafile,
// and some Value types from separate files.
export abstract class Value extends BasicObject {
  // The line of Values that this Value belongs to.
  // Someday, we'll split the 'line' object
  // out of the megafile.
  line: any;

  constructor() {
    super();
  }

  // required by logic that handles Values in the megafile.
  setLine(line: any): void {
    this.line = line;
  }

  // required by logic that handles Values in the megafile.
  buildSizeRecursive(): void {
    // default: do nothing. Children override as needed.
  }
}
