import { FC } from 'react';

import {
  useProcessedProblems,
  useProcessedWorkStore,
} from '../../../studentApp/hooks';
import { problemStore } from '../../../studentApp/stores';
import { squareGroupsStyle } from '../util/constants';

import { LabelAndSquares } from './labelAndSquares';
import { RegionLabel } from './regionLabel';

export const PracticeCorrectionsRegion: FC = () => {
  const { practiceCorrectionsProblems } = useProcessedProblems();
  const { practiceRequired, examPracticeProgress } = useProcessedWorkStore();
  const selectedId = problemStore(state => state.selectedID);
  const selectedProblem = practiceCorrectionsProblems.find(
    p => p.id === selectedId
  );
  const labelText = selectedProblem ? '#' + selectedProblem.number : '';

  return practiceRequired ? (
    <>
      <RegionLabel
        text={'Extra Practice'}
        score={examPracticeProgress}
        id="practiceProgress"
      />
      <div className="scrollable" style={squareGroupsStyle}>
        <LabelAndSquares
          text={labelText}
          problems={practiceCorrectionsProblems}
        />
      </div>
    </>
  ) : null;
};
