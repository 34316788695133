import { FC } from 'react';

import { problemStore } from '../../../studentApp/stores';
import { RegionLabel } from '../squares/regionLabel';

import { ShowSquares } from './showSquares';

export const ExamTop: FC = () => {
  const examProblemNumber = problemStore(
    state => state.currentExamProblemNumber
  );

  return (
    <>
      <ShowSquares />
      <RegionLabel
        text={examProblemNumber ? 'Question #' + examProblemNumber : ''}
      />
    </>
  );
};
