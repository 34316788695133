import { ColorScheme, DialogColorFlavor } from '..';

const DIALOG_RED_BODY = '#c6525a';
const DIALOG_RED_BUTTON = '#f16263';
const READ_ALOUD_HIGHLIGHT_RED = 'rgb(78, 168, 224)';

export const DIALOG_BLUE_BODY = '#145DA0';
export const DIALOG_BLUE_BUTTON = '#2E8BC0';
export const READ_ALOUD_HIGHLIGHT_BLUE = 'gray';

export const DIALOG_GREEN_BODY = '#479644';
export const DIALOG_GREEN_BUTTON = '#97CC6B';
export const READ_ALOUD_HIGHLIGHT_GREEN = 'rgb(78, 168, 224)';

export const FLOATER_BLUE_BACKGROUND = '#33a4c6';

// MUI z-index for modals
export const MODAL_Z_INDEX = 1300;

const RED: ColorScheme = {
  button: DIALOG_RED_BUTTON,
  body: DIALOG_RED_BODY,
  highlightColor: READ_ALOUD_HIGHLIGHT_RED,
};

const BLUE: ColorScheme = {
  button: DIALOG_BLUE_BUTTON,
  body: DIALOG_BLUE_BODY,
  highlightColor: READ_ALOUD_HIGHLIGHT_BLUE,
};

export const GREEN: ColorScheme = {
  button: DIALOG_GREEN_BUTTON,
  body: DIALOG_GREEN_BODY,
  highlightColor: READ_ALOUD_HIGHLIGHT_GREEN,
};

export const colorSchemes: Record<DialogColorFlavor, ColorScheme> = {
  red: RED,
  blue: BLUE,
  green: GREEN,
};
