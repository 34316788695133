import { Point } from '../../index';

import { Polygon } from './types';

export function justBeforePointOfACrossPoint(
  pointIndex: number,
  polygon: Polygon
): Point {
  const point = polygon[pointIndex];
  const previousIndex = (polygon.length + pointIndex - 1) % polygon.length;
  const previousPoint = polygon[previousIndex];

  const pointJustBeforeCurrent = {
    x: (point.x + previousPoint.x) / 2,
    y: (point.y + previousPoint.y) / 2,
  };

  return pointJustBeforeCurrent;
}
