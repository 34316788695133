import { CSSProperties, FC } from 'react';

import {
  useLayout,
  useProcessedWorkStore,
  useProcessedProblems,
  useProcessedBannerStore,
} from '../../../studentApp/hooks';
import { bannerStore, problemStore } from '../../../studentApp/stores';
import {
  BANNER_HEIGHT,
  EXAM_BUTTONS_HEIGHT,
  MOBILE_MARGIN,
  POINTS_AND_CREDITS_HEIGHT,
  TEXT_COLOR,
  WELCOME_STRIP_HEIGHT,
} from '../../constants';
import { ProblemRegion } from '../problem/problemRegion';

import { SquareRegions } from './squareRegions';

const landscape: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flexGrow: 1,
  marginRight: '20px',
  color: TEXT_COLOR,
};

const portrait: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'left',
  flexGrow: 1,
  padding: '0px 20px 20px 20px',
  color: TEXT_COLOR,
};

const noProblemsStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  paddingLeft: '20px',
  paddingRight: '20px',
  color: TEXT_COLOR,
};

export const SquaresAndProblem: FC = () => {
  const currentWorkType = useProcessedWorkStore().currentWorkType;
  const [hideWelcomeStrip] = bannerStore(state => [state.hideWelcomeStrip]);
  const noProblemsOnLogin = problemStore(state => state.noProblemsOnLogin);
  const { hasProblems } = useProcessedProblems();
  const { isMobile, isLandscape } = useLayout();
  const { showSquares } = useProcessedBannerStore();

  const mobileTopPadding =
    BANNER_HEIGHT +
    (currentWorkType !== 'EXAM'
      ? POINTS_AND_CREDITS_HEIGHT
      : EXAM_BUTTONS_HEIGHT);

  const portraitTopPadding =
    mobileTopPadding + (hideWelcomeStrip ? 0 : WELCOME_STRIP_HEIGHT);

  const squaresAndProblemStyle = isLandscape
    ? landscape
    : !isMobile
    ? { ...portrait, marginTop: portraitTopPadding + 'px' }
    : {
        ...portrait,
        marginTop: mobileTopPadding + 'px',
        padding: `0px ${MOBILE_MARGIN} ${MOBILE_MARGIN} ${MOBILE_MARGIN}`,
        color: TEXT_COLOR,
      };

  const noProblemsMessage = hasProblems
    ? undefined
    : noProblemsOnLogin
    ? 'Your teacher has not assigned any problems'
    : 'Loading problems...';

  return (
    <>
      {!hasProblems && (
        <div style={noProblemsStyle}>
          <h1>{noProblemsMessage}</h1>
        </div>
      )}
      {hasProblems && currentWorkType !== 'NONE' && (
        <div style={squaresAndProblemStyle}>
          <>
            {showSquares && <SquareRegions />}
            <ProblemRegion />
          </>
        </div>
      )}
    </>
  );
};
