import { ImageLoadedListener } from '@gmm/problem';

import { HighlightLocation, TTSDTO } from '../readAloud';
import { READ_ALOUD_HIGHLIGHT_COLOR_OVERLAY } from '../state';
import { base64ToBlob, drawImage, getImage } from '../utils';

import { BasicObject } from './basicObject';

const MAX_LOADING_WHEEL_SIZE = 50;

interface ImageDTO {
  w: number;
  h: number;
  // Store reference to built image so we don't need to
  // reload/rebuild on subsequent calls to setProblem,
  // such as when student clicks on other problem, then
  // clicks back to this problem.
  image?: HTMLImageElement;
  svg?: string;
  base64?: string;
}

interface ImageURLBuilder {
  // URL up to the '?' (inclusive)
  urlBase: string;
  restoreId: number;
  // Should server check RestoreTest table for the restoreId?
  test?: boolean;
  // Should server check the anonymous redis cache for the restoreId?
  teacher?: boolean;
}

/**
 * 
 * @param imageNumber Generally, the server expects a client-side-incremented number. The server
      methodically iterates through the ProblemQuestionBetter until it gets to 
      this '#th' image.
      Sometimes, the server sends a specific image number instead as part of the
      json describing the image. -1 has been used for answer images in several
      getters. For example, -1 has been used for answer images in several
      getters to specify the image associated with the answer.
 * @param dto width, height and possibly string embedding svg (experimental)
 * @param skipLoadingImage internal tool CanvasCapture uses to avoid loading image
 * @param urlBuilder bundle of strings used to construct url to aquire png from server
 * @param base64Imgs used by retroencabulator (internal testing)
 */
export function getProblemImageSource(
  imageNumber: number,
  dto: ImageDTO,
  urlBuilder?: ImageURLBuilder,
  base64Imgs?: any
): string {
  let url: string;

  // Tricky image storage for retroencabulator
  if (Object.keys(base64Imgs).length > 0) {
    url = base64Imgs[imageNumber];
  }
  // Paused experiment to embed svg in json
  else if (dto.svg) {
    const blob = new Blob([dto.svg], {
      type: 'image/svg+xml;charset=utf-8',
    });

    url = URL.createObjectURL(blob);
  } else if (urlBuilder) {
    url =
      urlBuilder.urlBase +
      'GeneralPurpose?type=probImage&restoreId=' +
      urlBuilder.restoreId +
      '&imageNum=' +
      imageNumber +
      '&c=' +
      new Date().getTime() +
      '&test=' +
      urlBuilder.test +
      '&anonymous=' +
      (!urlBuilder.teacher ? 'false' : 'true');
  } else {
    throw new Error('No image source');
  }

  return url;
}

interface MathImageDependencies {
  paintCanvas: () => void;
}

export class MathImage extends BasicObject {
  // helps with debugging
  gmmName = 'MathImage';

  image: HTMLImageElement;

  dto: ImageDTO;

  loaded = false;

  problemJS: MathImageDependencies;

  // Images provided by DynamicColumnTables sometimes support read aloud with highlighted locations
  highlightLocations: HighlightLocation[] | undefined;
  highlightIndex = -1;

  constructor(
    dto: ImageDTO,
    problemJS: MathImageDependencies,
    x?: number,
    y?: number
  ) {
    super(x, y);

    this.problemJS = problemJS;
    this.dto = dto;

    this.setAllDim(dto.w, dto.h);

    if (dto.image) {
      this.image = dto.image;
      this.loaded = true;
    } else this.image = new Image(this.viewportW, this.viewportH);
  }

  // Enable read aloud for this image. Built to support DynamicColumnTable.
  setTTS(tts: TTSDTO): void {
    this.highlightLocations = tts.highlightLocations;
  }

  loadImageHelper(
    delay?: boolean,
    imageLoadedListener?: ImageLoadedListener
  ): void {
    if (imageLoadedListener) {
      imageLoadedListener.loadingImage();
      // permit capture of canvas image as dataURL (for internal testing)
      this.image.crossOrigin = 'Anonymous';
    }

    // set up the listener before actually loading the image by setting src
    this.image.onload = () => {
      this.loaded = true;

      // Save image to student app problem store for later re-use without needing to rebuild
      this.dto.image = this.image;

      if (delay) {
        // Delay canvas paint for Safari to finish loading embedded fonts for svg
        window.setTimeout(this.problemJS.paintCanvas, 100);
      } else {
        this.problemJS.paintCanvas();
      }

      imageLoadedListener?.loadedImage();
    };
  }

  loadBase64Image(
    base64: string,
    imageLoadedListener?: ImageLoadedListener
  ): void {
    if (this.loaded) return;

    this.loadImageHelper(false, imageLoadedListener);

    base64ToBlob(base64).then(blob => {
      this.image.src = URL.createObjectURL(blob);
    });
  }

  loadImage(
    src: string,
    delay?: boolean,
    imageLoadedListener?: ImageLoadedListener
  ): string {
    if (this.loaded) return this.image.src;

    this.loadImageHelper(delay, imageLoadedListener);

    this.image.src = src;

    return this.image.src;
  }

  paintMe(ctx: CanvasRenderingContext2D): void {
    ctx.save();

    if (this.loaded) {
      drawImage(ctx, this.image, 0, 0, this.image.width, this.image.height);
    } else {
      let side = Math.min(this.viewportW, this.viewportH);

      side = Math.min(side, MAX_LOADING_WHEEL_SIZE);

      drawImage(
        ctx,
        getImage('loadingwheel', this.problemJS.paintCanvas),
        this.viewportW / 2 - side / 2,
        this.viewportH / 2 - side / 2,
        side,
        side
      );
    }

    ctx.restore();
  }

  isTTS(): boolean {
    return !!this.highlightLocations;
  }

  getPaintedCharCount(): number {
    return this.highlightLocations?.length || 0;
  }

  setHighlightReadAloud(start: number): void {
    this.highlightIndex = start;
  }

  clearHighlightReadAloud(): void {
    this.highlightIndex = -1;
  }

  /**
   * BasicObject sets scale in paintPre, if needed, and our
   * invokation of super.paintPost here will reset ctx scale.
   * @param ctx
   */
  paintPost(ctx: CanvasRenderingContext2D): void {
    if (this.isTTS()) {
      ctx.save();

      // Scaled by BasicObject.paintPre if we've called BasicObject.setMaxWidth
      this.paintHighlight(ctx);

      ctx.restore();
    }

    super.paintPost(ctx);
  }

  // During read aloud, paint a border around the highlighted zone and a semi-transparent
  // overlay over the highlighted zone. The overlay is necessary because
  // many DCTs have a white background, so we cannot simply paint a solid highlighted
  // rectangle behind the zone (which would work if DCT's and their cells
  // always used transparent backgrounds).
  paintHighlight(ctx: CanvasRenderingContext2D): void {
    if (this.highlightIndex == -1 || !this.highlightLocations) return;

    const highlightLocation = this.highlightLocations[this.highlightIndex];

    ctx.save();

    // rectangle overlay
    ctx.fillStyle = READ_ALOUD_HIGHLIGHT_COLOR_OVERLAY;
    ctx.fillRect(
      highlightLocation.x,
      highlightLocation.y,
      highlightLocation.w,
      highlightLocation.h
    );

    ctx.restore();
  }
}
