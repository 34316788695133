import { makeStyles } from '@material-ui/core';
import { FC, useState } from 'react';
import Draggable from 'react-draggable';

import { postToggleTopScores } from '../../../studentApp/api';
import { useProcessedStudentAppModalStore } from '../../../studentApp/hooks';
import { bannerStore } from '../../../studentApp/stores';
import { FLOATER_BLUE_BACKGROUND } from '../../constants';
import { DraggableTop } from '../util/constants';

interface StyleProps {
  isCollapsed: boolean;
}

const useStyles = makeStyles({
  topScoresHandle: {
    position: 'fixed',
    width: '175px',
    top: 0,
    // Draggable interferes with transform
    left: `calc(50% - 155px)`,
    backgroundColor: FLOATER_BLUE_BACKGROUND,
    height: (props: StyleProps) => (props.isCollapsed ? '25px' : '200px'),
    color: 'white',
  },
});

export const TopScores: FC = () => {
  const setShowTopScores = bannerStore(state => state.setShowTopScores);
  const topScores = useProcessedStudentAppModalStore().topScoreRows;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const classes = useStyles({ isCollapsed });

  const close = () => {
    setShowTopScores(false);
    postToggleTopScores({ showTopScores: false });
  };

  return (
    <Draggable handle=".gmm-top-scores-handle">
      <div className={classes.topScoresHandle}>
        <DraggableTop
          backgroundColor={FLOATER_BLUE_BACKGROUND}
          close={close}
          isCollapsed={isCollapsed}
          toggleCollapse={() => setIsCollapsed(!isCollapsed)}
          dragHandle="gmm-top-scores-handle"
        />

        {!isCollapsed && (
          <div style={{ padding: '5px' }}>
            <div style={{ fontSize: '130%', color: 'white' }}>Top Scores</div>
            <div
              style={{
                marginTop: '10px',
                overflowY: 'auto',
                overflowX: 'clip',
                maxHeight: '130px',
              }}
            >
              {topScores.length > 0
                ? topScores.map((topScore, index) => (
                    <TopScore
                      key={index}
                      name={topScore.name}
                      score={topScore.score}
                    />
                  ))
                : null}
            </div>
          </div>
        )}
      </div>
    </Draggable>
  );
};

interface TopScoreProps {
  key: number;
  name: string;
  score: number;
}

const TopScore: FC<TopScoreProps> = ({ key, name, score }) => (
  <div
    key={key}
    style={{
      display: 'flex',
      flexDirection: 'row',
      color: 'white',
      paddingBottom: '5px',
    }}
  >
    <span style={{ width: '35px', minWidth: '35px' }}>{score}</span>
    {name}
  </div>
);
