import { useMediaQuery } from '@mui/material';

import {
  Layout,
  MIN_PORTRAIT_WIDTH,
  MAX_PORTRAIT_WIDTH,
  MIN_LANDSCAPE_WIDTH,
} from '../../cnusr/constants';

export const useLayout = () => {
  const isLandscape = useMediaQuery(`(min-width:${MIN_LANDSCAPE_WIDTH}px)`, {
    noSsr: true,
  });
  const isPortrait = useMediaQuery(
    `(min-width:${MIN_PORTRAIT_WIDTH}px) and (max-width:${MAX_PORTRAIT_WIDTH}px)`,
    { noSsr: true }
  );

  const isMobile = !isLandscape && !isPortrait;
  const layout: Layout = isMobile
    ? 'mobile'
    : isPortrait
    ? 'portrait'
    : 'landscape';

  return { isMobile, isPortrait, isLandscape, layout };
};
