import { TTSInterface } from '@gmm/problem';

const END_MESSAGE_LINE = '</p>';

export class ReadAloud implements TTSInterface {
  audioButtonTarget: HTMLElement;
  speech: string;
  originalHTML: string;
  speechToHTML?: Map<number, number>;
  color: string;

  constructor(audioButtonTarget: HTMLElement) {
    this.audioButtonTarget = audioButtonTarget;

    if (!this.audioButtonTarget.dataset.gmmSpeech) {
      this.speech = this.audioButtonTarget.textContent || '';
      this.originalHTML = this.speech;
    } else {
      this.speech = this.audioButtonTarget.dataset.gmmSpeech;
      this.originalHTML = this.audioButtonTarget.dataset.gmmOriginalHtml || '';
      this.speechToHTML = new Map(
        JSON.parse(this.audioButtonTarget.dataset.gmmSpeechToHtml || '[]')
      );
    }

    // ReadAloud relies on data-highlight-color attribute from the audio button.
    // This is set up in the Readable react element.
    this.color = this.audioButtonTarget.dataset.highlightcolor || '';
  }

  getSpokenText(): string {
    return this.speech;
  }

  /**
   * When the speech synthesizer fires an 'onboundary' event, this function
   * will be called. It will highlight the text that is being spoken, starting
   * from the index of the most recent 'onboundary' event and extending to the
   * next space. It does this by resetting the inner html of audioButtonTarget,
   * using 'speech' as the original text, but then with each call injecting a
   * span element with colored background around the spoken text. The color
   * is the responsibility of the dialog component.
   *
   * @param startIndex index of most recent speech onboundary event
   */
  setHighlightReadAloudFromSpokenIndex(startIndex: number): void {
    if (this.speechToHTML) {
      const htmlIndex = this.speechToHTML.get(startIndex);

      if (htmlIndex === undefined) {
        throw new Error('startIndex not found in speechToHTML');
      }

      startIndex = htmlIndex;
    }

    const html = getHighlightedHTML(startIndex, this.originalHTML, this.color);

    this.audioButtonTarget.innerHTML = html;
  }

  /**
   * When the speech synthesizer fires an 'onend' event, this function will be
   * called. It will reset the inner html of audioButtonTarget to its original
   * state, using 'speech' as the original text.
   */
  readAloudEnded(): void {
    this.audioButtonTarget.innerHTML = this.originalHTML;
  }
}

/**
 * @param startIndex index of most recent speech onboundary event
 */
export function getHighlightedHTML(
  startIndex: number,
  originalHTML: string,
  color: string
): string {
  const pre = originalHTML.slice(0, startIndex);
  const trimmed = originalHTML.slice(startIndex);
  const stopIndex = getStopIndex(trimmed);
  const highlightedText = trimmed.slice(
    0,
    stopIndex !== -1 ? stopIndex : trimmed.length
  );
  const post = stopIndex === -1 ? '' : trimmed.slice(stopIndex);

  return pre + getHighlightSpan(highlightedText, color) + post;
}

export function getHighlightSpan(text: string, color: string): string {
  return `<span style="background-color:${color}">${text}</span>`;
}

/**
 * Return the smallest index of the next ' ' or '</p>'...
 * ... or -1 if neither is found.
 */
function getStopIndex(text: string): number {
  const stopIndexes = [];
  const stopSignals = [' ', END_MESSAGE_LINE];

  for (const stopSignal of stopSignals) {
    const index = text.indexOf(stopSignal);

    if (index !== -1) {
      stopIndexes.push(index);
    }
  }

  return stopIndexes.length > 0 ? Math.min(...stopIndexes) : -1;
}
