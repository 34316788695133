import { FC, useState } from 'react';
import Draggable from 'react-draggable';

import {
  DIALOG_BLUE_BODY,
  DIALOG_BLUE_BUTTON,
  ModalButton,
  READ_ALOUD_HIGHLIGHT_BLUE,
  Readable,
  StaticProblemCanvas,
  modalStyle,
} from '@gmm/problem';

import { studentAppModalStore } from '../../../studentApp/stores/studentAppModalStore';
import { animateReplaceProblem } from '../../../studentApp/utils/gmmUtils';
import { DraggableTop } from '../util/constants';

interface ShowAnswerProps {
  close: () => void;
}

export const ShowAnswer: FC<ShowAnswerProps> = ({ close }) => {
  const problemData = studentAppModalStore(
    state => state.answerToCurrentProblem
  );
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const replace = () => {
    close();
    animateReplaceProblem();
  };

  return (
    <Draggable handle=".show-answer-handle">
      <div
        style={{
          ...modalStyle,
          justifyContent: 'left',
          backgroundColor: DIALOG_BLUE_BODY,
          padding: '0px',
        }}
      >
        <DraggableTop
          dragHandle="show-answer-handle"
          backgroundColor={DIALOG_BLUE_BODY}
          close={replace}
          isCollapsed={isCollapsed}
          toggleCollapse={toggleCollapse}
        />

        {!isCollapsed && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '3px 20px 20px 20px',
              maxHeight: '475px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                fontSize: '130%',
                fontWeight: 'bold',
              }}
            >
              Answer
            </div>

            <div style={{ height: '4px' }} />

            <Readable
              highlightColor={READ_ALOUD_HIGHLIGHT_BLUE}
              text="When you close this message, we will replace your problem."
            />

            <div style={{ height: '10px' }} />

            <div
              id="answers"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                flexGrow: 1,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  margin: '0px',
                  padding: '5px 5px 0px 5px',
                  backgroundColor: 'white',
                }}
              >
                <StaticProblemCanvas problemData={problemData} />
              </div>
            </div>

            <div style={{ height: '20px' }} />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
              }}
            >
              <ModalButton
                color={DIALOG_BLUE_BUTTON}
                text="OK"
                close={replace}
              />
            </div>
          </div>
        )}
      </div>
    </Draggable>
  );
};
