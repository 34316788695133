import { CSSProperties, FC } from 'react';

import { bannerStore } from '../../../studentApp/stores';
import { POINTS_TODAY_FONT_SIZE } from '../../constants';
import { Space } from '../util/constants';
import { TextOnCanvas } from '../util/textOnCanvas';

import { PointsText } from './pointsTodayAndWeek';

const style: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: 'auto',
  fontSize: POINTS_TODAY_FONT_SIZE,
};

export const MobileGameCredits: FC = () => {
  const gameCredits = bannerStore(state => state.gameCredits);
  const text = 'game ' + (gameCredits === 1 ? 'credit' : 'credits');

  return (
    <div style={style}>
      <TextOnCanvas
        text={gameCredits.toString()}
        fontSize={POINTS_TODAY_FONT_SIZE}
      />
      <Space />
      <PointsText>{text}</PointsText>
    </div>
  );
};
