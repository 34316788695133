import { sendObject } from './handler';

export type ToggleEffects = { type: 'toggleEffects'; effects: boolean };

type Options = {
  showSmileys: boolean;
};

export const postToggleEffects = async ({
  showSmileys,
}: Options): Promise<void> =>
  sendObject({ type: 'toggleEffects', effects: showSmileys });
