import { styled } from '@material-ui/core';
import { mergeWith, omit } from 'lodash';
import { FC, useState } from 'react';

import { useStorage } from '../studentApp/hooks';
import { AppContext, AppContextProvider, Page } from './stores/appContext';
import { gmmLogo } from './ui/svg/logo';
import { GetStarted } from './views/getStarted';
import { Login } from './views/login';
import { Register } from './views/register';

const Container = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(5),
}));

export const App: FC<AppContext> = props => {
  const [urlParams, setUrlParams] = useStorage('appContext', props.urlParams, {
    merge: (initialValues, storedValues) => {
      const merged = mergeWith(
        storedValues,
        initialValues,
        (value, srcValue) => value || srcValue
      );

      if (initialValues.classCode) return omit(merged, 'page');

      return merged;
    },
    storage: window.sessionStorage,
  });
  const [page, setPageInternal] = useState<Page>(() => {
    if (urlParams.page) return urlParams.page;

    return urlParams.status === 'ok' ? 'getStarted' : 'login';
  });
  const setPage = (page: Page): void => {
    setPageInternal(page);
    setUrlParams({
      ...urlParams,
      page,
    });
  };

  const handleRegister = (): void => setPage('register');
  const handleLogin = (): void => setPage('login');

  return (
    <AppContextProvider value={{ ...props, urlParams }}>
      <Container>
        {gmmLogo}
        {page === 'getStarted' && (
          <GetStarted onRegister={handleRegister} onLogin={handleLogin} />
        )}
        {page === 'login' && <Login />}
        {page === 'register' && <Register onLogin={handleLogin} />}
      </Container>
    </AppContextProvider>
  );
};
