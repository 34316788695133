import { styled } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';

import { BOX_SHADOW } from '../../../../cnusr/constants';
import { examBoxClick, ExamProblem, problemStore } from '../../../stores';

import { boxIcons } from './boxIcons';

import { lockIcon } from '.';

interface Props {
  problem: ExamProblem;
}
const InnerBox = styled('div')({
  height: 24,
  width: 24,
  position: 'relative',
});

export const ExamBox: FC<Props> = ({ problem }) => {
  const selectedID = problemStore(state => state.selectedID);
  const { id, locked, ready, seen } = problem;
  const problemUntried = !locked && !seen;
  const problemValid = !locked && ready && seen;
  const problemInvalid = !locked && !ready && seen;
  const isSelected = selectedID === id;
  const viewedAndUnanswered = locked && !ready;

  const handleClick = (): void => {
    examBoxClick(problem.id);
  };

  return (
    <div
      className={clsx('box', {
        'box-selected': isSelected,
      })}
      id={id}
      onClick={handleClick}
    >
      <div
        className={clsx('innerBox center', {
          magentaback: viewedAndUnanswered,
          'test-locked': locked,
          'test-untried': problemUntried,
          'test-valid': problemValid || problemInvalid,
        })}
        style={{
          cursor: locked ? 'default' : 'pointer',
          boxShadow: locked ? 'none' : BOX_SHADOW,
        }}
      >
        <span
          className={clsx('center', {
            'invalid-test-exclamation': problemInvalid,
          })}
        >
          {problemValid && <InnerBox>{boxIcons['diamond']}</InnerBox>}
          {locked && <InnerBox>{lockIcon}</InnerBox>}
        </span>
      </div>
    </div>
  );
};
