import { sendObject } from './handler';

export type ToggleTopScores = {
  type: 'toggleTopScores';
  showTopScores: 't' | 'f';
};

type Options = {
  showTopScores: boolean;
};

export const postToggleTopScores = async ({
  showTopScores,
}: Options): Promise<void> => {
  return sendObject({
    type: 'toggleTopScores',
    showTopScores: showTopScores ? 't' : 'f',
  });
};
