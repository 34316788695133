export function arrowUp(
  ctx: CanvasRenderingContext2D,
  w: number,
  h: number,
  lineWidth: number,
  stroke: string | CanvasGradient | CanvasPattern,
  fill?: string | CanvasGradient | CanvasPattern
): void {
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = stroke;
  ctx.beginPath();
  ctx.moveTo(0.5 * w, 1);
  ctx.lineTo(w - 1, 0.55 * h);
  ctx.lineTo(0.8 * w, 0.55 * h);
  ctx.lineTo(0.8 * w, h - 1);
  ctx.lineTo(0.2 * w, h - 1);
  ctx.lineTo(0.2 * w, 0.55 * h);
  ctx.lineTo(1, 0.55 * h);
  ctx.lineTo(0.5 * w, 1);
  ctx.closePath();

  if (fill) {
    ctx.fillStyle = fill;
    ctx.fill();
  }

  ctx.stroke();
}

export function arrowDown(
  ctx: CanvasRenderingContext2D,
  w: number,
  h: number,
  lineWidth: number,
  stroke: string | CanvasGradient | CanvasPattern,
  fill?: string | CanvasGradient | CanvasPattern
): void {
  ctx.beginPath();
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = stroke;
  ctx.moveTo(0.5 * w, h - 1);
  ctx.lineTo(w - 1, 0.45 * h);
  ctx.lineTo(0.8 * w, 0.45 * h);
  ctx.lineTo(0.8 * w, 1);
  ctx.lineTo(0.2 * w, 1);
  ctx.lineTo(0.2 * w, 0.45 * h);
  ctx.lineTo(1, 0.45 * h);
  ctx.lineTo(0.5 * w, h - 1);
  ctx.closePath();

  if (fill) {
    ctx.fillStyle = fill;
    ctx.fill();
  }

  ctx.stroke();
}

export function arrowLeft(
  ctx: CanvasRenderingContext2D,
  w: number,
  h: number,
  lineWidth: number,
  stroke: string | CanvasGradient | CanvasPattern,
  fill?: string | CanvasGradient | CanvasPattern
): void {
  ctx.beginPath();
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = stroke;
  ctx.moveTo(1, 0.5 * h);
  ctx.lineTo(0.55 * w, h - 1);
  ctx.lineTo(0.55 * w, 0.8 * h);
  ctx.lineTo(w - 1, 0.8 * h);
  ctx.lineTo(w - 1, 0.2 * h);
  ctx.lineTo(0.55 * w, 0.2 * h);
  ctx.lineTo(0.55 * w, 1);
  ctx.lineTo(1, 0.5 * h);
  ctx.closePath();

  if (fill) {
    ctx.fillStyle = fill;
    ctx.fill();
  }

  ctx.stroke();
}

export function arrowRight(
  ctx: CanvasRenderingContext2D,
  w: number,
  h: number,
  lineWidth: number,
  stroke: string | CanvasGradient | CanvasPattern,
  fill?: string | CanvasGradient | CanvasPattern
): void {
  ctx.beginPath();
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = stroke;
  ctx.moveTo(w - 1, 0.5 * h);
  ctx.lineTo(0.45 * w, h - 1);
  ctx.lineTo(0.45 * w, 0.8 * h);
  ctx.lineTo(1, 0.8 * h);
  ctx.lineTo(1, 0.2 * h);
  ctx.lineTo(0.45 * w, 0.2 * h);
  ctx.lineTo(0.45 * w, 1);
  ctx.lineTo(w - 1, 0.5 * h);
  ctx.closePath();

  if (fill) {
    ctx.fillStyle = fill;
    ctx.fill();
  }

  ctx.stroke();
}
