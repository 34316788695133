interface MathObject {
  t:
    | 'line'
    | 'm'
    | 'text'
    | 'quantity'
    | 'fraction'
    | 'absoluteValue'
    | 'power'
    | 'subscript'
    | 'function'
    | 'root'
    | 'barred';
  children?: MathObject[];
}

// These four is/has methods used to be members of BasicObject,
// which was undesirable for our ROBO (Rip Out Basic Object)
// dependency-free version of BasicObject. Ideally, these
// behaviors could reside in a superclass MathObject, but that's a...
// Target for future cleansing.
export function isText(mathObject: MathObject): boolean {
  return mathObject.t === 'text';
}

export function hasText(mathObject: MathObject): boolean {
  if (mathObject.children?.length == 1) return hasText(mathObject.children[0]);

  return mathObject.t === 'text';
}

export function isQuantity(mathObject: MathObject): boolean {
  return mathObject.t === 'quantity';
}

export function hasQuantity(mathObject: MathObject): boolean {
  if (!mathObject.children) return false;

  return mathObject.children.some(
    child => isQuantity(child) || (child.t == 'm' && hasQuantity(child))
  );
}
