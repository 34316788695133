import { ProblemData } from '..';

import { isTouchDevice } from './isTouchDevice';

type TextObject = string | { [key: string]: TextObject };

export function decodeString(obj: string): string | undefined {
  let text = obj;

  while (text.indexOf('&encu') != -1) {
    const i = text.indexOf('&encu');
    const vOffset = i + 5;
    const delim = text.indexOf(';', vOffset);

    const enc = String.fromCharCode(
      parseInt(text.substring(vOffset, delim), 16)
    );

    if (i === 0) {
      text = enc + text.substring(delim + 1);
    } else {
      text = text.substring(0, i) + enc + text.substring(delim + 1);
    }
  }

  // BEGIN DEPRECATED DECODING (REMOVE AFTER A FEW BUILDS?)
  if (text.indexOf('&ge;') > -1) {
    text = text.replace(/&ge;/g, '\u2265');
  }

  if (text.indexOf('&le;') > -1) {
    text = text.replace(/&le;/g, '\u2264');
  }

  if (text.indexOf('&ne;') > -1) {
    text = text.replace(/&ne;/g, '\u2260');
  }

  if (text.indexOf('&divide;') > -1) {
    text = text.replace(/&divide;/g, '\u00F7');
  }

  if (text.indexOf('&angle;') > -1) {
    text = text.replace(/&angle;/g, '\u2220');
  }

  if (text.indexOf('&tri;') > -1) {
    text = text.replace(/&tri;/g, '\u25B3');
  }

  if (text.indexOf('&pi;') > -1) {
    text = text.replace(/&pi;/g, '\u03C0');
  }

  if (text.indexOf('&dot;') > -1) {
    text = text.replace(/&dot;/g, '\u2022');
  }

  if (text.indexOf('&inverse;') > -1) {
    text = text.replace(/&inverse;/g, '\u207B\u00B9');
  }

  if (text.indexOf('&infinity;') > -1) {
    text = text.replace(/&infinity;/g, '\u221E');
  }

  if (text.indexOf('&theta;') > -1) {
    text = text.replace(/&theta;/g, '\u03B8');
  }

  if (text.indexOf('&rightarrow;') > -1) {
    text = text.replace(/&rightarrow;/g, '\u279C');
  }

  if (text.indexOf('&approxequal;') > -1) {
    text = text.replace(/&approxequal;/g, '\u2248');
  }

  if (text.indexOf('&degree;') > -1) {
    text = text.replace(/&degree;/g, '\u00B0');
  }

  if (text.indexOf('&delta;') > -1) {
    text = text.replace(/&delta;/g, '\u0394');
  }
  // END DEPRECATED DECODING (REMOVE AFTER A FEW BUILDS?)

  // If not an svg, turn hyphen into minus
  if (text.indexOf('svg') < 0 && text.indexOf('-') > -1) {
    text = text.replace(/-/g, '\u2212');
  }

  if (isTouchDevice() && text.indexOf('Click on') > -1) {
    text = text.replace(/Click on/g, 'Tap on');
  }

  if (text !== obj) {
    return text;
  }

  return undefined;
}

// Parameter may be a string or an array of strings
export function decodeText(obj: TextObject): string | void {
  if (typeof obj === 'string') {
    return decodeString(obj);
  } else {
    for (const prop in obj) {
      const use = decodeText(obj[prop]);

      if (use) {
        obj[prop] = use;
      }
    }
  }
}

export function decodeProblemData(
  problem: ProblemData | undefined
): string | void {
  if (!problem) return;

  return decodeText((problem as unknown) as TextObject);
}
