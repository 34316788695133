import { styled } from '@material-ui/core';
import clsx from 'clsx';
import { forwardRef, ReactNode, SyntheticEvent } from 'react';

const Container = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexShrink: 0,
  height: 36,
  justifyContent: 'center',
  perspective: 1000,
  width: 36,
  position: 'relative',
});

interface Props {
  children: ReactNode;
  id: string;
  isSelected: boolean;
  onClick: (event: SyntheticEvent) => void;
}

export const OuterBox = forwardRef<HTMLDivElement, Props>(function OuterBox(
  { isSelected, id, onClick, ...props },
  ref
) {
  return (
    <Container
      id={id}
      onClick={onClick}
      className={clsx({
        'box-selected': isSelected,
      })}
      ref={ref}
    >
      {props.children}
    </Container>
  );
});
