import {
  createMuiTheme as createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core';
import { FC } from 'react';

const theme = createTheme({
  palette: {
    primary: { main: '#337ab7' },
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: 16,
      },
      body2: {
        fontSize: 14,
      },
      h4: {
        color: '#6b6d70',
        fontSize: 24,
      },
      h6: {
        color: '#6b6d70',
        fontSize: 20,
        fontWeight: 400,
      },
      colorTextSecondary: {
        color: '#737579',
      },
    },
    MuiButton: {
      text: {
        fontSize: 12,
        fontWeight: 500,
        textTransform: 'none',
      },
    },
    MuiSnackbarContent: {
      message: {
        fontSize: 14,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
      },
    },
    MuiPaper: {
      root: {
        padding: 8,
      },
    },
  },
});

export const ThemeProvider: FC = ({ children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);
