import { roundRect } from './roundRect';

export function roundRect2(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number,
  r: number,
  fillColor?: string,
  lineColor?: string,
  thick = 1
): void {
  ctx.save();
  ctx.lineWidth = thick;
  roundRect(ctx, x, y, w, h, r);

  if (fillColor) {
    ctx.fillStyle = fillColor;
    ctx.fill();
  } else {
    if (lineColor) ctx.strokeStyle = lineColor;
    ctx.stroke();
  }

  ctx.restore();
}
