import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  makeStyles,
} from '@material-ui/core';
import { FC, KeyboardEventHandler } from 'react';
import { useModal } from '../../hooks/useModal';
import { Button } from '../../ui';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
});

export const ModalContainer: FC = ({ children }) => {
  const {
    isShowing,
    close,
    modalProps: {
      component,
      onClose,
      dialogText: { description, buttonText, title },
    },
  } = useModal();
  const classes = useStyles();

  const handleClose = (): void => {
    close();
    onClose?.();
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      handleClose();
    }
  };

  return (
    <Dialog
      aria-labelledby="modal-dialog-title"
      aria-describedby="modal-dialog-description"
      maxWidth={'xs'}
      disableAutoFocus
      classes={{ root: classes.root }}
      open={isShowing}
      onClose={handleClose}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>{description}</DialogContentText>
        {component ? component : children}
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleClose}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
