import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';

import { usePrevious } from './usePrevious';

const defaultMerge = <S>(_: S, storedValue: S): S => storedValue;

const getFromStorage = <S>(
  storage: Storage,
  key: string,
  getInitialValue: () => S
): S => {
  const item = storage.getItem(key);

  if (!item) return getInitialValue();

  try {
    return JSON.parse(item);
  } catch (error) {
    return getInitialValue();
  }
};

interface Options<S> {
  merge?: (initialValue: S, storedValue: S) => S;
  storage?: Storage;
}

export const useStorage = <S>(
  key: string,
  initialValue: S | (() => S),
  { merge = defaultMerge, storage = window.localStorage }: Options<S> = {}
): [S, Dispatch<SetStateAction<S>>] => {
  const previousKey = usePrevious(key);
  const initialValueRef = useRef(initialValue);
  const storageRef = useRef(storage);
  const getInitialValue = useCallback((): S => {
    // This will make it so we only run `initialValue` once if it is a function
    if (typeof initialValueRef.current === 'function') {
      initialValueRef.current = (initialValueRef.current as () => S)();
    }

    return initialValueRef.current;
  }, []);
  const updated = useRef(false);
  const [storedValue, setStoredValue] = useState<S>(() => {
    const item = storageRef.current.getItem(key);

    if (!item) return getInitialValue();

    try {
      return merge(getInitialValue(), JSON.parse(item));
    } catch (error) {
      return getInitialValue();
    }
  });

  // istanbul ignore next: hard to test and probably not worth it
  const handleStorageEvent = useCallback(
    ({ key: eventKey, newValue }: StorageEvent): void => {
      if (eventKey !== key) return;

      if (updated.current) {
        updated.current = false;

        return;
      }

      setStoredValue(prevValue => {
        try {
          const nextValue = newValue !== null ? JSON.parse(newValue) : null;
          const valueToStore =
            nextValue === null ? getInitialValue() : nextValue;

          return isEqual(valueToStore, prevValue) ? prevValue : valueToStore;
        } catch (error) {
          return prevValue;
        }
      });
    },
    [getInitialValue, key]
  );

  useEffect(() => {
    if (!previousKey || previousKey === key) return;

    setStoredValue(getFromStorage(storageRef.current, key, getInitialValue));
  }, [getInitialValue, key, previousKey]);

  useEffect(() => {
    window.addEventListener('storage', handleStorageEvent);

    return () => window.removeEventListener('storage', handleStorageEvent);
  }, [handleStorageEvent]);

  const setValue = useCallback(
    (value: SetStateAction<S>): void => {
      setStoredValue(prevValue => {
        try {
          const nextValue =
            value instanceof Function ? value(prevValue) : value;
          const valueToStore =
            nextValue === null
              ? /* istanbul ignore next: TS does not allow this */ getInitialValue()
              : nextValue;

          const newValue = JSON.stringify(valueToStore);

          updated.current = true;
          storageRef.current.setItem(key, newValue);
          window.requestAnimationFrame(() => {
            // If we have the same key used multiple times, this makes sure
            // each one gets the update
            window.dispatchEvent(
              new StorageEvent('storage', {
                key,
                newValue,
                oldValue: JSON.stringify(prevValue),
              })
            );
          });

          return valueToStore;
        } catch (error) {
          return prevValue;
        }
      });
    },
    [getInitialValue, key]
  );

  return [storedValue, setValue];
};
