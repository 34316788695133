import { FC } from 'react';

import logo from '../../../static_files/GMM-images/gmm-letters-white.svg';
import {
  useProcessedBannerStore,
  useProcessedWorkStore,
} from '../../../studentApp/hooks';
import { MOBILE_MARGIN } from '../../constants';
import { TextOnCanvas } from '../util/textOnCanvas';

import { StyledBanner, logoStyle } from './banner';
import { HamburgerMenu } from './hamburger';

export const MobileBanner: FC = () => {
  const progress = useProcessedWorkStore().currentWorkProgress;
  const themeColor = useProcessedBannerStore().themeColor;
  const currentWorkType = useProcessedWorkStore().currentWorkType;

  return (
    <StyledBanner
      color={themeColor}
      style={{
        justifyContent: 'space-between',
        paddingLeft: MOBILE_MARGIN,
        paddingRight: MOBILE_MARGIN,
      }}
    >
      <img src={logo} style={logoStyle} />

      {currentWorkType !== 'SPIRAL_REVIEW' && currentWorkType !== 'EXAM' && (
        <TextOnCanvas
          text={progress}
          color={'white'}
          bold={true}
          fontSize={30}
        />
      )}

      {currentWorkType === 'SPIRAL_REVIEW' && (
        <img
          src="GMM-icons/GMM-icon-transparent-spiral.svg"
          style={{ maxHeight: '30px' }}
        />
      )}

      {currentWorkType !== 'EXAM' && <HamburgerMenu />}
    </StyledBanner>
  );
};
