import { CSSProperties, FC } from 'react';

import { ExamProblem, NormalProblem } from '../../../studentApp/stores';
import {
  ProblemShared,
  isExamProblem,
  isNormalProblem,
} from '../../../studentApp/utils';
import { ExamBox, NormalBox } from '../../../studentApp/views/student/boxes';

const squaresStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'left',
  alignItems: 'center',
  // note to self: I wanted to establish this at one level higher, but
  // when I set the minheight at LabelAndSquares, the height of this
  // component no longer adjusted the height of that component
  minHeight: '41px',
};

const noSkillsStyle: CSSProperties = {
  fontStyle: 'italic',
  fontSize: '12px',
  fontWeight: 500,
  marginLeft: '5px',
};

interface SquaresProps {
  problems: ProblemShared[];
}

export const Squares: FC<SquaresProps> = ({ problems }) => {
  return (
    <div style={squaresStyle}>
      {problems.length === 0 ? (
        <span style={noSkillsStyle}>no skills</span>
      ) : (
        problems.map(renderProblem)
      )}
    </div>
  );
};

const renderProblem = (problem: NormalProblem | ExamProblem) => {
  if (isNormalProblem(problem)) {
    return <NormalBox key={problem.id} problem={problem as NormalProblem} />;
  }

  if (isExamProblem(problem)) {
    return <ExamBox key={problem.id} problem={problem as ExamProblem} />;
  }

  return null;
};
