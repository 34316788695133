import { useCallback, useEffect } from 'react';

import { useModal } from '../../onboardingApp/hooks/useModal';
import { NormalProblem, problemStore } from '../stores';
import { ModalBoxAnimation } from '../views/student/boxes';

import { useProcessedWorkStore } from './useProcessedWorkStore';

const dialogText = {
  buttonText: 'OKAY',
  description: 'Congratulations! You just leveled up a Skill!',
  title: 'Skill Leveled-Up!',
};

export const useLevelUp = (): void => {
  const isExam = useProcessedWorkStore().isTesting;
  const problems = problemStore(state => state.problems);
  const levelingUp = problemStore(state => state.levelingUp);
  const updateProblem = problemStore(state => state.updateProblem);
  const clearLevelingUp = problemStore(state => state.clearLevelingUp);

  const { show } = useModal();

  const completeLevelingUp = useCallback((): void => {
    updateProblem(levelingUp.id, {
      lvl: levelingUp.lvl,
    });
    clearLevelingUp();
  }, [clearLevelingUp, levelingUp.id, levelingUp.lvl, updateProblem]);

  const notifyStudent = useCallback(
    (problem: NormalProblem): void => {
      show({
        component: (
          <ModalBoxAnimation problem={{ ...problem, lvl: levelingUp.lvl }} />
        ),
        dialogText,
      });

      completeLevelingUp();
    },
    [completeLevelingUp, levelingUp.lvl, show]
  );

  useEffect(() => {
    if (problems[levelingUp.id]) completeLevelingUp();
  }, [completeLevelingUp, levelingUp.id, problems]);

  useEffect(() => {
    if (!problems[levelingUp.id] || isExam) return;

    notifyStudent(problems[levelingUp.id] as NormalProblem);
  }, [problems, levelingUp, isExam, notifyStudent]);
};
