import { styled, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useAppContext } from '../../stores/appContext';

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3.5),
}));

const getWelcomeMessage = (name: string): string => {
  if (name === '') return 'Welcome';

  return name.endsWith('s')
    ? `Welcome to ${name}' Class`
    : `Welcome to ${name}'s Class`;
};

export const WelcomeMessage: FC = () => {
  const { className, teacherName } = useAppContext().urlParams;

  const welcomeMessage = getWelcomeMessage(teacherName);

  return (
    <>
      <Title align="center" variant="h4">
        {welcomeMessage}
      </Title>
      <Title align="center" variant="h6">
        {className}
      </Title>
    </>
  );
};
