import { styled } from '@mui/material';
import { FC } from 'react';

import { useLayout, useProcessedBannerStore } from '../../../studentApp/hooks';
import { bannerStore } from '../../../studentApp/stores';
import {
  POINTS_TODAY_BIG_FONT_SIZE,
  POINTS_TODAY_FONT,
  POINTS_TODAY_FONT_SIZE,
} from '../../constants';
import { Space } from '../util/constants';
import { TextOnCanvas } from '../util/textOnCanvas';

export const PointsTodayAndWeek: FC = () => {
  const [pointsToday, pointsThisWeek] = bannerStore(state => [
    state.pointsToday,
    state.pointsThisWeek,
  ]);
  const { themeColor } = useProcessedBannerStore();
  const isMobile = useLayout().isMobile;
  const pointsText = isMobile ? 'today' : 'points today';
  const weekText = isMobile ? 'week' : 'points this week';

  return (
    <>
      <PointsText>{pointsText}</PointsText>
      <Space />
      <TextOnCanvas
        text={pointsToday}
        fontSize={POINTS_TODAY_BIG_FONT_SIZE}
        roundedRectangle={true}
        backColor={themeColor}
        color="white"
        bold={true}
        id="pointsToday"
      />
      <Space />
      <Space />
      <PointsText>{weekText}</PointsText>
      <Space />
      <TextOnCanvas
        text={pointsThisWeek}
        fontSize={POINTS_TODAY_FONT_SIZE}
        id="pointsThisWeek"
      />
    </>
  );
};

export const PointsText = styled('span')(() => ({
  fontSize: POINTS_TODAY_FONT_SIZE,
  fontFamily: POINTS_TODAY_FONT,
}));
