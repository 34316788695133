import { FC } from 'react';
import Draggable from 'react-draggable';

import { ModalHeader, modalStyle } from '@gmm/problem';

import { useProcessedProblems } from '../../../studentApp/hooks';
import { bannerStore } from '../../../studentApp/stores';
import { RowDivider } from '../util/constants';

export const Statistics: FC<{ close: () => void }> = ({ close }) => {
  const [totalDollarAttempts, pointsEarned, oldest] = bannerStore(state => [
    state.totalDollarAttempts,
    state.pointsEarned,
    state.oldest,
  ]);
  const statistics = useProcessedProblems().currentProblemStatistics;

  return (
    <Draggable handle=".gmm-statistics-modal">
      <div className="blue" style={modalStyle}>
        <ModalHeader
          title={'Overall Statistics'}
          close={close}
          dragHandle={'gmm-statistics-modal'}
        />

        <div style={{ height: '5px' }}></div>

        <Stat label="Total $ Attempts" value={totalDollarAttempts.toString()} />
        <Stat label="Points Earned" value={pointsEarned.toString()} />
        <Stat label="Rotation Time" value={oldest} />

        <RowDivider />

        <div
          style={{
            fontSize: '150%',
            fontWeight: 'bold',
            marginTop: '10px',
            marginBottom: '5px',
          }}
        >
          <span>Current Skill</span>
        </div>

        <Stat label="Accuracy" value={statistics.mySkillAccuracy} />
        <Stat label="Attempts" value={statistics.mySkillAttempts} />
        <Stat label="Last three" value={statistics.lastThree} />
        <Stat label="Last five" value={statistics.lastFive} />
        <Stat label="Last ten" value={statistics.lastTen} />
        <Stat label="Last correct" value={statistics.lastCorrectDate} />
      </div>
    </Draggable>
  );
};

const Stat: FC<{
  label: string;
  value: string | number | undefined;
}> = props => {
  return (
    <p>
      {props.label}:{' '}
      <span>{props.value !== undefined ? props.value : 'N/A'}</span>
    </p>
  );
};
