import { CSSProperties, FC } from 'react';

import { useProcessedProblems } from '../../../studentApp/hooks';
import { BOX_SHADOW, EXAM_BUTTONS_HEIGHT } from '../../constants';

import { HandInButton } from './handInButton';
import { RequestUnlockButton } from './requestUnlock';

const outerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: EXAM_BUTTONS_HEIGHT,
  minHeight: EXAM_BUTTONS_HEIGHT,
  paddingLeft: '20px',
  paddingRight: '20px',
  boxShadow: BOX_SHADOW,
};

const innerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ExamButtons: FC = () => {
  const { showRequestUnlockButton } = useProcessedProblems();

  return (
    <div style={outerStyle}>
      <div style={innerStyle}>
        <HandInButton />
        {showRequestUnlockButton && (
          <>
            <div style={{ width: '20px' }} />
            <RequestUnlockButton />
          </>
        )}
      </div>
    </div>
  );
};
