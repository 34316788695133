import { FC } from 'react';
import { AlertOptions, modalStyle } from '..';
import { ModalHeader } from './modalHeader';
import { ModalButton } from './modalButton';
import { Readable } from './readable';
import Draggable from 'react-draggable';
import { colorSchemes } from './constants';
import { problemModalStore } from './problemModalStore';

export interface DialogProps {
  alert: AlertOptions;
  closer?: () => void;
}

export const Dialog: FC<DialogProps> = ({ alert, closer }) => {
  const defaultCloser = problemModalStore(state => state.popStackableDialog);
  const close = closer || defaultCloser;
  const { top, msg, style } = alert;
  const colorScheme = colorSchemes[style || 'green'];

  return (
    <Draggable handle=".gmm-dialog">
      <div
        style={{
          ...modalStyle,
          backgroundColor: `${colorScheme.body}`,
        }}
      >
        <ModalHeader
          title={top || 'GMM'}
          close={close}
          dragHandle={'gmm-dialog'}
        />

        <div style={{ height: '20px' }}></div>

        <Readable text={msg} highlightColor={colorScheme.highlightColor} />

        <div style={{ height: '20px' }}></div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
          }}
        >
          <ModalButton color={colorScheme.button} text="OK" close={close} />
        </div>
      </div>
    </Draggable>
  );
};
