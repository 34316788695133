import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { OtherClass } from '../api';
import { DEFAULT_THEME_OPTION, ThemeOption, themeOptions } from '../types';

import { getGmm } from './globalState';

const MAX_LOGINS_FOR_WELCOME = 4;

export interface BannerStoreState {
  isHandRaised: boolean;
  pointsToday: number;
  pointsThisWeek: number;
  gameCredits: number;
  pointsTowardGameCredit: number;
  pointsForGameCredit: number;
  dailyGoal: number;
  showOnboardingMenuItem: boolean;
  featureStudentOnboarding: boolean;
  allowHand: boolean;
  themeOption: ThemeOption;

  // All-time
  pointsEarned: number;
  totalDollarAttempts: number;
  oldest: string;
  teacherName: string;
  className: string;
  otherClasses: OtherClass[];

  replacementsUsedToday: number;
  replacementsPerDay: number;
  allowReplacementsWhenTeacherOnline: boolean;

  loginCount: number;
  hideWelcomeStrip: boolean;

  // Smileys
  effects: boolean;
  showTopScores: boolean;
  showClock: boolean;
  touchKeyboard: boolean;
  useNativeKeyboard: boolean;
  allowExamsOnMobile: boolean;
  teacherOnline: boolean;
  permitReadAloud: boolean;
  blockGames: boolean;
  blockGamesClass: boolean;
  classHighestToday: number;

  // User's choice, if the user ever made one
  userChoiceForShowSquares: boolean | undefined;
}

export interface BannerStore extends BannerStoreState {
  setIsHandRaised: (isHandRaised: boolean) => void;
  setPointsToday: (pointsToday: number) => void;
  setPointsThisWeek: (pointsThisWeek: number) => void;
  incrementPointsThisWeek: () => void;
  setGameCredits: (gameCredits: number) => void;
  setPointsTowardGameCredit: (pointsTowardGameCredit: number) => void;
  setPointsForGameCredit: (pointsForGameCredit: number) => void;
  setDailyGoal: (dailyGoal: number) => void;
  setShowOnboardingMenuItem: (showOnboardingMenuItem: boolean) => void;
  setAllowHand: (allowHand: boolean) => void;
  setThemeOption: (themeOption: ThemeOption) => void;
  setPointsEarned: (pointsEarned: number) => void;
  setTotalDollarAttempts: (totalDollarAttempts: number) => void;
  setOldest: (oldest: string) => void;
  setTeacherName: (teacherName: string) => void;
  setClassName: (className: string) => void;
  setOtherClasses: (otherClasses: OtherClass[]) => void;
  setReplacementsUsedToday: (replacementsUsedToday: number) => void;
  setReplacementsPerDay: (replacementsPerDay: number) => void;
  setAllowReplacementsWhenTeacherOnline: (
    allowReplacementsWhenTeacherOnline: boolean
  ) => void;
  setLoginCount: (loginCount: number) => void;
  setHideWelcomeStrip: (hideWelcomeStrip: boolean) => void;
  setFeatureStudentOnboarding: (featureStudentOnboarding: boolean) => void;
  setEffects: (effects: boolean) => void;
  setShowTopScores: (showTopScores: boolean) => void;
  setShowClock: (showClock: boolean) => void;
  setTouchKeyboard: (touchKeyboard: boolean) => void;
  setUseNativeKeyboard: (useNativeKeyboard: boolean) => void;
  setUserChoiceForShowSquares: (showSquares: boolean) => void;
  setAllowExamsOnMobile: (allowExamsOnMobile: boolean) => void;
  setTeacherOnline: (teacherOnline: boolean) => void;
  setPermitReadAloud: (permitReadAloud: boolean) => void;
  setBlockGames: (blockGames: boolean) => void;
  setBlockGamesClass: (BlockGamesClass: boolean) => void;
  setClassHighestToday: (classHighestToday: number) => void;
}

(window as any).__REDUX_DEVTOOLS_EXTENSION__;

export const bannerStore = create<BannerStore>()(
  devtools(
    immer(set => ({
      isHandRaised: false,
      pointsToday: 0,
      pointsThisWeek: 0,
      gameCredits: 0,
      pointsTowardGameCredit: 0,
      pointsForGameCredit: 0,
      dailyGoal: 0,
      allowHand: false,
      themeOption: DEFAULT_THEME_OPTION,
      showOnboardingMenuItem: false,
      pointsEarned: 0,
      totalDollarAttempts: 0,
      oldest: 'N/A',
      teacherName: '',
      className: '',
      otherClasses: [],
      replacementsUsedToday: 2,
      replacementsPerDay: 1,
      allowReplacementsWhenTeacherOnline: false,
      loginCount: 0,
      hideWelcomeStrip: false,
      featureStudentOnboarding: false,
      effects: true,
      showTopScores: false,
      showClock: false,
      touchKeyboard: false,
      useNativeKeyboard: false,
      userChoiceForShowSquares: undefined,
      allowExamsOnMobile: false,
      teacherOnline: false,
      permitReadAloud: false,
      blockGames: false,
      blockGamesClass: false,
      classHighestToday: 0,
      setIsHandRaised: (isHandRaised: boolean) => set({ isHandRaised }),
      setPointsToday: (pointsToday: number) => set({ pointsToday }),
      setPointsThisWeek: (pointsThisWeek: number) => set({ pointsThisWeek }),
      incrementPointsThisWeek: () =>
        set(state => ({ pointsThisWeek: state.pointsThisWeek + 1 })),
      setGameCredits: (gameCredits: number) => set({ gameCredits }),
      setPointsTowardGameCredit: (pointsTowardGameCredit: number) =>
        set({ pointsTowardGameCredit }),
      setPointsForGameCredit: (pointsForGameCredit: number) =>
        set({ pointsForGameCredit }),
      setDailyGoal: (dailyGoal: number) => set({ dailyGoal }),
      setAllowHand: (allowHand: boolean) => set({ allowHand }),
      setThemeOption: (themeOption: ThemeOption) => {
        if (!themeOptions.includes(themeOption)) {
          themeOption = DEFAULT_THEME_OPTION;
        }

        set({ themeOption });
      },
      setShowOnboardingMenuItem: (showOnboardingMenuItem: boolean) =>
        set({ showOnboardingMenuItem }),
      setPointsEarned: (pointsEarned: number) => set({ pointsEarned }),
      setTotalDollarAttempts: (totalDollarAttempts: number) =>
        set({ totalDollarAttempts }),
      setOldest: (oldest: string) => set({ oldest }),
      setTeacherName: (teacherName: string) => set({ teacherName }),
      setClassName: (className: string) => set({ className }),
      setOtherClasses: (otherClasses: OtherClass[]) => set({ otherClasses }),
      setReplacementsUsedToday: (replacementsUsedToday: number) =>
        set({ replacementsUsedToday }),
      setReplacementsPerDay: (replacementsPerDay: number) =>
        set({ replacementsPerDay }),
      setAllowReplacementsWhenTeacherOnline: (
        allowReplacementsWhenTeacherOnline: boolean
      ) => set({ allowReplacementsWhenTeacherOnline }),
      setLoginCount: (loginCount: number) => {
        set({
          loginCount,
          hideWelcomeStrip: loginCount > MAX_LOGINS_FOR_WELCOME,
        });
      },
      setHideWelcomeStrip: (hideWelcomeStrip: boolean) =>
        set({ hideWelcomeStrip }),
      setFeatureStudentOnboarding: (featureStudentOnboarding: boolean) =>
        set({ featureStudentOnboarding }),
      setEffects: (effects: boolean) => set({ effects }),
      setShowTopScores: (showTopScores: boolean) => set({ showTopScores }),
      setShowClock: (showClock: boolean) => set({ showClock }),
      setUseNativeKeyboard: (useNativeKeyboard: boolean) =>
        set({ useNativeKeyboard }),
      setTouchKeyboard: (touchKeyboard: boolean) => set({ touchKeyboard }),
      setUserChoiceForShowSquares: (userChoiceForShowSquares: boolean) =>
        set({ userChoiceForShowSquares }),
      setAllowExamsOnMobile: (allowExamsOnMobile: boolean) =>
        set({ allowExamsOnMobile }),
      setTeacherOnline: (teacherOnline: boolean) => set({ teacherOnline }),
      setPermitReadAloud: (permitReadAloud: boolean) => {
        set({ permitReadAloud });
        getGmm()?.setPermitReadAloud(permitReadAloud);
      },
      setBlockGames: (blockGames: boolean) => set({ blockGames }),
      setBlockGamesClass: (blockGamesClass: boolean) =>
        set({ blockGamesClass }),
      setClassHighestToday: (classHighestToday: number) =>
        set({ classHighestToday }),
    })),
    { name: 'Banner Store' }
  )
);

export const bannerState = (): BannerStore => bannerStore.getState();
