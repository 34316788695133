import { Point } from '../../index';

import { checkLineIntersection } from './checkLineIntersection';
import { pointsEqual } from './pointsEqual';
import { Line, Polygon } from './types';

/**
 * Works for any polygon, not just convex polygons.
 * @param bordersCountAsOutside - if point is exactly on the polygon's border, this parameter determines whether the point will be classified as 'inside' or 'outside'
 */
export function isPointInsidePolygon(
  point: Point,
  polygon: Polygon,
  onBorderCountsAsOutside = false
): boolean {
  // point is any javascript object that contains both x & y numeric parameters
  // polygon is array of points, properly sorted to form a polygon
  const pointVerticalLine: Line = [point, { x: point.x, y: point.y + 1 }];
  let higherIntersectionsCount = 0;
  let lowerIntersectionCount = 0;

  for (let i = 0; i < polygon.length; i++) {
    const polygonLine: Line = [polygon[i], polygon[(i + 1) % polygon.length]];
    const result = checkLineIntersection(pointVerticalLine, polygonLine);

    if (result.onLine2) {
      if (pointsEqual(point, result)) {
        return !onBorderCountsAsOutside;
      }

      if (result.y > point.y) {
        higherIntersectionsCount++;
      } else {
        lowerIntersectionCount++;
      }
    }
  }

  return higherIntersectionsCount % 2 != 0 && lowerIntersectionCount % 2 != 0;
}
