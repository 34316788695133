/* eslint-disable no-console */
const fontUrl = `https://${process.env.ROBOTO_GMM}`;

let fontsLoadedPromise: Promise<void>;

const getFontSource = (filename: string): string =>
  `local("Roboto-GMM"), url("${fontUrl}/${filename}.woff2") format("woff2"),  url("${fontUrl}/${filename}.woff") format("woff"), url("${fontUrl}/${filename}.ttf") format("truetype")`;

export const loadFonts = function (
  onError: (message: string, err: Error) => void = console.warn
): Promise<void> {
  if (fontsLoadedPromise) return fontsLoadedPromise;

  // Font information
  const fontRegular = new FontFace(
    'Roboto-GMM',
    getFontSource('Roboto-GMM-Regular')
  );
  const fontBold = new FontFace(
    'Roboto-GMM',
    getFontSource('Roboto-GMM-Bold'),
    { weight: 'bold' }
  );

  fontsLoadedPromise = Promise.all([fontBold.load(), fontRegular.load()])
    .then(function (fonts) {
      console.log('Font was loaded.');

      if (document.fonts) {
        for (let i = 0; i < fonts.length; i++) {
          document.fonts.add(fonts[i]);
        }
      }
    })
    .catch(function (reason) {
      onError(`Unable to load Roboto-GMM from ${fontUrl}`, new Error(reason));
    });

  return fontsLoadedPromise;
};
