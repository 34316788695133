import { axesModalStore } from './axesModal';
import { problemModalStore } from './problemModalStore';

interface UseProblemModalStore {
  isShowingAnyModal: boolean;
}

export const useProcessedProblemModalStore = (): UseProblemModalStore => {
  const [stackableDialogs, nativeInputLocation] = problemModalStore(state => [
    state.stackableDialogs,
    state.nativeInputLocation,
  ]);
  const axesModalShowing = axesModalStore(state => state.visible);

  const isShowingAnyModal =
    stackableDialogs.length > 0 || !!nativeInputLocation || axesModalShowing;

  return {
    isShowingAnyModal,
  };
};
