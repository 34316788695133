/* eslint-disable padding-line-between-statements */
import { ProblemCanvasOwner, buildProblemCanvasOwner } from '@gmm/problem';

import { problemContext, pubsub } from '../legacy';
import { Any } from '../types';

import { userState } from './userStore';
import { workState } from './workStore';

let apiUrl = '';

export interface State {
  apiUrl: string;
  crowning?: boolean;
  lastMsgId?: string;
  lastLoginTry?: number;
  lastPing: number;
  lastPingPolice?: number;
  pingPoliceId?: NodeJS.Timeout;
  nextMsgId: number;
  pingFail: number;
  pingId?: number;
  pingIDDebug: number;
  pingLevel: number;
  timeSeenStart?: Date;
  timeStuck?: number;
  gmm?: ProblemCanvasOwner;
  inactiveTimer?: NodeJS.Timeout;
  smileyTimeoutTimer?: NodeJS.Timeout;
  smileyIntervalTimer?: NodeJS.Timeout;
  snackbarMessage:
    | string
    | { autoHideDuration?: number | null; message: string };
}

type GameMaster = Any;

declare global {
  interface Window {
    // Can't be removed from global/document-level reference until we change
    // games from old js to ts and stop hot-loading them. Maybe never.
    gameMaster?: GameMaster;
    studentApp: State;
    dev: (start: boolean) => void;
  }
}

window.studentApp = {
  apiUrl: process.env.JAVA_URL ?? '',
  lastPing: new Date().getTime(),
  nextMsgId: 2,
  pingFail: 0,
  pingIDDebug: 0,
  pingLevel: 0,
  snackbarMessage: '',
};

export const getGuid = (): string => userState().guid;
export const getSicId = (): number => userState().sicId;
export const getSs = (): number => userState().redisSessionId;
export const getUserId = (): number => userState().userId;
export const getUsername = (): string => userState().userName;
export const getIsTest = (): boolean => workState().currentWork.type === 'EXAM';

export const getApiUrl = (): string => apiUrl;
export const setApiUrl = (value: string): void => {
  apiUrl = value;
};

export const getCrowning = (): State['crowning'] => window.studentApp.crowning;
export const setCrowning = (value: State['crowning']): void => {
  window.studentApp.crowning = value;
};

// The 'problemjs' object -- the one from the huge 'index.js' file
// (package '@gmm/problem, defined as ProblemCanvas in index.d.ts') --
// exposes an interface so that the student app can call its methods.
// For example, the student app can call receiveProblem.
let problemCanvasOwner: ProblemCanvasOwner | undefined;

export const getGmm = (): ProblemCanvasOwner => {
  if (!problemCanvasOwner) {
    problemCanvasOwner = buildProblemCanvasOwner({
      problemContext,
      isStudent: true,
    });
  }
  return problemCanvasOwner;
};

export const getInactiveTimer = (): State['inactiveTimer'] =>
  window.studentApp.inactiveTimer;
export const setInactiveTimer = (value: State['inactiveTimer']): void => {
  window.studentApp.inactiveTimer = value;
};

export const getLastMsgId = (): State['lastMsgId'] =>
  window.studentApp.lastMsgId;
export const setLastMsgId = (value: State['lastMsgId']): void => {
  window.studentApp.lastMsgId = value;
};

export const getLastLoginTry = (): State['lastLoginTry'] =>
  window.studentApp.lastLoginTry;
export const setLastLoginTry = (value: State['lastLoginTry']): void => {
  window.studentApp.lastLoginTry = value;
};

export const getLastPing = (): State['lastPing'] => window.studentApp.lastPing;
export const setLastPing = (value: State['lastPing']): void => {
  window.studentApp.lastPing = value;
};

export const getLastPingPolice = (): State['lastPingPolice'] =>
  window.studentApp.lastPingPolice;
export const setLastPingPolice = (value: State['lastPingPolice']): void => {
  window.studentApp.lastPingPolice = value;
};

export const getPingFail = (): State['pingFail'] => window.studentApp.pingFail;
export const setPingFail = (value: State['pingFail']): void => {
  window.studentApp.pingFail = value;
};

export const getPingId = (): State['pingId'] => window.studentApp.pingId;
export const setPingId = (value: State['pingId']): void => {
  window.studentApp.pingId = value;
};

export const getPingIDDebug = (): State['pingIDDebug'] =>
  window.studentApp.pingIDDebug;
export const setPingIDDebug = (value: State['pingIDDebug']): void => {
  window.studentApp.pingIDDebug = value;
};

export const getPingLevel = (): State['pingLevel'] =>
  window.studentApp.pingLevel;
export const setPingLevel = (value: State['pingLevel']): void => {
  window.studentApp.pingLevel = value;
};

export const getPingPoliceId = (): State['pingPoliceId'] =>
  window.studentApp.pingPoliceId;
export const setPingPoliceId = (value: State['pingPoliceId']): void => {
  window.studentApp.pingPoliceId = value;
};

export const getSnackbarMessage = (): State['snackbarMessage'] => {
  return window.studentApp.snackbarMessage;
};
export const setSnackbarMessage = (value: State['snackbarMessage']): void => {
  window.studentApp.snackbarMessage = value;
  pubsub.publish('snackbarMessage', value);
};

export const getSmileyIntervalTimer = (): State['smileyIntervalTimer'] =>
  window.studentApp.smileyIntervalTimer;
export const setSmileyIntervalTimer = (
  value: State['smileyIntervalTimer']
): void => {
  window.studentApp.smileyIntervalTimer = value;
};

export const getSmileyTimeoutTimer = (): State['smileyTimeoutTimer'] =>
  window.studentApp.smileyTimeoutTimer;
export const setSmileyTimeoutTimer = (
  value: State['smileyTimeoutTimer']
): void => {
  window.studentApp.smileyTimeoutTimer = value;
  pubsub.publish('smileyTimeoutTimer', value);
};

export const getTimeSeenStart = (): State['timeSeenStart'] =>
  window.studentApp.timeSeenStart;
export const setTimeSeenStart = (value: State['timeSeenStart']): void => {
  window.studentApp.timeSeenStart = value;
};

export const getTimeStuck = (): State['timeStuck'] =>
  window.studentApp.timeStuck;
export const setTimeStuck = (value: State['timeStuck']): void => {
  window.studentApp.timeStuck = value;
};
