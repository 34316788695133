import { FC } from 'react';

import { useProcessedProblems } from '../../../studentApp/hooks';
import { NormalProblem } from '../../../studentApp/stores';
import { NormalBox } from '../../../studentApp/views/student/boxes';
import { RegionLabel } from '../squares/regionLabel';

import { NeedHelpButton } from './needHelpButton';
import { ShowSquares } from './showSquares';

export const NormalTop: FC = () => {
  const problem = useProcessedProblems().currentProblem as
    | NormalProblem
    | undefined;

  if (!problem) return null;

  const text = 'Current Skill';
  const hideNeedHelp = problem.type === 'EXAM_CORRECTIONS';
  const penalties = problem.penalty || 0;
  const penaltyMessage =
    penalties === 0
      ? ''
      : `${penalties} ${penalties === 1 ? 'Penalty' : 'Penalties'}`;

  return (
    <>
      <ShowSquares />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RegionLabel text={text} />
        <div style={{ paddingLeft: '5px' }} />
        <NormalBox
          replica={true}
          key={problem.id}
          problem={problem as NormalProblem}
        />
        {penaltyMessage}
      </div>
      {!hideNeedHelp && <NeedHelpButton />}
      {/* placeholder to maintain even spread of three top elements */}
      {hideNeedHelp && <div style={{ marginLeft: 'auto' }} />}
    </>
  );
};
