export const READ_ALOUD_HIGHLIGHT_COLOR = '#FFD59A';
export const READ_ALOUD_HIGHLIGHT_COLOR_OVERLAY = '#FFAE3350';

export const HEIGHT = 20;
export const WIDTH = 22;

export const LOADING_INSET = 2;

export const ICON_HEIGHT = 18;
export const ICON_Y_INSET = 1;

export const SPEAKER_ICON_WIDTH = 12;
export const SPEAKER_ICON_X_INSET = 5;

export const STOP_ICON_WIDTH = 18;
export const STOP_ICON_X_INSET = 0;

export function getReadAloudButtonWidth(): number {
  return WIDTH;
}
