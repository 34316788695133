import { unstable_batchedUpdates } from 'react-dom';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { NO_WORK, NewWork, Work, mergeWork, stripWork } from '../types';
import { handleSevereError, logWork } from '../utils';

import { problemState, setProblems } from './problemStore';

interface WorkStoreState {
  requireStudentsToFinishDefaultAssignment: boolean;
  isClasDefaultWorkSpiralReview: boolean;

  currentWork: Work;
}

export interface WorkStore extends WorkStoreState {
  setRequireStudentsToFinishDefaultAssignment: (
    requireStudentsToFinishDefaultAssignment: boolean
  ) => void;
  setIsClasDefaultWorkSpiralReview: (
    isClasDefaultWorkSpiralReview: boolean
  ) => void;

  setCurrentWork: (work: Work) => void;
  updateCurrentWork: (work: Work) => void;
}

(window as any).__REDUX_DEVTOOLS_EXTENSION__;

export const workStore = create<WorkStore>()(
  devtools(
    immer((set, get) => ({
      isClasDefaultWorkSpiralReview: false,
      currentWork: NO_WORK,
      requireStudentsToFinishDefaultAssignment: false,
      setRequireStudentsToFinishDefaultAssignment: (
        requireStudentsToFinishDefaultAssignment: boolean
      ) => set({ requireStudentsToFinishDefaultAssignment }),
      setIsClasDefaultWorkSpiralReview: (
        isClasDefaultWorkSpiralReview: boolean
      ) => set({ isClasDefaultWorkSpiralReview }),
      setCurrentWork: (work: Work) => {
        if (work.type === 'NONE') problemState().clear();
        set({ currentWork: stripWork(work) });
        logWork('workStore.setCurrentWork (no problem data involved)');
      },
      updateCurrentWork: (update: Work) => {
        const currentWork = get().currentWork;

        set({ currentWork: mergeWork(currentWork, update) });
        logWork('workStore.updateCurrentWork (no problem data involved)');
      },
    })),
    { name: 'Work Store' }
  )
);

export const workState = (): WorkStore => workStore.getState();

export function newWork(data?: NewWork): void {
  if (!data) return;

  // Ideally, severeError would not be bundled in NewWork
  if (data.severeError) {
    handleSevereError(data.severeError);

    return;
  }

  unstable_batchedUpdates(() => {
    workState().setCurrentWork(data as Work);
    setProblems(data);
  });
}
