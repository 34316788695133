import { Point } from '../../index';

import { Polygon } from './types';

export function justAfterPointOfACrossPoint(
  pointIndex: number,
  polygon: Polygon
): Point {
  const point = polygon[pointIndex];
  const nextIndex = (pointIndex + 1) % polygon.length;
  const nextPoint = polygon[nextIndex];

  const pointJustAfterCurrent = {
    x: (point.x + nextPoint.x) / 2,
    y: (point.y + nextPoint.y) / 2,
  };

  return pointJustAfterCurrent;
}
