import { FC } from 'react';

import { useProcessedWorkStore } from '../../studentApp/hooks';

import { ExamButtons } from './banner/examButtons';
import { MobileBanner } from './banner/mobileBanner';
import { MobilePointsAndGameCredits } from './banner/pointsAndGameCredits';
import { SquaresAndProblem } from './squares/squaresAndProblem';

export const MobileLayout: FC = () => {
  const isTesting = useProcessedWorkStore().isTesting;

  return (
    <>
      <SquaresAndProblem />
      <div className="sticky-banner">
        {!isTesting && <MobilePointsAndGameCredits />}
        <MobileBanner />
        {isTesting && <ExamButtons />}
      </div>
    </>
  );
};
