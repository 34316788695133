import { FC } from 'react';

import {
  useProcessedProblems,
  useProcessedWorkStore,
} from '../../../studentApp/hooks';
import { squareGroupsStyle } from '../util/constants';

import { LabelAndSquares } from './labelAndSquares';
import { RegionLabel } from './regionLabel';

export const AssignedRegion: FC = () => {
  const progress = useProcessedWorkStore().assignedProgress;
  const { assignedSkills } = useProcessedProblems();
  const { dateAssigned } = useProcessedWorkStore();

  return (
    <>
      <RegionLabel
        text={'Targeted Skills'}
        score={progress}
        id="newSkillProgress"
      />
      <div
        className="scrollable"
        style={{ ...squareGroupsStyle, maxHeight: '82px' }}
      >
        <LabelAndSquares
          text={dateAssigned}
          dateOrdinalIndicator={getDateOrdinalIndicator(
            new Date(dateAssigned).getDate()
          )}
          problems={assignedSkills}
        />
      </div>
    </>
  );
};

const getDateOrdinalIndicator = (day: number): string => {
  // 1, 21, 31: st
  // 2, 22: nd
  // 3, 23: rd
  // 4-20, 24-30: th
  const ret =
    day % 10 === 1 && day !== 11
      ? 'st'
      : day === 2 || day === 22
      ? 'nd'
      : day === 3 || day === 23
      ? 'rd'
      : 'th';

  return ret;
};
