type AlOptions = { a: number; l: number; x?: never; y?: never };
type XyOptions = { a?: never; l?: never; x: number; y: number };
type VectorOptions = XyOptions | AlOptions;

const isXyOptions = (opts: VectorOptions): opts is XyOptions =>
  typeof opts.x !== 'undefined' && typeof opts.y !== 'undefined';

class Vector {
  x = 0;
  y = 0;
  l = 1;
  a = 0;

  constructor(opts: VectorOptions) {
    if (isXyOptions(opts)) {
      this.x = opts.x;
      this.y = opts.y;
      this.cAL();
    } else {
      this.l = opts.l;
      this.a = opts.a;
    }
  }

  cAL(): void {
    if (this.x === 0 && this.y === 0) {
      this.a = 0;
    } else if (this.x === 0) {
      if (this.y > 0) {
        this.a = Math.PI / 2;
      } else {
        this.a = (3 * Math.PI) / 2;
      }
    } else if (this.y === 0) {
      if (this.x > 0) {
        this.a = 0;
      } else {
        this.a = Math.PI;
      }
    } else {
      this.a = Math.atan(this.y / this.x);
      if (this.x < 0) this.a += Math.PI;
    }
  }

  cXY(): void {
    this.x = this.l * Math.cos(this.a);
    this.y = this.l * Math.sin(this.a);
  }

  sL(l: number): void {
    this.l = l;
    this.cXY();
  }

  iA(d: number): void {
    this.a += d;

    if (this.a >= 2 * Math.PI) {
      const numberOf2Pis = Math.floor(this.a / (2 * Math.PI));

      this.a -= numberOf2Pis * 2 * Math.PI;
    }

    if (this.a < 0) {
      const numberOf2Pis = Math.floor(this.a / (2 * Math.PI));

      this.a += (numberOf2Pis + 1) * 2 * Math.PI;
    }

    this.cXY();
  }
}

export function arrow(
  ctx: CanvasRenderingContext2D,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  size?: 'small' | 'smaller'
): void {
  const len = !size ? 20 : size === 'small' ? 14 : 10;
  const vector = new Vector({ x: x1 - x2, y: y1 - y2 });

  vector.sL(len);
  vector.iA(0.7);

  const x3 = x2 + vector.x;
  const y3 = y2 + vector.y;

  vector.iA(-1.4);
  const x4 = x2 + vector.x;
  const y4 = y2 + vector.y;

  ctx.moveTo(x3, y3);
  ctx.lineTo(x2, y2);
  ctx.lineTo(x4, y4);
}

export function filledArrow(
  ctx: CanvasRenderingContext2D,
  fromX: number,
  fromY: number,
  toX: number,
  toy: number,
  r: number
): void {
  const x_center = toX;
  const y_center = toy;
  let angle, x, y;

  ctx.beginPath();

  angle = Math.atan2(toy - fromY, toX - fromX);
  x = r * Math.cos(angle) + x_center;
  y = r * Math.sin(angle) + y_center;

  ctx.moveTo(x, y);

  angle += (1 / 3) * (2 * Math.PI);
  x = r * Math.cos(angle) + x_center;
  y = r * Math.sin(angle) + y_center;

  ctx.lineTo(x, y);

  angle += (1 / 3) * (2 * Math.PI);
  x = r * Math.cos(angle) + x_center;
  y = r * Math.sin(angle) + y_center;

  ctx.lineTo(x, y);

  ctx.closePath();
  ctx.fill();
}
