import { CSSProperties, FC } from 'react';

import { Divider } from '../util/constants';
import { TextOnCanvas } from '../util/textOnCanvas';

const regionLabelStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  fontSize: '16px',
  fontWeight: 800,
  height: '48px',
  minHeight: '48px',
};

interface RegionLabelProps {
  text: string;
  score?: string;
  id?: string;
}

export const RegionLabel: FC<RegionLabelProps> = ({ text, score, id }) => {
  return (
    <div style={regionLabelStyle}>
      {text}
      {score && (
        <>
          <Divider />
          <TextOnCanvas text={score} id={id} />
        </>
      )}
    </div>
  );
};
