import { WorkType } from '../../types';

import { sendObject } from './handler';

export type SwitchToSpiralReview = {
  type: 'chooseAssignment';
  switchToMR: boolean;
  workType: WorkType;
};

export const postSwitchToSpiralReview = async (): Promise<void> =>
  sendObject({
    type: 'chooseAssignment',
    switchToMR: true,
    workType: 'SPIRAL_REVIEW',
  });
