import { lineFont } from '../state';
import { drawImage, getImage } from '../utils';

export function xyz(
  // plusminus and arrows unused
  use:
    | 'xyz'
    | 'abc'
    | '#'
    | 'plusminus'
    | 'arrows'
    | 'xsquared'
    | 'submit'
    | 'blank',
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  onLoad: () => void,
  animated = false,
  animatedColor: string,
  side: number,
  disabled = false,
  noFill = false,
  fontScalar?: number
): void {
  fontScalar = fontScalar || 1;
  const w = side || 30;
  const h = side || 30;
  const r = 8;

  if (!disabled) {
    ctx.save();
    ctx.fillStyle = animated ? animatedColor : 'lightgray';
    ctx.lineWidth = 2;
    ctx.strokeStyle = 'lightgray';
    ctx.beginPath();
    ctx.moveTo(x + r, y);
    ctx.arcTo(x + w, y, x + w, y + h, r);
    ctx.arcTo(x + w, y + h, x, y + h, r);
    ctx.arcTo(x, y + h, x, y, r);
    ctx.arcTo(x, y, x + w, y, r);
    ctx.closePath();

    if (!noFill) ctx.fill();
    else {
      ctx.stroke();
    }

    ctx.restore();
  }

  ctx.save();

  ctx.beginPath();
  ctx.fillStyle = animated ? 'white' : 'gray';

  if (use == 'xyz' || use == 'abc') {
    ctx.textBaseline = 'top';
    ctx.font = 12 * fontScalar + 'px ' + lineFont;

    if (use == 'xyz') {
      x += 5;
      y += 2;

      ctx.fillText('X', x * fontScalar, y * fontScalar);
      ctx.fillText('Y', (x + 6) * fontScalar, (y + 6) * fontScalar);
      ctx.fillText('Z', (x + 12) * fontScalar, (y + 12) * fontScalar);
    } else if (use == 'abc') {
      x += 5;
      y += 2;

      ctx.fillText('A', x * fontScalar, y * fontScalar);
      ctx.fillText('B', (x + 6) * fontScalar, (y + 6) * fontScalar);
      ctx.fillText('C', (x + 12) * fontScalar, (y + 12) * fontScalar);
    }
  } else if (use == '#') {
    ctx.textBaseline = 'top';
    ctx.font = 22 * fontScalar + 'px ' + lineFont;
    x += 9;
    y += 2;
    ctx.fillText('#', x * fontScalar, y * fontScalar);
  } else if (use == 'plusminus') {
    ctx.textBaseline = 'top';
    ctx.font = 22 * fontScalar + 'px ' + lineFont;
    x += 9;
    ctx.fillText('\u00B1', x, y);
  } else if (use == 'arrows') {
    ctx.lineWidth = 1;
    ctx.strokeStyle = ctx.fillStyle;
    ctx.beginPath();
    ctx.moveTo(0.15 * w, 0.5 * h);
    ctx.lineTo(0.35 * w, 0.3 * h);
    ctx.moveTo(0.15 * w, 0.5 * h);
    ctx.lineTo(0.35 * w, 0.7 * h);

    ctx.moveTo(0.15 * w, 0.5 * h);
    ctx.lineTo(0.85 * w, 0.5 * h);
    ctx.lineTo(0.65 * w, 0.3 * h);
    ctx.moveTo(0.85 * w, 0.5 * h);
    ctx.lineTo(0.65 * w, 0.7 * h);

    ctx.closePath();
    ctx.stroke();
  } else if (use == 'xsquared') {
    ctx.textBaseline = 'top';
    ctx.font = 16 * fontScalar + 'px ' + lineFont;
    x += 6;
    y += 10;
    ctx.fillText('X', x * fontScalar, y * fontScalar);
    ctx.font = 12 * fontScalar + 'px ' + lineFont;
    x += 12;
    y -= 8;
    ctx.fillText('2', x * fontScalar, y * fontScalar);
  } else if (use == 'submit') {
    const i = getImage(disabled ? 'checklight' : 'check', onLoad);

    drawImage(ctx, i, 0.15 * side, 0.185 * side, 0.7 * side, 0.63 * side);
  }

  ctx.restore();
}
