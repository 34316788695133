import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { pubsub } from '../legacy';

// Functions, null, boolean, string, number, undefined
type NonObject =
  | ((...args: any) => any)
  | null
  | boolean
  | string
  | number
  | undefined;

export type Writable<T> = T extends NonObject
  ? T
  : { -readonly [P in keyof T]: Writable<T[P]> };

export const useSubscription = <T extends unknown[]>(
  event: string,
  initialData: T | (() => T)
): Writable<T> => {
  const [data, setData] = useState<Writable<T>>(initialData as Writable<T>);

  useEffect(() => {
    return pubsub.subscribe<Writable<T>>(event, (...args) =>
      setData(cloneDeep(args))
    ).unsubscribe;
  }, []);

  return data;
};
