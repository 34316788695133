import { studentAppModalStore } from '../stores/studentAppModalStore';
import { workStore } from '../stores/workStore';
import { Work, WorkType } from '../types';

interface UseWorkStore {
  isTesting: boolean;
  currentWorkType: WorkType;
  currentWorkId?: number;
  currentWorkName: string;
  currentWorkProgress: string;
  assignedProgress: string;
  srProgress: string;
  examErrorsFixedProgress: string;
  examPracticeProgress: string;
  practiceRequired: number;
  trulyAvailableWork: Work[];
  hasWorkChoices: boolean;
  dateAssigned: string;
}

export const useProcessedWorkStore = (): UseWorkStore => {
  const [work, requireStudentsToFinishDefaultAssignment] = workStore(state => [
    state.currentWork,
    state.requireStudentsToFinishDefaultAssignment,
  ]);
  const availableWork = studentAppModalStore(state => state.availableWork);
  const isTesting = getType(work) === 'EXAM';
  const currentWorkType = getType(work);
  const currentWorkId = work.workId;
  const currentWorkName = getWorkName(work);

  const currentWorkProgress = getWorkProgress(work);

  const assignedProgress = work.newSkillRequired
    ? work.newSkillPoints + ' of ' + work.newSkillRequired
    : 'N/A';
  const srProgress = work.spiralReviewRequired
    ? work.spiralReviewPoints + ' of ' + work.spiralReviewRequired
    : 'N/A';
  const examErrorsFixedProgress = work.errorsRequired
    ? work.errorsFixed + ' of ' + work.errorsRequired
    : 'N/A';
  const examPracticeProgress = work.practiceRequired
    ? work.practicePoints + ' of ' + work.practiceRequired
    : 'N/A';
  const practiceRequired = work.practiceRequired || 0;

  // Server sends some 'availableWork' that's not available
  const trulyAvailableWork = availableWork.filter(work => {
    return (
      work.workId !== currentWorkId &&
      work.available !== false &&
      work.points! <= work.required!
    );
  });

  // CORRECTIONS: let them see choice of SR, even if perhaps it is empty
  // because teacher has never assigned anything
  let hasWorkChoices = true;

  switch (currentWorkType) {
    case 'ASSIGNMENT': {
      const lockedIntoDefaultAssignment =
        work.defaultAssignment && requireStudentsToFinishDefaultAssignment;

      hasWorkChoices = !lockedIntoDefaultAssignment;
      break;
    }

    case 'SPIRAL_REVIEW':
      hasWorkChoices = trulyAvailableWork.length > 0;
  }

  const dateAssigned = !work.dateAssigned
    ? ''
    : getFormattedDate(work.dateAssigned);

  return {
    isTesting,
    currentWorkType,
    currentWorkId,
    currentWorkName,
    currentWorkProgress,
    assignedProgress,
    srProgress,
    examErrorsFixedProgress,
    examPracticeProgress,
    practiceRequired,
    trulyAvailableWork,
    hasWorkChoices,
    dateAssigned,
  };
};

export const getWorkProgress = (work: Work): string => {
  if (!work) return 'undefined';

  return getType(work) === 'ASSIGNMENT' || getType(work) === 'CORRECTIONS'
    ? getEarned(work) + ' of ' + getRequired(work)
    : 'N/A';
};

export const getWorkName = (work: Work): string => {
  if (!work) return 'undefined';
  const prependCorrections =
    getType(work) === 'CORRECTIONS' ? 'Corrections: ' : '';

  return prependCorrections + work.name;
};

export const getEarned = (work: Work): number => {
  if (getType(work) === 'ASSIGNMENT') {
    return (work.newSkillPoints || 0) + (work.spiralReviewPoints || 0);
  } else if (getType(work) === 'CORRECTIONS') {
    return (work.errorsFixed || 0) + (work.practicePoints || 0);
  }

  throw new Error('Invalid work type for getEarned');
};

export const getRequired = (work: Work): number => {
  if (getType(work) === 'ASSIGNMENT') {
    return (work.newSkillRequired || 0) + (work.spiralReviewRequired || 0);
  } else if (getType(work) === 'CORRECTIONS') {
    return (work.errorsRequired || 0) + (work.practiceRequired || 0);
  }

  throw new Error('Invalid work type for getRequired');
};

// sometimes we get type, sometimes we get workType
export const getType = (work: Work): WorkType => {
  return work.type || work.workType;
};

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const getFormattedDate = (timestamp: string): string => {
  const date = new Date(timestamp);
  const month = monthNames[date.getMonth()];
  const day = date.getDate();

  return `${month} ${day}`;
};
