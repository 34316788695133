import { styled } from '@mui/material';
import { CSSProperties, FC } from 'react';

import { DragZone } from '@gmm/problem';

import { useProcessedBannerStore } from '../../../studentApp/hooks';
import {
  BOX_SHADOW,
  LABEL_AND_SQUARES_FIRST_BACKCOLOR,
  LABEL_AND_SQUARES_SECOND_BACKCOLOR,
} from '../../constants';

export const Space: FC = () => {
  return <span>&nbsp;</span>;
};

const DEFAULT_DIVIDER_MARGIN = 7;
const DEFAULT_DIVIDER_FONT_SIZE = 16;

interface DividerProps {
  fontSize?: number;
  margin?: number;
}

export const Divider: FC<DividerProps> = ({
  fontSize = DEFAULT_DIVIDER_FONT_SIZE,
  margin = DEFAULT_DIVIDER_MARGIN,
}) => {
  return (
    <span
      style={{
        fontSize: fontSize + 'px',
        fontWeight: 400,
        margin: `0px ${margin}px 0px ${margin}px`,
      }}
    >
      |
    </span>
  );
};

const StyledDraggableTop = styled('div')(({ color }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: '25px',
  color: 'white',
  backgroundColor: color,
}));

interface DraggableTopProps {
  dragHandle: string;
  toggleCollapse: () => void;
  close: () => void;
  isCollapsed: boolean;
  backgroundColor?: string;
  minimizedMessage?: string;
  messageColor?: string;
}

export const DraggableTop: FC<DraggableTopProps> = props => {
  return (
    <StyledDraggableTop>
      <CollapseIcon
        toggleCollapse={props.toggleCollapse}
        collapsed={props.isCollapsed}
      />
      <DragZone
        dragHandle={props.dragHandle}
        collapsed={props.isCollapsed}
        collapsedMessage={props.minimizedMessage}
        color={props.messageColor}
      />
      <CloseX close={props.close} />
    </StyledDraggableTop>
  );
};

interface CollapseProps {
  toggleCollapse: () => void;
  collapsed: boolean;
}

const CollapseIcon: FC<CollapseProps> = ({ toggleCollapse, collapsed }) => {
  return (
    <img
      onClick={toggleCollapse}
      src={
        collapsed
          ? 'GMM-icons/GMM-icon-expand.svg'
          : 'GMM-icons/GMM-icon-collapse.svg'
      }
      style={collapseStyle}
    />
  );
};

const collapseStyle: CSSProperties = {
  width: '18px',
  padding: '0px 5px 0px 5px',
  cursor: 'pointer',
};

interface CloseXProps {
  close: () => void;
}

const CloseX: FC<CloseXProps> = ({ close }) => {
  return (
    <span onClick={close} style={closeXStyle}>
      &times;
    </span>
  );
};

const closeXStyle: CSSProperties = {
  padding: '1px 5px 0px 5px',
  fontWeight: 'bold',
  fontSize: '20px',
  cursor: 'pointer',
  color: 'white',
};

interface ButtonProps {
  onClick: () => void;
  text: string;
  rightSide?: boolean;
  width?: number;
  disabled?: boolean;
}

export const Button: FC<ButtonProps> = ({
  onClick,
  text,
  rightSide,
  width,
  disabled,
}) => {
  const themeColor = useProcessedBannerStore().themeColor;

  return (
    <div
      onClick={onClick}
      style={{
        ...buttonStyle,
        backgroundColor: disabled ? 'lightgray' : themeColor,
        marginLeft: rightSide ? 'auto' : undefined,
        width: width ? `${width}px` : buttonStyle.width,
        cursor: disabled ? 'default' : 'pointer',
      }}
    >
      {text}
    </div>
  );
};

export const buttonStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '24px',
  width: '114px',
  fontSize: '12px',
  fontWeight: 700,
  color: 'white',
  backgroundColor: '#5c934e',
  borderRadius: '20px',
  boxShadow: BOX_SHADOW,
  cursor: 'pointer',
};

export const RowDivider: FC = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '1px',
        backgroundColor: 'lightgray',
      }}
    ></div>
  );
};

export const squareGroupsStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'left',
  minHeight: '41px',
  width: '100%',
  background: `linear-gradient(to right, 
    ${LABEL_AND_SQUARES_FIRST_BACKCOLOR} 0px, 
    ${LABEL_AND_SQUARES_FIRST_BACKCOLOR} 75px, 
    ${LABEL_AND_SQUARES_SECOND_BACKCOLOR} 75px, 
    ${LABEL_AND_SQUARES_SECOND_BACKCOLOR} 100%)`,
  backgroundSize: '100% 100%',
  boxShadow: BOX_SHADOW,
  gap: '5px',
};
