import { BasicObject } from './basicObject';

// Tracks whether there is currently anything 'floating' on top of everything else.
// Except... not including dialogs. So really, only popup menus painted on the canvas.
class FloaterManager {
  panel?: BasicObject;
  preserveOnClick = false;

  paint(ctx: CanvasRenderingContext2D): void {
    this.panel?.paint(ctx);
  }

  mouseDown(x: number, y: number, paintCanvas: () => void): boolean {
    if (!this.panel) return false;
    this.panel.mouseDown(x, y, paintCanvas);

    if (!this.preserveOnClick) {
      this.setPanel(undefined);
    }

    this.setPreserve(false);

    return true;
  }

  setPanel(panel: BasicObject | undefined): void {
    this.panel = panel;
  }

  hasPanel(): boolean {
    return this.panel != undefined;
  }

  clear(): void {
    this.setPanel(undefined);
    this.setPreserve(false);
  }

  setPreserve(b: boolean): void {
    this.preserveOnClick = b;
  }

  setLocation(viewportX: number, viewportY: number): void {
    if (!this.panel) return;
    this.panel.viewportX = viewportX;
    this.panel.viewportY = viewportY;
  }
}

export const floater = new FloaterManager();
