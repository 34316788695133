// This method is used to fix malformed bases prior to sending values along to server.
// For examples, it fixes the following:
// 2x^2 was sometimes incorrectly parsed as (2x)^2.
// xyz^2 was sometimes incorrectly parsed as (xyz)^2.
// x10^2 was sometimes incorrectly parsed as (x10)^2.
export function baseBugPatch(line: string): string {
  if (!line) return line;

  const original = line;

  try {
    let i = line.indexOf('<p><b><t>');

    if (i < 0) return line;
    let ret = '';
    let buggy = false;

    while (i > -1) {
      ret += line.substring(0, i);
      line = line.substring(i);
      i = line.indexOf('</t>');
      const chunk = line.substring('<p><b><t>'.length, i);

      line = line.substring(i + '</t>'.length);

      // fully textual base should only have one character
      if (chunk.length > 1 && !$.isNumeric(chunk)) {
        buggy = true;

        let removeFromBase;
        let keepInBase;

        const lastChar = chunk.substring(chunk.length - 1);

        if ($.isNumeric(lastChar)) {
          let nextCharIndex = chunk.length - 2;

          while (
            nextCharIndex >= 0 &&
            $.isNumeric(chunk.substring(nextCharIndex))
          ) {
            nextCharIndex--;
          }

          removeFromBase = chunk.substring(0, nextCharIndex + 1);
          keepInBase = chunk.substring(nextCharIndex + 1);
        } else {
          // all chars except final char should be in prior text element
          removeFromBase = chunk.substring(0, chunk.length - 1);
          keepInBase = chunk.substring(chunk.length - 1);
        }

        // if that element already exists, add to it
        if (ret.length >= 4 && ret.lastIndexOf('</t>') === ret.length - 4) {
          ret = ret.substring(0, ret.length - 4) + removeFromBase + '</t>';
        }
        // otherwise, create new text element
        else {
          ret += '<t>' + removeFromBase + '</t>';
        }

        ret += '<p><b><t>' + keepInBase + '</t>';
      } else {
        ret += '<p><b><t>' + chunk + '</t>';
      }

      i = line.indexOf('<p><b><t>');
    }

    ret += line;

    return buggy ? ret : original;
  } catch (err) {}

  return original;
}
