import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { GridGetter } from '../../getters';

let gridGetter: GridGetter | undefined;

export const setAxesModalGridGetter = (
  gGetter: GridGetter | undefined
): void => {
  gridGetter = gGetter;
};

interface AxesModalState {
  visible: boolean;
  xScale: number;
  yScale: number;
  showLabelPickers: boolean;
  xLabel: string;
  yLabel: string;
  labelChoices: string[];
  xScaleChoicesShowing: boolean;
  yScaleChoicesShowing: boolean;
  xLabelChoicesShowing: boolean;
  yLabelChoicesShowing: boolean;
}

export interface AxesModalStore extends AxesModalState {
  setVisible: (visible: boolean) => void;
  setXScale: (xScale: number) => void;
  setYScale: (yScale: number) => void;
  setShowLabelPickers: (showAxisLabelPickers: boolean) => void;
  setXLabel: (xLabel: string) => void;
  setYLabel: (yLabel: string) => void;
  setLabelChoices: (labelChoices: string[]) => void;
  setXScaleChoicesShowing: (xScaleChoicesShowing: boolean) => void;
  setYScaleChoicesShowing: (yScaleChoicesShowing: boolean) => void;
  setXLabelChoicesShowing: (xLabelChoicesShowing: boolean) => void;
  setYLabelChoicesShowing: (yLabelChoicesShowing: boolean) => void;
}

(window as any).__REDUX_DEVTOOLS_EXTENSION__;

export const axesModalStore = create<AxesModalStore>()(
  devtools(
    immer(set => ({
      xScale: 1,
      yScale: 1,
      showLabelPickers: false,
      xLabel: '',
      yLabel: '',
      visible: false,
      labelChoices: [],
      xScaleChoicesShowing: false,
      yScaleChoicesShowing: false,
      xLabelChoicesShowing: false,
      yLabelChoicesShowing: false,
      setXScale: xScale => {
        set({ xScale });
        gridGetter?.updateFromGridDialog();
      },
      setYScale: yScale => {
        set({ yScale });
        gridGetter?.updateFromGridDialog();
      },
      setShowLabelPickers: showLabelPickers => set({ showLabelPickers }),
      setXLabel: xLabel => {
        set({ xLabel });
        gridGetter?.updateFromGridDialog();
      },
      setYLabel: yLabel => {
        set({ yLabel });
        gridGetter?.updateFromGridDialog();
      },
      setVisible: visible => {
        set({
          xScaleChoicesShowing: false,
          yScaleChoicesShowing: false,
          xLabelChoicesShowing: false,
          yLabelChoicesShowing: false,
        });
        if (!visible) setAxesModalGridGetter(undefined);

        set({ visible });
      },
      setLabelChoices: labelChoices => set({ labelChoices }),
      setXScaleChoicesShowing: xScaleChoicesShowing => {
        if (xScaleChoicesShowing) {
          set({
            yScaleChoicesShowing: false,
            xLabelChoicesShowing: false,
            yLabelChoicesShowing: false,
          });
        }

        set({ xScaleChoicesShowing });
      },
      setYScaleChoicesShowing: yScaleChoicesShowing => {
        if (yScaleChoicesShowing) {
          set({
            xScaleChoicesShowing: false,
            xLabelChoicesShowing: false,
            yLabelChoicesShowing: false,
          });
        }

        set({ yScaleChoicesShowing });
      },
      setXLabelChoicesShowing: xLabelChoicesShowing => {
        if (xLabelChoicesShowing) {
          set({
            xScaleChoicesShowing: false,
            yScaleChoicesShowing: false,
            yLabelChoicesShowing: false,
          });
        }

        set({ xLabelChoicesShowing });
      },
      setYLabelChoicesShowing: yLabelChoicesShowing => {
        if (yLabelChoicesShowing) {
          set({
            xScaleChoicesShowing: false,
            yScaleChoicesShowing: false,
            xLabelChoicesShowing: false,
          });
        }

        set({ yLabelChoicesShowing });
      },
    })),
    { name: 'Axes Modal' }
  )
);

export const axesModalState = (): AxesModalStore => axesModalStore.getState();
