type DrawImageArgs = [HTMLImageElement, number, number, number, number];

export const drawImage = (
  ctx: CanvasRenderingContext2D,
  ...drawImageArgs: DrawImageArgs
): void => {
  if (typeof process !== 'undefined' && process.release.name === 'node') return;

  ctx.drawImage(...drawImageArgs);
};
