export const MIN_PORTRAIT_WIDTH = 802;
export const MIN_LANDSCAPE_WIDTH = 1190;
export const MAX_PORTRAIT_WIDTH = MIN_LANDSCAPE_WIDTH - 1;

// problemRegion should be at least 65% of the screen width (ceiling of 762/1190)
// when in landscape mode to ensure that the problem region is at least 762px wide
// Note: 762 comes from PROBLEM_WIDTH in problemCanvasConstants.ts
export const LANDSCAPE_PROBLEM_WIDTH_PERCENTAGE = 65;
export const LANDSCAPE_SQUARES_WIDTH_PERCENTAGE =
  100 - LANDSCAPE_PROBLEM_WIDTH_PERCENTAGE;

export const GRAY = '#7B7B7B';
export const FLOATER_BLUE_BACKGROUND = '#33a4c6';
export const LABEL_AND_SQUARES_FIRST_BACKCOLOR = '#e9e9e9';
export const LABEL_AND_SQUARES_SECOND_BACKCOLOR = '#f8f8f8';

export const POINTS_TODAY_FONT_SIZE = 14;
export const POINTS_TODAY_FONT = POINTS_TODAY_FONT_SIZE + 'px inter, arial';
export const POINTS_TODAY_BIG_FONT_SIZE = 20;

export type Layout = 'mobile' | 'portrait' | 'landscape';

export const BOX_SHADOW = '2px 2px 2px rgba(0, 0, 0, 0.3)';
export const LIST_ITEM_COLOR = '#337ab7';

export const BANNER_HEIGHT = 53;
export const POINTS_AND_CREDITS_HEIGHT = 30;
export const EXAM_BUTTONS_HEIGHT = 36;
export const WELCOME_STRIP_HEIGHT = 30;

export const MAX_LANDSCAPE_BANNER_WORK_NAME_WIDTH = 550;
export const MAX_LANDSCAPE_BANNER_STUDENT_NAME_AND_CLASS_WIDTH = 220;
export const MAX_PORTRAIT_BANNER_WORK_NAME_WIDTH = 280;
export const MAX_PORTRAIT_BANNER_STUDENT_NAME_AND_CLASS_WIDTH = 130;

export const MOBILE_MARGIN = '10px';

export const TEXT_COLOR = '#7b7b7b';

export const GETTING_STARTED_VIDEO_URL = 'https://vimeo.com/995544581';
