import { circle } from '../renderers';
import { BasicObject } from '../uiObjects';

// legacy naming for status
// 'c' = correct, not a test
// 'wr' = wrong, not a test
// 'u' = unsubmitted, not a test
// 'ready' = testing, contains valid attempt
// 'invalid' = testing, does not contain valid attempt
export type AttemptGetterStatus = 'c' | 'wr' | 'u' | 'ready' | 'invalid';

// status of 'non-Exam' Getter
type CorrectStatus = 'correct' | 'wrong' | 'untried';

export type AttemptGetterType =
  | 'n'
  | 'normal'
  | 't'
  | 'table'
  | 'nag'
  | 'numberLine'
  | 'g'
  | 'grid'
  | 'm'
  | 'multipleChoice'
  | 'valueTable'
  | 'multiSelect'
  | 'matchTableGrid'
  | 'inlineChoice'
  | 'dragDrop'
  | 'hotSpot';

export interface BaseDTO {
  // 'correct' and 'validAttempt' get translated into a legacy AttemptGetterStatus during index.js.buildGetterH
  correct: 'correct' | 'wrong' | 'untried' | undefined; // result of prior submission, only present if not test, 'untried' if not test and never had valid attempt.
  validAttempt: true | false | undefined; // whether current state is 'turn-in-able' for test, only provided if test
  t: AttemptGetterType;
  agId: number; // attempt getter id
  selectedButton?: string;
}

export const STATUS_DIAMETER = 15;
const STATUS_READY_COLOR = 'cyan';
const STATUS_CORRECT_COLOR = 'green';
const STATUS_WRONG_COLOR = 'magenta';

export abstract class AttemptGetter extends BasicObject {
  VERTICAL_SPACE_AFTER_PROMPT = 12;
  VERTICAL_SPACE_BEFORE_SUBMIT_BUTTON = 20;

  changeMaybeHandler: any;

  st: AttemptGetterStatus;

  // attemptGetterId
  agId: number;

  // legacy naming for enabled.
  // Target for eventual replacement with isEnabled based on current value of st
  e = false;

  t: AttemptGetterType;

  dto: BaseDTO;

  constructor(
    dto: BaseDTO,
    status: AttemptGetterStatus,
    changeMaybeHandler: any,
    x?: number,
    y?: number
  ) {
    super(x || 0, y || 0);
    this.changeMaybeHandler = changeMaybeHandler;
    this.t = dto.t;
    this.dto = dto;
    this.st = status;
    this.agId = dto.agId;
    this.gmmName = 'abstract getter';
  }

  // slightly fade getters that are correct (when not testing) to show disabled
  paintPre(ctx: CanvasRenderingContext2D): void {
    // this status only occurs when not testing
    if (this.st === 'c') {
      ctx.globalAlpha = 0.7;
    }
  }

  getStatusColor(): string | undefined {
    if (this.st === 'ready') {
      return STATUS_READY_COLOR;
    }

    if (this.st === 'c') {
      return STATUS_CORRECT_COLOR;
    }

    if (this.st === 'wr') {
      return STATUS_WRONG_COLOR;
    }

    return undefined;
  }

  // Many subclasses will have specific strategies for visually indicating
  // status, such as outlining a table. However, some will share this
  // 'dot' strategy, so it is available here in abstract super class
  paintStatus(
    ctx: CanvasRenderingContext2D,
    point: { x: number; y: number }
  ): void {
    if (!this.st) return;
    const color = this.getStatusColor();

    if (!color) return;

    ctx.globalAlpha = 0.5;
    circle(ctx, point.x, point.y, STATUS_DIAMETER / 2, color);
    ctx.globalAlpha = 1;
  }

  getJsonFromServer(): BaseDTO {
    return this.dto;
  }

  setStatus(status: AttemptGetterStatus): void {
    this.st = status;
    // Disable getter if status is correct ('c')
    this.e = status !== 'c';

    this.disabled = !this.e;

    // mutate base dto (which is held by student app) so that later client-side rebuilds of getter have updated 'correct' or 'validAttempt' values
    // (later == click on different square, then click back on square that had this Getter, triggers rebuild from base dto data)
    this.dto.correct = this.translateNonExamStatus(status);
    // if 'correct' is undefined, then this is an exam problem and exam status ('validAttempt') needs to be updated
    if (!this.dto.correct) this.dto.validAttempt = status === 'ready';
  }

  translateNonExamStatus(
    status: AttemptGetterStatus
  ): CorrectStatus | undefined {
    if (status === 'c') return 'correct';
    if (status === 'wr') return 'wrong';
    if (status === 'u') return 'untried';

    return undefined;
  }

  changedMaybe(agId: number): void {
    this.changeMaybeHandler(agId);
  }

  isEnabled(): boolean {
    return this.st !== 'c';
  }

  loseFocus(): void {}

  abstract grabFocus(): void;

  // validates user input, then
  // returns DTO to send to server
  // OR returns undefined if invalid
  abstract serializeAttempt(testing: boolean): any;
}
