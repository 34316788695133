import { ProblemData, Score } from '@gmm/problem';

export type AnyArray = any[];
export type AnyObject = Record<string | number, any>;
export type Any = any;

export type ID = string | number;

export type WorkType =
  | 'ASSIGNMENT'
  | 'EXAM'
  | 'CORRECTIONS'
  | 'SPIRAL_REVIEW'
  | 'NONE';

export interface Work {
  type: WorkType;
  // TODO settle on type or workType. Rn, we get both, type for NewWork and workType for
  // list of available assignments
  workType?: WorkType;
  defaultAssignment?: boolean;

  name: string;
  date?: string;
  workId?: number | undefined;
  available?: boolean;

  dateAssigned?: string;

  sitId?: number;
  ticId?: number;

  // list of work history uses these for totals
  points?: number;
  required?: number;

  // this pair is for targeted skills on an assignment
  newSkillPoints?: number;
  newSkillRequired?: number;
  assignmentRestoreIds?: number[];

  // this pair is for SR skills on an assignment
  spiralReviewPoints?: number;
  spiralReviewRequired?: number;

  // this pair is for for the original exam mistakes on Corrections
  errorsFixed?: number;
  errorsRequired?: number;

  // this pair is for the extra practice on Corrections
  practicePoints?: number;
  practiceRequired?: number;
}

// Type guard for Exam
const isExam = (item: Partial<Work | Exam>): item is Exam => {
  return (item as Exam).testId !== undefined;
};

// For convenience, sometimes we pass around big flattened objects from the server
// that have Work fields alongside other fields. For example, see AttemptSubmitted.
// When those objects are passed to the WorkStore, it calls mergeWork
// or stripWork to extract the Work fields.
export const mergeWork = (
  original: Work | Exam,
  inbound: Partial<Work | Exam>
): Work | Exam => {
  const ret = {} as Work | Exam;

  ret.type = inbound.type ?? original.type;
  ret.workType = inbound.workType ?? original.workType;
  ret.name = inbound.name ?? original.name;
  ret.date = inbound.date ?? original.date;
  ret.workId = inbound.workId ?? original.workId;
  ret.available = inbound.available ?? original.available;
  ret.sitId = inbound.sitId ?? original.sitId;
  ret.ticId = inbound.ticId ?? original.ticId;
  ret.newSkillPoints = inbound.newSkillPoints ?? original.newSkillPoints;
  ret.newSkillRequired = inbound.newSkillRequired ?? original.newSkillRequired;
  ret.assignmentRestoreIds =
    inbound.assignmentRestoreIds ?? original.assignmentRestoreIds;
  ret.spiralReviewPoints =
    inbound.spiralReviewPoints ?? original.spiralReviewPoints;
  ret.spiralReviewRequired =
    inbound.spiralReviewRequired ?? original.spiralReviewRequired;
  ret.errorsFixed = inbound.errorsFixed ?? original.errorsFixed;
  ret.errorsRequired = inbound.errorsRequired ?? original.errorsRequired;
  ret.practicePoints = inbound.practicePoints ?? original.practicePoints;
  ret.practiceRequired = inbound.practiceRequired ?? original.practiceRequired;
  ret.dateAssigned = inbound.dateAssigned ?? original.dateAssigned;
  ret.defaultAssignment =
    inbound.defaultAssignment ?? original.defaultAssignment;

  if (isExam(inbound)) {
    const examRet = ret as Exam;

    examRet.testId = inbound.testId;

    return examRet;
  }

  return ret;
};

export const stripWork = (work: Work): Work => {
  return mergeWork({} as Work, work);
};

// only for two types of work: ASSIGNMENT and CORRECTIONS.
// Yeah, naming is tough. Corrections are things that teachers 'assign'.
export interface Assignment extends Work {
  available?: boolean;
  date: string;
}

export interface Exam extends Work {
  sitId: number;
  testId: number;
}

export const NO_WORK: Work = { type: 'NONE', name: 'No Work' };

export interface NewWorkProblems {
  problems: ProblemObjects;
  autoUnlockExamsProblems?: boolean;
  dollarRestoreIds?: ID[];
  selectedRestoreId?: number;
}

export interface NewWorkTagAlong {
  severeError?: string;
}

// The thing that gets built by WebClientBean.sendRestores,
// which is now handled by CurrentWorkService
export type NewWork = Work & NewWorkProblems & NewWorkTagAlong;

export interface ExamDatum {
  name?: string;
  sitId?: number;
  ticId?: number;
}

export interface ShowAssignmentsHistory {
  workHistory: Assignment[];
}

export type Proficiency =
  | 'untried'
  | 'not enough data'
  | 'red'
  | 'yellow'
  | 'green'
  | 'silver'
  | 'gold';

export interface HighScores {
  name: string;
  score: number;
}

export interface ProblemObject {
  skillId?: number;
  daysSince?: number;
  firstTry: boolean;
  followUp?: boolean;
  hasBeenTried: boolean;
  isUnfixedExamCorrection?: boolean;
  lastAttempt?: number;
  lastCorrectDate?: string;
  lastThree?: Score;
  lastFive?: Score;
  lastTen?: Score;
  lvl?: Proficiency;
  myAllTime?: Score;
  notValid?: boolean;
  penalty?: number;
  // problem
  p?: ProblemData;
  rawScore?: number;
  ready?: boolean;
  // How many times has the student looked at this problem, then clicked off
  skips?: number;
  // test number
  tn?: number;
  unseen?: boolean;
  mySkillAccuracy?: string;
  mySkillAttempts?: number;
  problemid?: ID;
  dateLearned?: string;
}

export type ProblemObjects = Record<ID, ProblemObject>;

export interface StatisticsProps {
  mySkillAccuracy: string;
  mySkillAttempts: number | undefined;
  lastThree: string;
  lastFive: string;
  lastTen: string;
  lastCorrectDate: string;
  problemid: ID;
}

export const themeOptions = [
  'green',
  'blue',
  'gray',
  'black',
  'orange',
  'pink',
  'purple',
] as const;

export const DEFAULT_THEME_OPTION = 'green';

// Step 2: Use 'typeof' and 'as const' to create the type
export type ThemeOption = typeof themeOptions[number];

export const themeColors = new Map<ThemeOption, string>([
  ['green', '71,150,68'],
  ['blue', '32,120,153'],
  ['gray', '169,169,169'],
  ['black', '0,0,0'],
  ['orange', '255,172,14'],
  ['pink', '255,20,147'],
  ['purple', '95,2,187'],
]);

export const getThemeColor = (themeColorName: ThemeOption): string => {
  return `rgb(${themeColors.get(themeColorName)})`;
};
