import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type DialogText = {
  buttonText: string;
  description: string;
  title: string;
};

export type ModalProps = {
  component?: JSX.Element;
  dialogText: DialogText;
  onClose?: () => void;
};

const initialDialogText = {
  buttonText: '',
  description: '',
  title: '',
};

const initialModalProps = {
  dialogText: initialDialogText,
  onClose: () => {},
};

type ModalStore = {
  isShowing: boolean;
  modalProps: ModalProps;
  setIsShowing: (show: boolean, modalProps?: ModalProps) => void;
};

export const modalStore = create<ModalStore>()(
  devtools(
    immer(set => ({
      isShowing: false,
      modalProps: initialModalProps,
      setIsShowing: (show: boolean, modalProps?: ModalProps) => {
        // this avoids changing modalProps to default during close events
        if (show && modalProps) {
          set({ isShowing: true, modalProps });
        } else {
          set({ isShowing: false });
        }
      },
    })),
    { name: 'Modal Store' }
  )
);

export const modalState = (): ModalStore => modalStore.getState();
