import { CSSProperties, FC } from 'react';

import { sendRaisedHand } from '../../../studentApp/api';
import { useLayout } from '../../../studentApp/hooks';
import { bannerStore, problemState } from '../../../studentApp/stores';

const handStyle: CSSProperties = {
  height: '30px',
  minHeight: '30px',
  width: '20px',
  minWidth: '20px',
  cursor: 'pointer',
  marginLeft: '15px',
  marginRight: '7px',
};

export const Hand: FC = () => {
  const [isHandRaised, setIsHandRaised] = bannerStore(state => [
    state.isHandRaised,
    state.setIsHandRaised,
  ]);
  const { isMobile } = useLayout();

  const raisedSrc = isMobile
    ? 'GMM-icons/GMM-icon-header-hand-selected-green.svg'
    : 'GMM-icons/GMM-icon-header-hand-selected.svg';
  const notRaisedSrc = isMobile
    ? 'GMM-icons/GMM-icon-header-hand-green.svg'
    : 'GMM-icons/GMM-icon-header-hand.svg';

  const setRaiseHand = (raised: boolean): void => {
    const currentProblemId = problemState().selectedID;

    if (!currentProblemId) {
      return;
    }

    // Tell the store
    setIsHandRaised(raised);
    // Tell the server
    sendRaisedHand(raised, currentProblemId);
  };

  return (
    <div style={handStyle} onClick={() => setRaiseHand(!isHandRaised)}>
      {isHandRaised ? <img src={raisedSrc} /> : <img src={notRaisedSrc} />}
    </div>
  );
};
