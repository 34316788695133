import { useFlags } from 'launchdarkly-react-client-sdk';
import { camelCase } from 'lodash';

// This is a constant for multivariate flags when it should be off
const NONE = 'none';

const isFlagOff = (value?: any): boolean => {
  switch (typeof value) {
    case 'string':
      return value.toLowerCase() === NONE;
    case 'boolean':
      return false;
    default:
      return !value;
  }
};

export function useFeatureFlag<T = boolean>(
  flag?: string
): T extends boolean ? T : T | null;
export function useFeatureFlag<T = boolean>(
  flag: string | undefined,
  defaultValue: T
): T;

export function useFeatureFlag<T = boolean>(
  flag?: string,
  defaultValue: T | null = null
): T extends boolean ? T : T | null {
  const flags = useFlags();
  const parsedFlag = camelCase(flag);
  const flagValue =
    parsedFlag && Object.prototype.hasOwnProperty.call(flags || {}, parsedFlag)
      ? flags[parsedFlag]
      : defaultValue;

  return isFlagOff(flagValue) ? null : flagValue;
}
