import Tooltip from '@material-ui/core/Tooltip';
import { FC } from 'react';

import { LIST_ITEM_COLOR } from '../../../constants';

interface ListItemProps {
  date?: string;
  text: string;
  corrections?: boolean;
  color?: string;
  score?: string;
  disabled?: boolean;
  launch?: () => void;
  minWidth?: number;
}

export const ListItem: FC<ListItemProps> = props => {
  const minWidth = props.minWidth ? props.minWidth + 'px' : undefined;

  return (
    <div
      style={{ minWidth: minWidth }}
      className={
        'work-list-item' + (props.disabled ? ' unclickable-work-list-item' : '')
      }
      onClick={!props.disabled ? props.launch : undefined}
    >
      <DateAndName {...props} />
      <Score {...props} />
    </div>
  );
};

const DateAndName: FC<ListItemProps> = props => {
  const color = props.color ?? LIST_ITEM_COLOR;
  const text = props.corrections ? 'Corrections, ' + props.text : props.text;

  return (
    <Tooltip title={text}>
      <div
        className="work-list-item-date-name"
        style={{ width: props.score ? '70%' : '95%' }}
      >
        {props.date && (
          <span style={{ color: color }}>{`${props.date}: `}</span>
        )}
        {props.corrections && (
          <span style={{ color: 'red' }}>Corrections, </span>
        )}
        <span style={{ color: color }}>{props.text}</span>
      </div>
    </Tooltip>
  );
};

const Score: FC<ListItemProps> = props => {
  const color = props.color ?? LIST_ITEM_COLOR;

  return (
    <>
      {props.score && (
        <div className="work-list-item-score" style={{ color: color }}>
          {props.score}
        </div>
      )}
    </>
  );
};
