import { sendObject } from './handler';

export type ToggleNativeKeyboard = {
  type: 'toggleNativeKeyboard';
  nativeKeyboard: 't' | 'f';
};

type Options = {
  enable: boolean;
};

export const postToggleNativeKeyboard = async ({
  enable,
}: Options): Promise<void> => {
  return sendObject({
    type: 'toggleNativeKeyboard',
    nativeKeyboard: enable ? 't' : 'f',
  });
};
