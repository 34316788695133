import { IconButton, Snackbar, SnackbarOrigin } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { FC, useEffect, useState } from 'react';

import { getSnackbarMessage, setSnackbarMessage } from '../../stores/globalState';
import { useSubscription } from '../../hooks';

const anchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

export const SnackbarNotification: FC = () => {
  const [open, setOpen] = useState(false);
  const [snackbarMessage] = useSubscription<
    [string | { autoHideDuration?: number | null; message: string }]
  >('snackbarMessage', [getSnackbarMessage()]);

  const handleClick = (): void => {
    setOpen(false);
    setSnackbarMessage('');
  };

  useEffect(() => {
    setOpen(!!snackbarMessage);
  }, [snackbarMessage]);

  const { autoHideDuration = 4000, message } =
    typeof snackbarMessage === 'string'
      ? { message: snackbarMessage }
      : snackbarMessage;

  return (
    <Snackbar
      id="snackbar"
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={handleClick}
      message={message}
      autoHideDuration={autoHideDuration}
      action={
        <IconButton aria-label="close" color="inherit" onClick={handleClick}>
          <CloseIcon />
        </IconButton>
      }
    />
  );
};
