import { FC } from 'react';

interface ModalButtonProps {
  close: () => void;
  color: string;
  text: string;
}

export const ModalButton: FC<ModalButtonProps> = ({ close, color, text }) => {
  return (
    <span
      onClick={close}
      style={{
        cursor: 'pointer',
        padding: '10px',
        backgroundColor: color,
      }}
    >
      {text}
    </span>
  );
};
