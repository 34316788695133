import { withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FC } from 'react';

const StyledAlert = withStyles(theme => ({
  root: {
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  icon: {
    flexBasis: 34,
    flexShrink: 0,
    margin: 0,
    minWidth: 34,
    width: 34,
  },
}))(Alert);

export const UnsupportedBrowserAlert: FC = () => (
  <StyledAlert severity="info">
    GMM can be unreliable with Microsoft or Firefox browsers. Please use Chrome
    or Safari.
  </StyledAlert>
);
