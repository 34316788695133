import { FC } from 'react';

import { MODAL_Z_INDEX } from '@gmm/problem';

// A Game operates as a modal -- an inert div to
// cover the whole screen, then a absolute positioned
// canvas with an id of 'gameCanvas' so that
// gameengine can find it, size it, and render to it.

export const Game: FC = () => {
  return (
    <div
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        zIndex: MODAL_Z_INDEX,
      }}
    >
      <canvas
        id="gameCanvas"
        style={{
          position: 'absolute',
          top: '10px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      ></canvas>
    </div>
  );
};
