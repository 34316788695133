type SubscribeCallback<T extends unknown[] = unknown[]> = (...args: T) => void;

const subscribers: Record<string, Array<SubscribeCallback<any>>> = {};

export interface Pubsub {
  publish(event: string, data: unknown): void;
  subscribe<T extends unknown[]>(
    event: string,
    callback: SubscribeCallback<T>
  ): { unsubscribe: () => void };
}

/**
 * PubSub
 *
 * to subscribe to an event:
 *
 * pubsub.subscribe('eventName', function handler(data: any) {})
 *
 * This sets up a listener. The handler will be called with the `data` that is
 * passed to `publish` (see below)
 *
 * to publish an event:
 *
 * pubsub.publish('eventName', data)
 *
 * This will fire any handlers that are setup to listen to the event
 */
class PubsubImpl implements Pubsub {
  publish(event: string, data: unknown): void {
    if (!subscribers[event]) return;

    subscribers[event].forEach(callback => callback(data));
  }

  subscribe<T extends unknown[]>(
    event: string,
    callback: SubscribeCallback<T>
  ): { unsubscribe: () => void } {
    if (!subscribers[event]) {
      subscribers[event] = [];
    }

    const index = subscribers[event].push(callback) - 1;

    return {
      unsubscribe: () => subscribers[event].splice(index, 1),
    };
  }
}

// Export this as a singleton
export const pubsub = new PubsubImpl();
