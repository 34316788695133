import { FC } from 'react';
import Draggable from 'react-draggable';

import { ModalHeader, modalStyle } from '@gmm/problem';

import { bannerStore } from '../../../../studentApp/stores';
import { studentAppModalState } from '../../../../studentApp/stores/studentAppModalStore';
import { loginInternal } from '../../../../studentApp/utils';

import { ListItem } from './listItem';

interface SwitchClassProps {
  close: () => void;
}

export const SwitchClass: FC<SwitchClassProps> = ({ close }) => {
  const [currentClass, otherClasses] = bannerStore(state => [
    state.className,
    state.otherClasses,
  ]);

  return (
    <Draggable handle=".gmm-switch-class-modal">
      <div className="blue" style={modalStyle}>
        <ModalHeader
          close={close}
          title={'Pick a class'}
          dragHandle={'gmm-switch-class-modal'}
        />

        <div style={{ height: '15px' }}></div>

        <span>Current class: {currentClass}</span>

        <div style={{ height: '15px' }}></div>

        {otherClasses.map(otherClass => (
          <ListItem
            key={otherClass.id}
            text={otherClass.name}
            launch={() => changeClass(otherClass.id)}
          />
        ))}
      </div>
    </Draggable>
  );
};

const changeClass = (id: string) => {
  studentAppModalState().closeAll();
  loginInternal(id);
};
