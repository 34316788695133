import { FC } from 'react';
import Draggable from 'react-draggable';

import { ModalHeader, modalStyle } from '@gmm/problem';

import { postSpendGameCredit } from '../../../../studentApp/api';
import { launchGame } from '../../../../studentApp/api/responseHandlerShared';
import { studentAppModalStore } from '../../../../studentApp/stores/studentAppModalStore';

interface GameProps {
  close: () => void;
}

export const Games: FC<GameProps> = ({ close }) => {
  const games = studentAppModalStore(state => state.availableGames);

  const launch = (game: string) => {
    close();
    postSpendGameCredit(game, launchGame);
  };

  return (
    <Draggable handle=".gmm-games-modal">
      <div className="blue" style={modalStyle}>
        <ModalHeader
          close={close}
          title={'Games'}
          dragHandle={'gmm-games-modal'}
        />

        <div style={{ height: '15px' }}></div>

        <div className="limit-list-height">
          {games.map(game => (
            <GameListItem
              key={game.internal}
              name={game.human}
              img={'games/menuIcons/' + game.img}
              launch={() => launch(game.internal)}
            />
          ))}
        </div>
      </div>
    </Draggable>
  );
};

interface GameListItemProps {
  name: string;
  img: string;
  launch?: () => void;
}

const GameListItem: FC<GameListItemProps> = listItemProps => {
  return (
    <div
      className={'work-list-item'}
      onClick={listItemProps.launch}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <img
        src={listItemProps.img}
        style={{
          height: '30px',
          width: '30px',
          display: 'inline-block',
          margin: '0px 20px 0px 20px',
        }}
      />
      <span>{listItemProps.name}</span>
    </div>
  );
};
