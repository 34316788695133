import { FC } from 'react';

import { useProcessedProblems } from '../../../studentApp/hooks';
import { problemStore } from '../../../studentApp/stores';
import { squareGroupsStyle } from '../util/constants';

import { LabelAndSquares } from './labelAndSquares';
import { RegionLabel } from './regionLabel';

export const ExamRegion: FC = () => {
  const problemNumber = problemStore(state => state.currentExamProblemNumber);
  const examProblems = useProcessedProblems().examProblems;

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        <RegionLabel text={'Exam Questions'} />
      </div>

      <div className="scrollable" style={squareGroupsStyle}>
        <LabelAndSquares
          text={problemNumber ? '#' + problemNumber : ''}
          problems={examProblems}
        />
      </div>
    </>
  );
};
