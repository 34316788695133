import { yupResolver } from '@hookform/resolvers/yup';
import { styled, Tooltip, Typography } from '@material-ui/core';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  isEdge,
  isFirefox,
  isIE,
  isOldIOS,
} from '../../../studentApp/api/sendError/requesHandler';
import { setSnackbarMessage } from '../../../studentApp/stores/globalState';
import { studentAppModalStore } from '../../../studentApp/stores/studentAppModalStore';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useAppContext } from '../../stores/appContext';
import { Button, TextField } from '../../ui';
import {
  UnsupportedBrowserAlert,
  WelcomeMessage,
  OldDeviceAlert,
} from '../components';

const loadingImage = <img src="GMM-images/loading.gif" height="30px"></img>;

const Container = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1.75),
}));

const Form = styled('form')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1.75),
}));

const Fields = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(0.75),
  width: theme.spacing(33.5),
}));

const Field = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(0.75),
}));

const Hint = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: 'center',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3.5),
}));

const validationSchema = yup.object().shape({
  password: yup.string().label('Password').required().trim(),
  username: yup.string().label('Username').required().trim(),
});

export interface LoginValues {
  password: string;
  username: string;
}

const messages = {
  archived: 'If you are trying to join a class, please contact your teacher',
  disabled: 'If you need an account please contact your teacher',
  notFound: '',
  ok: '',
  unknown: '',
};

export const Login: FC = () => {
  const {
    autoLogin,
    defaultLoginValues,
    pubsub,
    urlParams: { status },
  } = useAppContext();
  const preventSigninFromOldDevices = useFeatureFlag(
    'Student.MinimumiOSVersion',
    false
  );
  const [loading] = studentAppModalStore(state => [state.loading]);
  const userAgent = window.navigator.userAgent;
  const old_ie = userAgent.includes('MSIE ');
  const new_ie = userAgent.includes('Trident/');
  const ms_ie = old_ie || new_ie;
  const isWrongBrowser = ms_ie || isIE || isEdge || isFirefox;
  const isOldDevice = preventSigninFromOldDevices && isOldIOS;
  const showWelcome = status === 'ok';
  const classEnrollmentStatusMessage = messages[status];
  const { formState, handleSubmit, register } = useForm<LoginValues>({
    defaultValues: defaultLoginValues,
    resolver: yupResolver(validationSchema),
  });
  const { errors, submitCount, touchedFields } = formState;

  const onSubmitForm = useCallback(
    (values: LoginValues): void => {
      pubsub.publish('login', validationSchema.cast(values));
    },
    [pubsub]
  );
  const onSubmit = useMemo(() => handleSubmit(onSubmitForm), [
    handleSubmit,
    onSubmitForm,
  ]);

  useEffect(() => {
    if (classEnrollmentStatusMessage.length > 0) {
      setSnackbarMessage(classEnrollmentStatusMessage);
    }
  }, [classEnrollmentStatusMessage]);

  useEffect(() => {
    if (autoLogin && submitCount === 0) {
      // This merely maintains consoling that was previously used
      // eslint-disable-next-line no-console
      console.log('loginSubmit attempt');
      onSubmit();
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, [autoLogin, onSubmit, submitCount]);

  return (
    <Container>
      {showWelcome && <WelcomeMessage />}
      <Title align="center" variant="h4">
        Student Login
      </Title>
      <Form id="loginForm" onSubmit={onSubmit}>
        <Fields>
          {isOldDevice && <OldDeviceAlert />}
          {isWrongBrowser && <UnsupportedBrowserAlert />}
          <Field
            {...register('username')}
            autoComplete="username"
            autoFocus={!defaultLoginValues.username}
            defaultValue={defaultLoginValues.username}
            disabled={isOldDevice || isWrongBrowser}
            error={touchedFields.username && !!errors.username?.message}
            fullWidth
            helperText={errors.username?.message ?? ' '}
            label="Username"
            variant="outlined"
          />
          <Field
            {...register('password')}
            autoComplete="current-password"
            autoFocus={
              !!defaultLoginValues.username && !defaultLoginValues.password
            }
            defaultValue={defaultLoginValues.password}
            disabled={isOldDevice || isWrongBrowser}
            error={touchedFields.password && !!errors.password?.message}
            fullWidth
            helperText={errors.password?.message ?? ' '}
            label="Password"
            name="password"
            type="password"
            variant="outlined"
          />
          <Tooltip
            title={
              isOldDevice ? 'The device you are using is not supported.' : ''
            }
          >
            <div>
              <Button
                color="primary"
                disabled={isOldDevice || isWrongBrowser || loading}
                fullWidth
                type="submit"
                variant="contained"
              >
                Login {loading && loadingImage}
              </Button>
            </div>
          </Tooltip>
          <Hint>
            Don&apos;t have an account yet? Ask your teacher for the class
            enrollment link.
          </Hint>
        </Fields>
      </Form>
    </Container>
  );
};
