import { CSSProperties, FC } from 'react';

import { useProcessedBannerStore } from '../../../studentApp/hooks';
import { bannerStore } from '../../../studentApp/stores';
import {
  MOBILE_MARGIN,
  POINTS_AND_CREDITS_HEIGHT,
  TEXT_COLOR,
} from '../../constants';

import { GameCredits } from './gameCredits';
import { MobileGameCredits } from './mobileGameCredits';
import { PointsText, PointsTodayAndWeek } from './pointsTodayAndWeek';

const pointsAndGameCreditsStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: POINTS_AND_CREDITS_HEIGHT + 'px',
  minHeight: POINTS_AND_CREDITS_HEIGHT + 'px',
  paddingLeft: '20px',
  paddingRight: '20px',
  color: TEXT_COLOR,
};

export const PointsAndGameCredits: FC = () => {
  const [dailyGoal, pointsToday] = bannerStore(state => [
    state.dailyGoal,
    state.pointsToday,
  ]);

  const { showGamesMenu, showDisabledGamesMenu } = useProcessedBannerStore();

  const hasHomework = dailyGoal > 0;
  const finishedHomework = hasHomework && pointsToday >= dailyGoal;
  const pointsRemaining = dailyGoal - pointsToday;
  const pointsWord = pointsRemaining === 1 ? 'point' : 'points';

  return (
    <div style={pointsAndGameCreditsStyle}>
      <PointsTodayAndWeek />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: 'auto',
        }}
      >
        {hasHomework && !finishedHomework && (
          <PointsText>
            {pointsRemaining + ' daily ' + pointsWord + ' remaining'}
          </PointsText>
        )}
        {hasHomework && finishedHomework && (
          <PointsText>daily goal complete</PointsText>
        )}
        {hasHomework && <div style={{ width: '10px' }}></div>}

        {(showGamesMenu || showDisabledGamesMenu) && <GameCredits />}
      </div>
    </div>
  );
};

export const MobilePointsAndGameCredits: FC = () => {
  return (
    <div
      style={{
        ...pointsAndGameCreditsStyle,
        paddingLeft: MOBILE_MARGIN,
        paddingRight: MOBILE_MARGIN,
      }}
    >
      <PointsTodayAndWeek />
      <MobileGameCredits />
    </div>
  );
};
