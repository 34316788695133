import { FC, useEffect, useRef } from 'react';
import { NARROW_PROBLEM_WIDTH, ProblemData, ProblemWidth } from '..';
import { displayStaticProblem } from './problemCanvasSetup';

interface Props {
  problemData?: ProblemData;
  width?: ProblemWidth;
}

export const StaticProblemCanvas: FC<Props> = ({ problemData, width }) => {
  const canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvas.current && problemData) {
      displayStaticProblem(
        canvas.current,
        problemData,
        width || NARROW_PROBLEM_WIDTH
      );
    }
  }, [problemData]);

  return <canvas ref={canvas} />;
};
