import { createContext, useContext } from 'react';

import { LoginValues } from '../views/login';
import { Pubsub } from '../../studentApp/legacy';

export type Page = 'getStarted' | 'login' | 'register';
export type Status = 'archived' | 'disabled' | 'notFound' | 'ok' | 'unknown';

interface URLParams {
  className: string;
  teacherName: string;
  classId?: string | null;
  classCode: string;
  page?: Page;
  status: Status;
}
export interface AppContext {
  autoLogin?: boolean;
  defaultLoginValues: LoginValues;
  pubsub: Pubsub;
  urlParams: URLParams;
}

const AppContext = createContext<AppContext>(null as any);

export const AppContextProvider = AppContext.Provider;

export const useAppContext = (): AppContext => {
  const ctx = useContext(AppContext);

  if (process.env.NODE_ENV === 'development' && ctx === null) {
    throw new Error('`useAppContext` must be used with an AppContextProvider');
  }

  return ctx;
};
