import { CSSProperties, FC } from 'react';
import Draggable from 'react-draggable';

import { ModalHeader, modalStyle } from '@gmm/problem';

import {
  postToggleClock,
  postToggleEffects,
  postToggleNativeKeyboard,
  postToggleTopScores,
  postToggleTouchKeyboard,
} from '../../../studentApp/api';
import { themeUpdated } from '../../../studentApp/api/responseHandlerShared';
import { bannerStore } from '../../../studentApp/stores';
import { studentAppModalStore } from '../../../studentApp/stores/studentAppModalStore';
import {
  ThemeOption,
  getThemeColor,
  themeColors,
} from '../../../studentApp/types';

export const Settings: FC<{ close: () => void }> = ({ close }) => {
  const [
    touchKeyboard,
    effects,
    showTopScores,
    showClock,
    useNativeKeyboard,
    setEffects,
    setShowTopScores,
    setShowClock,
    setTouchKeyboard,
    setUseNativeKeyboard,
  ] = bannerStore(state => [
    state.touchKeyboard,
    state.effects,
    state.showTopScores,
    state.showClock,
    state.useNativeKeyboard,
    state.setEffects,
    state.setShowTopScores,
    state.setShowClock,
    state.setTouchKeyboard,
    state.setUseNativeKeyboard,
  ]);

  const touchDevice = studentAppModalStore(state => state.touchDevice);

  const toggleEffects = (checked: boolean) => {
    setEffects(checked);
    postToggleEffects({ showSmileys: checked });
  };

  const toggleShowTopScores = (checked: boolean) => {
    setShowTopScores(checked);
    postToggleTopScores({ showTopScores: checked });
  };

  const toggleShowClock = (checked: boolean) => {
    setShowClock(checked);
    postToggleClock({ showClock: checked });
  };

  const toggleTouchKeyboard = (checked: boolean) => {
    setTouchKeyboard(checked);
    postToggleTouchKeyboard({ force: checked });
  };

  const toggleNativeKeyboard = (checked: boolean) => {
    setUseNativeKeyboard(checked);
    postToggleNativeKeyboard({ enable: checked });
  };

  return (
    <Draggable handle=".gmm-settings-modal">
      <div className="blue" style={modalStyle}>
        <ModalHeader
          title="Settings"
          close={close}
          dragHandle={'gmm-settings-modal'}
        />
        <div style={{ height: '5px' }}></div>

        <Checkbox label="Smileys" onChange={toggleEffects} value={effects} />
        <Checkbox
          label="Show top scores"
          onChange={toggleShowTopScores}
          value={showTopScores}
        />
        <Checkbox
          label="Show clock"
          onChange={toggleShowClock}
          value={showClock}
        />
        {!touchDevice && (
          <Checkbox
            label="Show touch-friendly keyboard"
            onChange={toggleTouchKeyboard}
            value={touchKeyboard}
          />
        )}
        {touchDevice && (
          <Checkbox
            label="Use native keyboard"
            onChange={toggleNativeKeyboard}
            value={useNativeKeyboard}
          />
        )}

        <div style={themeColorsStyle}>
          Theme:
          {Array.from(themeColors.keys()).map((themeOption, index) => (
            <ThemeColor key={index} themeOption={themeOption} />
          ))}
        </div>
      </div>
    </Draggable>
  );
};

const themeColorsStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px',
};

interface CheckboxProps {
  label: string;
  onChange: (checked: boolean) => void;
  value: boolean;
}

const Checkbox: FC<CheckboxProps> = ({ label, onChange, value }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <div style={checkboxStyle}>
      <label>
        <input type="checkbox" checked={value} onChange={handleChange} />
        <span style={{ marginLeft: '8px', cursor: 'pointer' }}>{label}</span>
      </label>
    </div>
  );
};

const checkboxStyle: CSSProperties = {
  position: 'relative',
  display: 'block',
  margin: '10px 0px 10px 0px',
};

const ThemeColor: FC<{ themeOption: ThemeOption }> = ({ themeOption }) => {
  const [currentThemeOption, setThemeOption] = bannerStore(state => [
    state.themeOption,
    state.setThemeOption,
  ]);

  const handleClick = () => {
    setThemeOption(themeOption);
    themeUpdated();
  };

  const trueColor = getThemeColor(themeOption);
  const selected = currentThemeOption === themeOption;
  const borderStyle = selected ? '3px solid white' : 'none';

  return (
    <div
      onClick={handleClick}
      style={{
        backgroundColor: trueColor,
        border: borderStyle,
        boxSizing: 'content-box',
        width: '22px',
        height: '22px',
      }}
    ></div>
  );
};
