import { pointsEqual } from './pointsEqual';
import { Polygon } from './types';

/**
 * If the polygon that is passed as argument is clockwise, that polygon is returned. If it is counter-clockwise, a polygon with same points but in reversed order is returned.
 * Complexity O(n)
 */
export function clockwisePolygon(polygon: Polygon): Polygon {
  let sum = 0;
  const reversedPolygon: Polygon = [];

  for (let i = 0; i < polygon.length; i++) {
    const currentPoint = polygon[i];
    const nextPoint = polygon[(i + 1) % polygon.length];

    if (!pointsEqual(currentPoint, nextPoint)) {
      reversedPolygon.splice(0, 0, currentPoint);
    }

    sum += (nextPoint.x - currentPoint.x) * (nextPoint.y + currentPoint.y);
  }

  if (sum > 0) {
    // polygon is clockwise
    return polygon;
  } else {
    // polygon is counter-clockwise
    return reversedPolygon;
  }
}
