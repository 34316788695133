import { FC, useState } from 'react';
import { DownTriangle } from '../constants-react';
import { MODAL_Z_INDEX } from '../constants';

interface PickerProps {
  title: string;
  choices: string[];
  current: string | number;
  setCurrent: (value: string) => void;
  choicesShowing?: boolean;
  setChoicesShowing: (value: boolean) => void;
}

export const Picker: FC<PickerProps> = ({
  title: pickerLabel,
  current,
  choices,
  setCurrent: setCurrentChoice,
  choicesShowing,
  setChoicesShowing,
}) => {
  const toggle = (event: React.MouseEvent) => {
    setChoicesShowing(!choicesShowing);
    event.stopPropagation();
  };

  const handleListItemClick = (value: string): void => {
    setCurrentChoice(value);
    setChoicesShowing(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{pickerLabel}</span>

      <div
        style={{
          position: 'relative',
          border: '1px solid #337ab7',
          marginTop: '10px',
        }}
      >
        <Current value={current} toggle={toggle} />

        {choicesShowing && (
          <div
            style={{
              position: 'absolute',
              top: '110%',
              border: '1px solid #337ab7',
            }}
          >
            {choices.map((choice, index) => (
              <ListItem
                key={index}
                value={choice}
                click={() => handleListItemClick(choice)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

interface CurrentProps {
  value: number | string;
  toggle: (event: React.MouseEvent) => void;
}

const Current: FC<CurrentProps> = ({ value, toggle }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={toggle}
      style={{
        display: 'flex',
        height: '25px',
        width: '120px',
        backgroundColor: isHovered ? 'lightgray' : 'white',
        justifyContent: 'left',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <span style={{ paddingLeft: '10px', color: 'black' }}>{value}</span>
      <DownTriangle />
    </div>
  );
};

interface ListItemProps {
  value: string;
  click: () => void;
}

const ListItem: FC<ListItemProps> = ({ value, click }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={click}
      style={{
        position: 'relative',
        display: 'flex',
        height: '25px',
        width: '120px',
        paddingBottom: '5px',
        backgroundColor: isHovered ? 'lightgray' : 'white',
        padding: '5px 30px 5px 10px',
        cursor: 'pointer',
        color: 'black',
        zIndex: MODAL_Z_INDEX,
      }}
    >
      {value}
    </div>
  );
};
